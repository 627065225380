import { BlogLayoutHeader, DesktopBlogLayout } from "@/app/components/Desktop";
import { SectionWrapper } from "@/app/hoc";
import { useApiQuery } from "@/server/api-fetch";
import { GET_ALL_BLOGS } from "@/server/apis";
import { textVariant } from "@/utils/motion";
import { motion } from "framer-motion";
import React, { useState } from "react";

const RecommandBlogs = ({ title }) => {
  const [offset, setOffset] = useState(1);
  const limit = 10;
  const { data, isLoading } = useApiQuery({
    url: `${GET_ALL_BLOGS}?limit=${limit}&offset=${(offset - 1) * limit}`,
  });

  return (
    <motion.div variants={textVariant()} style={{ marginTop: 100 }}>
      <BlogLayoutHeader title={title} isViewAll={false} />
      <DesktopBlogLayout data={data} offset={offset} isLoading={isLoading} setOffset={setOffset} />
    </motion.div>
  );
};

export default SectionWrapper(RecommandBlogs, "recommandBlogs");
