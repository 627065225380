import React from "react";
import {
  BlogSection,
  CategorySection,
  HeroSection,
  PopularBlogsSection,
  ProductSection,
  ServiceSection,
} from "@/app/layouts/Desktop";
import { useDesktopDimensions } from "@/hooks";

import { DesktopAdSection, DesktopFooter, DesktopNavbar } from "@/app/components/Desktop";

const DesktopView = () => {
  const { isDesktop } = useDesktopDimensions();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "space-between",
      }}>
      <DesktopNavbar />
      <div style={{ paddingInline: isDesktop ? 20 : 72, overflow: "hidden", overflowX: "hidden" }}>
        <HeroSection />
        <BlogSection />
        <CategorySection />
        <PopularBlogsSection title={"လတ်တလော ရေပန်းစားမှုများ"} />
        <DesktopAdSection />
        <ServiceSection href={"/services"} />
        <ProductSection title={"ဈေးဆိုင်တွင်းရှိ ပစ္စည်းများ"} category={"all"} featured={"hot"} />
      </div>
      <DesktopFooter />
    </div>
  );
};

export default DesktopView;
