import { CImage } from "@/app/common";
import { Typography } from "antd";
import styled from "styled-components";

export const HeroContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 82px;
    align-items: center;
`

export const HeroTitle = styled(Typography.Text)`
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
`

export const HeroImage = styled(CImage)`
    width: 100%;
    height: 240px;
    object-fit: cover;
    border-radius: 20px;
`

export const HeroDescription = styled(Typography.Text)`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: var(--Blue-600, #4B5262);
    line-height: 32px;
`