import React, { useState } from "react";
import { DesktopAdSection, DesktopFooter } from "@/app/components/Desktop";
import { MobileNavbar } from "@/app/components/Mobile";
import {
  BlogSection,
  CategorySection,
  PopularBlogsSection,
  ProductSection,
  ServiceSection,
} from "@/app/layouts/Desktop";
import { MobileHeroSection, MobileSearchContainer } from "@/app/layouts/Mobile";

const MobileView = () => {
  const [showSearchContainer, setShowSearchContainer] = useState(false);
  return (
    <main style={{ overflowX: "hidden" }}>
      <MobileSearchContainer
        showSearchContainer={showSearchContainer}
        setShowSearchContainer={setShowSearchContainer}
      />
      <div style={{ paddingInline: 16 }}>
        {!showSearchContainer && <MobileNavbar setShowSearchContainer={setShowSearchContainer} />}
        <MobileHeroSection />
        <BlogSection />
        <CategorySection />
        <PopularBlogsSection title={"လတ်တလော ရေပန်းစားမှုများ"} />
        <DesktopAdSection />
        <ServiceSection href={"/services"} />
        <ProductSection
          title={"ဈေးဆိုင်တွင်းရှိ ပစ္စည်းများ"}
          category={"all"}
          featured={"hot"}
        />
      </div>
      <DesktopFooter />
    </main>
  );
};

export default MobileView;
