import { Flex, Slider, Typography } from "antd";
import React from "react";
import styled from "styled-components";

const StyledText = styled(Typography.Text)`
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

const ProductPriceFilter = ({ value, onChangeValue }) => {
  const formatNumber = (number: number) => {
    return number.toLocaleString();
  };
  return (
    <Flex vertical gap={24}>
      <Slider
        range
        min={1}
        max={10000000}
        value={value}
        onChange={onChangeValue}
        styles={{
          track: {
            background: "#485AFF",
          },
          tracks: {
            background: "#485AFF",
          },
        }}
      />
      <Flex vertical gap={16}>
        <Flex>
          <StyledText style={{ marginRight: 12 }}>အနည်းဆုံး နှုန်းထား -</StyledText>
          <StyledText style={{ marginRight: 8 }}>{formatNumber(value[0])}</StyledText>
          <StyledText>₩</StyledText>
        </Flex>
        <Flex>
          <StyledText style={{ marginRight: 12 }}>အများဆုံး နှုန်းထား -</StyledText>
          <StyledText style={{ marginRight: 8 }}>
            {formatNumber(value[1])}
            {value[1] === 10000000 && "+"}
          </StyledText>
          <StyledText>₩
          </StyledText>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ProductPriceFilter;
