import React, { useState } from "react";
import { Container, StyledButton } from "./styles";
import { ProductSearchBar } from "@/app/components/Desktop";
import { FilterTypeContainer } from "@/app/entities";
import { useDesktopDimensions } from "@/hooks";
import {
  ProductBrandFilterList,
  ProductGenreFilterList,
  ProductPriceFilter,
  ProductTypeFilterList,
} from "@/app/features";
import { View } from "@/styles/reactnative";

const ProductsFilter = ({
  productType,
  setProductType,
  productGenre,
  setProductGenre,
  productBrand,
  setProductBrand,
  setIsFilter,
  searchResult,
  setSearchResult,
  sliderRange,
  setSliderRange,
}) => {
  const { isDesktop } = useDesktopDimensions();

  const handleSliderChange = newValue => {
    setSliderRange(newValue);
  };

  const handleGenreChange = (checkedValue, genre) => {
    if (checkedValue) {
      setProductGenre([...productGenre, genre]);
    } else {
      setProductGenre(productGenre.filter(item => item !== genre));
    }
  };

  const handleBrandChange = (checkedValue: boolean, brand: string) => {
    if (checkedValue) {
      setProductBrand([...productBrand, brand]);
    } else {
      setProductBrand(productBrand.filter(item => item !== brand));
    }
  };

  return (
    <Container $isDesktop={isDesktop}>
      <ProductSearchBar
        placeholder={"ပစ္စည်းများကို ရှာဖွေမည်"}
        vStyle={{ width: "100%", position: "absolute" }}
        action={setSearchResult}
      />
      <View style={{ flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
        {searchResult ? (
          <StyledButton
            type="text"
            style={{ color: "#485AFF", paddingLeft: 0 }}
            onClick={() => setSearchResult(null)}>
            ရှာဖွေမူကို ရှင်လင်းမည်။
          </StyledButton>
        ) : null}
        <StyledButton
          type="text"
          style={{ color: "#485AFF", paddingRight: 0, alignSelf: "flex-end" }}
          onClick={() => setIsFilter(true)}>
          စီစစ်မည်
        </StyledButton>
      </View>

      <FilterTypeContainer title={"ပစ္စည်းအမျိုးအစား"} scrollable={true}>
        <ProductTypeFilterList value={productType} onChangeValue={setProductType} />
      </FilterTypeContainer>

      <FilterTypeContainer title={"ဈေးနှုန်း"} scrollable={false}>
        <ProductPriceFilter value={sliderRange} onChangeValue={handleSliderChange} />
      </FilterTypeContainer>

      <FilterTypeContainer title={"ပုံစံအမျိုးအမည်"} scrollable>
        <ProductGenreFilterList value={productGenre} onChangeValue={handleGenreChange} />
      </FilterTypeContainer>

      {productGenre?.length > 0 && (
        <FilterTypeContainer title={"ဘရန်းအမျိုးအစား"} scrollable>
          <ProductBrandFilterList value={productBrand} onChangeValue={handleBrandChange} />
        </FilterTypeContainer>
      )}
    </Container>
  );
};

export default ProductsFilter;
