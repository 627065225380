import { useEffect, useState, useMemo } from 'react'
import debounce from 'lodash/debounce'

function useDebounce(value, delay) {
	// State and setters for debounced value
	const [debouncedValue, setDebouncedValue] = useState(value)

	// Memoize the debounced function
	const debouncedSet = useMemo(
		() => debounce(newValue => setDebouncedValue(newValue), delay),
		[delay]
	)

	useEffect(() => {
		// Call the debounced function
		debouncedSet(value)

		// Cleanup function to cancel the debounced call if value changes before delay
		return () => {
			debouncedSet.cancel()
		}
	}, [value, debouncedSet])

	return debouncedValue
}

export default useDebounce
