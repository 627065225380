import home_page_img from "./home/licensed-image.png";
import app_store from "./home/app_store.png";
import play_store from "./home/play_store.png";
import blog_1 from "./home/blog_1.png";
import blog_2 from "./home/blog_2.png";
import dummy_blog_1 from "./blogs/blog_1.png";
import dummy_blog_2 from "./blogs/blog_2.png";
import dummy_blog_3 from "./blogs/blog_3.png";
import dummy_blog_4 from "./blogs/blog_4.png";
import dummy_blog_5 from "./blogs/blog_5.png";
import dummy_blog_6 from "./blogs/blog_6.png";
import dummy_blog_7 from "./blogs/blog_7.png";
import dummy_blog_8 from "./blogs/blog_8.png";
import ad_img from "./home/ad.png";
import product_1 from "./products/product_1.png";
import product_2 from "./products/product_2.png";
import product_3 from "./products/product_3.png";
import product_4 from "./products/product_4.png";
import product_5 from "./products/product_5.png";
import footer_apple_store from "./home/footer_apple_store.png";
import footer_google_store from "./home/footer_google_play.png";
import dummy_carousel from "./blogs/dummyCarousel.png"

const images = {
  home_page_img,
  app_store,
  play_store,
  blog_1,
  blog_2,
  dummy_blog_1,
  dummy_blog_2,
  dummy_blog_3,
  dummy_blog_4,
  dummy_blog_5,
  dummy_blog_6,
  dummy_blog_7,
  dummy_blog_8,
  ad_img,
  product_1,
  product_2,
  product_3,
  product_4,
  product_5,
  footer_apple_store,
  footer_google_store,
  dummy_carousel
};

export { images };
