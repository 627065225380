import styled from "styled-components";

export const Container = styled.div<{ $isMobile: boolean }>`
  width: 100%;
  /* width: ${props => (props?.$isMobile ? "157px" : "205px")}; */
  height: ${props => (props?.$isMobile ? "241px" : "260px")};
  padding: 8px 8px ${props => (props?.$isMobile ? "8px" : "42px")} 8px;
  border: 1px solid var(--Stroke, #e4e7e9);
  margin-bottom: 12px;
  border-radius: 12px;
  position: relative;
  cursor: pointer;
`;

export const StyledTag = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  padding: 4px 8px;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
  display: inline-block;
`;
