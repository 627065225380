import React, { forwardRef, useCallback, useState } from "react";
import { BottomSheet, StyledBackground, StyledCollapse } from "./styles";
import { AnimatePresence } from "framer-motion";
import { CollapseProps, Flex } from "antd";
import { ProductFilterHeader } from "@/app/entities";
import {
  ProductBrandFilterList,
  ProductGenreFilterList,
  ProductPriceFilter,
  ProductTypeFilterList,
} from "@/app/features";

interface MobileProductFilterProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  productType: string;
  setProductType: React.Dispatch<React.SetStateAction<string>>;
  productGenre: string[];
  setProductGenre: React.Dispatch<React.SetStateAction<string[]>>;
  productBrand: string[];
  setProductBrand: React.Dispatch<React.SetStateAction<string[]>>;
  clearFilter: any;
  sliderRange?: any;
  setSliderRange: React.Dispatch<React.SetStateAction<[number, number]>>;
}

const MobileProductFilter = forwardRef<HTMLDivElement, MobileProductFilterProps>(
  (
    {
      open,
      setOpen,
      productType,
      setProductType,
      productGenre,
      setProductGenre,
      productBrand,
      setProductBrand,
      clearFilter,
      sliderRange,
      setSliderRange,
    },
    ref
  ) => {
    const handleSliderChange = (newValue: React.SetStateAction<[number, number]>) => {
      setSliderRange(newValue);
    };

    const handleGenreChange = (checkedValue: any, genre: any) => {
      if (checkedValue) {
        setProductGenre([...productGenre, genre]);
      } else {
        setProductGenre(productGenre.filter((item: any) => item !== genre));
      }
    };

    const handleBrandChange = (checkedValue: boolean, brand: string) => {
      if (checkedValue) {
        setProductBrand([...productBrand, brand]);
      } else {
        setProductBrand(productBrand.filter(item => item !== brand));
      }
    };

    const items: CollapseProps["items"] = [
      {
        key: "1",
        label: "ပစ္စည်းအမျိုးအစား",
        children: (
          <div style={{ maxHeight: 212, overflowY: "auto" }}>
            <ProductTypeFilterList value={productType} onChangeValue={setProductType} />
          </div>
        ),
      },
      {
        key: "2",
        label: "ဈေးနှုန်း",
        children: <ProductPriceFilter value={sliderRange} onChangeValue={handleSliderChange} />,
      },
      {
        key: "3",
        label: "ပုံစံအမျိုးအမည်",
        children: (
          <div style={{ maxHeight: 212, overflowY: "auto" }}>
            <ProductGenreFilterList value={productGenre} onChangeValue={handleGenreChange} />
          </div>
        ),
      },
    ];

    if (productGenre.length > 0) {
      items.push({
        key: "4",
        label: "ဘရန်းအမျိုးအစား",
        children: (
          <div style={{ maxHeight: 212, overflowY: "auto" }}>
            <ProductBrandFilterList value={productBrand} onChangeValue={handleBrandChange} />
          </div>
        ),
      });
    }

    return (
      <>
        <StyledBackground
          animate={open ? { opacity: 0.6, zIndex: 3999 } : { opacity: 0, display: "none" }}
          initial={{ opacity: 0 }}
        />
        <AnimatePresence initial={false}>
          {open && (
            <BottomSheet
              key={"content"}
              initial="collapsed"
              animate="open"
              exit={"collapsed"}
              variants={{
                open: { y: 0, height: "auto" },
                collapsed: { y: "100%", height: 0 },
              }}
              transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}>
              <Flex vertical ref={ref} style={{ padding: "24px 16px" }}>
                <ProductFilterHeader setOpen={setOpen} clearFilter={clearFilter} />
                <StyledCollapse items={items} defaultActiveKey={["1"]} />
              </Flex>
            </BottomSheet>
          )}
        </AnimatePresence>
      </>
    );
  }
);

export default MobileProductFilter;
