export const COLORS = {
  // base colors
  // app color
  primary: "#4E008E",
  secondary: "#0095FF",

  // usage colors
  borderColor: "#D4D5D6",
  borderOpacity: "rgba(238,238,238)",
  background: "#F3F4F7",

  // colors
  black: "#1C1C1C",
  black1: "#17181B",
  white: "#FFFFFF",
  grayWhite: "#F1F1F1",
  greyWhite: "#E9E9E9",
  greyWhite1: "#EEEEEE",
  lightGray: "#888B90",
  lightGray1: "#adb1b8",
  lightGray2: "#EEE",
  lightGray3: "#D4D5D6",
  lightGray4: "#C4C4C4",
  lightGray5: "#7D7E84",
  lightGray6: "#F5F5F5",
  lightGray7: "#747578",
  lightGray8: "#8F8F8F",
  lightGray9: "#EBEBEB",
  lightGray10: "#B6B6B7",
  lightGray11: "#D4D5D8",
  lightGray12: "#F3F4F5",
  lightGray13: "#FAFAFA",
  grey: "#202020",
  grey1: "#202124",
  gray: "#2D3038",
  gray1: "#282C35",
  gray2: "#404347",

  red: "#EC334D",
  red2: "#F9475F",
  blue: "#6280F9",
  purple: "#6335B4",
  green: "#00C4CB",
  orange: "#FFA800",
};

export const SIZES = {
  // base colors
  primary: "14px",
  secondary: "12px",
  h1: "32px",
  h2: "28px",
  h3: "20px",
  h4: "18px",
  h5: "16px",
  h6: "14px",
  h7: "12px",
  h8: "10px",
  body1: "14px",
  body2: "12px",
  body3: "10px",
  body4: "8px",
  body5: "6px",
};

export const FONTS = {
  h1: {
    fontFamily: "Noto Sans Myanmar",
    fontSize: SIZES.h1,
    color: "#000"
  },
  h2: {
    fontFamily: "Noto Sans Myanmar",
    fontSize: SIZES.h2,
    color: "#000"
  },
  h3: {
    fontFamily: "Noto Sans Myanmar",
    fontSize: SIZES.h3,
    color: "#000"
  },
  h4: {
    fontFamily: "Noto Sans Myanmar",
    fontSize: SIZES.h4,
    color: "#000"
  },
  h5: {
    fontFamily: "Noto Sans Myanmar",
    fontSize: SIZES.h5,
    color: "#000"
  },
  h6: {
    fontFamily: "Noto Sans Myanmar",
    fontSize: SIZES.h6,
    color: "#000"
  },
  h7: {
    fontFamily: "Noto Sans Myanmar",
    fontSize: SIZES.h7,
  },
  h8: {
    fontFamily: "Noto Sans Myanmar",
    fontSize: SIZES.h8,
    color: "#000"
  },
  body1: {
    fontFamily: "Spoqa Han Sans Neo, sans-serif",
    fontSize: SIZES.body1,
    color: "#000"
  },
  body2: {
    fontFamily: "Spoqa Han Sans Neo, sans-serif",
    fontSize: SIZES.body2,
    color: "#000"
  },
  body3: {
    fontFamily: "Spoqa Han Sans Neo, sans-serif",
    fontSize: SIZES.body3,
    color: "#000"
  },
  body4: {
    fontFamily: "Spoqa Han Sans Neo, sans-serif",
    fontSize: SIZES.body4,
    color: "#000"
  },
  body5: {
    fontFamily: "Spoqa Han Sans Neo, sans-serif",
    fontSize: SIZES.body5,
    color: "#000"
  },
};
