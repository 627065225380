import {
  AboutUsPage,
  BlogsByCategoryPage,
  CategoriesPage,
  HomePage,
  NotFoundPage,
  PolicyPage,
} from "@/app/pages";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import BlogsRoute from "./BlogsRoute";
import ProductsRoute from "./ProductsRoute";
import ServicesRoute from "./ServicesRoute";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/blogs" element={<BlogsRoute />} />
        <Route path="/blogs/categories" element={<BlogsByCategoryPage />} />
        <Route path="/policy" element={<PolicyPage />} />
        <Route path="/aboutus" element={<AboutUsPage />} />
        <Route path="/categories" element={<CategoriesPage />} />
        <Route path="/products" element={<ProductsRoute />} />
        <Route path="/services" element={<ServicesRoute />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
