import { Text } from "@/styles/reactnative";
import styled from "styled-components";

export const ServiceContainer = styled.div<{$isMobile: boolean}>`
    display: flex;
    flex-direction: column;
    gap: ${props => props?.$isMobile ? "32px" : "64px"};
`

export const ServiceTitle = styled(Text)<{$isMobile: boolean}>`
    font-size: ${props => props?.$isMobile ? "12px" : "24px"};
    font-weight: 500;
`