import { Text } from "@/styles/reactnative";
import { FONTS } from "@/styles/styles";
import styled from "styled-components";

export const BlogSectionTitle = styled(Text)<{ $isMobile: boolean }>`
  font-size: ${props => (props.$isMobile ? FONTS.h5 : FONTS.h1)};
  font-weight: 600;
  font-style: normal;
  max-width: ${props => (props.$isMobile ? "227px" : "auto")};
  margin-bottom: 21px;
  text-align: center;
`;

export const BlogSectionDescription = styled(Text)<{ $isMobile: boolean }>`
  font-size: ${props => (props.$isMobile ? FONTS.h7 : FONTS.h5)};
  font-style: normal;
  font-weight: 400;
  color: #52525b;
  text-align: center;
  max-width: 601px;
`;

export const BlogCreatedAt = styled(Text)`
  font-size: ${FONTS.h6};
  font-weight: 400;
  font-style: normal;
  color: #52525b;
  margin-top: 20px;
`;

export const BlogTitle = styled(Text)<{ $isMobile: boolean }>`
  font-size: ${props => (props.$isMobile ? FONTS.h5 : FONTS.h3)};
  font-style: normal;
  font-weight: 600;
  margin-top: 15px;
  max-width: 328px;
  line-height: 32px;

  /* Ensures text is truncated after 2 lines */
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limits text to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
