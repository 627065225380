import { DesktopFooter, ServiceCategoryHeader, ServiceHeader } from "@/app/components/Desktop";
import { MobileNavbar } from "@/app/components/Mobile";
import { ServiceTypesList } from "@/app/features";
import { MobileSearchContainer } from "@/app/layouts/Mobile";
import { Flex } from "antd";
import React, { useState } from "react";

const MobileView = ({ parentId, title, description, servicePaths }) => {
  const [showSearchContainer, setShowSearchContainer] = useState(false);
  return (
    <main>
      <MobileSearchContainer
        showSearchContainer={showSearchContainer}
        setShowSearchContainer={setShowSearchContainer}
      />
      <Flex vertical style={{ paddingInline: 16, marginTop: 90 }}>
        {!showSearchContainer && <MobileNavbar setShowSearchContainer={setShowSearchContainer} />}
        <ServiceHeader maintitle={"ဝန်ဆောင်မှု"} subtitles={servicePaths} chevIcon />
        <ServiceCategoryHeader title={title} description={description} />
        <ServiceTypesList parentId={parentId} title={title} servicePaths={servicePaths} />
      </Flex>
      <DesktopFooter />
    </main>
  );
};

export default MobileView;
