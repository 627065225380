import { CloseCircleFilled } from "@ant-design/icons";
import { Button, Flex, Typography } from "antd";
import React from "react";

const ProductFilterHeader = ({ setOpen, clearFilter }) => {

  const handleClearFilter = () => {
    // Avoid updating parent state directly during render
    setTimeout(() => {
      clearFilter();
      setOpen(false); // Close the filter after clearing it
    }, 0);
  };
  return (
    <Flex align="center" justify="space-between">
      <CloseCircleFilled
        style={{ color: "#000", cursor: "pointer" }}
        onClick={() => setOpen(false)}
      />
      <Typography.Text
        style={{
          color: "#414141",
          fontSize: 13.56,
          fontWeight: 500,
          lineHeight: "20px",
          marginLeft: 40,
        }}>
        ရွေးထုတ်ခြင်း
      </Typography.Text>
      <Button
        onClick={handleClearFilter}
        style={{ color: "#485AFF", fontSize: 13.56, fontWeight: 500, lineHeight: "20px" }}
        type="text">
        ပယ်ဖျက်မည်
      </Button>
    </Flex>
  );
};

export default ProductFilterHeader;
