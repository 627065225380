import { Divider, Flex, Typography } from "antd";
import React from "react";
import styled from "styled-components";

const FilterTypeTitle = styled(Typography.Text)`
  font-size: 16px;
  font-weight: 500;
  color: #000;
  line-height: 24px;
`;

const CustomScrollContainer = styled.div`
  height: 212px;
  width: 100%;
  overflow: scroll;
  overflow-y: scroll;
  scrollbar-width: 8px;
  overflow-x: hidden;

  /* Custom scrollbar styles */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f0f0f0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #b3b3b3;
    border-radius: 10px;
    border: 2px solid #f0f0f0;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #888;
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: #b3b3b3 #f0f0f0;
`;

const FilterTypeContainer = ({ title, children, scrollable }) => {
  return (
    <Flex vertical gap={24} style={{marginBottom: 40}}>
      <FilterTypeTitle>{title}</FilterTypeTitle>
      {scrollable ? <CustomScrollContainer>{children}</CustomScrollContainer> : children}
      <div style={{ width: "100%", height: '1px', backgroundColor: "#C9CFD2" }}></div>
    </Flex>
  );
};

export default FilterTypeContainer;
