import { DesktopFooter, DesktopNavbar } from "@/app/components/Desktop";
import { ProductsList } from "@/app/features";
import { ProductsFilter } from "@/app/layouts/Desktop";
import { useDesktopDimensions, useScrollToTop } from "@/hooks";
import { Flex } from "antd";
import React, { useState } from "react";

const DesktopView = () => {
  const { isDesktop } = useDesktopDimensions();
  const [isFilter, setIsFilter] = useState(false);
  const [productType, setProductType] = useState("ပစ္စည်းများအားလုံး");
  const [productGenre, setProductGenre] = useState<any>([]);
  const [productBrand, setProductBrand] = useState<any>([]);
  const [searchResult, setSearchResult] = useState<any>(null);
  const [sliderRange, setSliderRange] = useState<[number, number]>([1, 10000000]);

  const clearFilter = () => {
    setProductType("ပစ္စည်းများအားလုံး");
    setProductBrand([]);
    setProductGenre([]);
    setIsFilter(false);
  };
  // console.log(productType, productGenre, productBrand);
  useScrollToTop();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "space-between",
      }}>
      <DesktopNavbar />
      <Flex gap={24} style={{ paddingInline: isDesktop ? 20 : 72, marginTop: 130 }}>
        <ProductsFilter
          productType={productType}
          setProductType={setProductType}
          productGenre={productGenre}
          setProductGenre={setProductGenre}
          productBrand={productBrand}
          setProductBrand={setProductBrand}
          setIsFilter={setIsFilter}
          searchResult={searchResult}
          setSearchResult={setSearchResult}
          sliderRange={sliderRange}
          setSliderRange={setSliderRange}
        />
        <ProductsList
          productType={productType}
          productGenre={productGenre}
          setProductGenre={setProductGenre}
          productBrand={productBrand}
          setProductBrand={setProductBrand}
          isFilter={isFilter}
          clearFilter={clearFilter}
          searchResult={searchResult}
          sliderRange={sliderRange}
        />
      </Flex>
      <DesktopFooter />
    </div>
  );
};

export default DesktopView;
