import { CText } from '@/app/common';
import { Text } from '@/styles/reactnative';
import React from 'react'

const BlogDetailSectionContentLink = ({item}) => {
  return (
    <>
      <a href={item?.text} style={{fontStyle: 'italic'}}>{item?.text}</a>
    </>
  );
}

export default BlogDetailSectionContentLink