import React from "react";

interface CPargraphProps {
  lines?: number;
  children: any;
  style?: any;
}

const CParagraph = ({ lines, children, style }:CPargraphProps) => {
  return (
    <p
      style={{
        display: "-webkit-box",
        WebkitLineClamp: lines,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        ...style,
      }}>
      {children}
    </p>
  );
};

export default CParagraph;
