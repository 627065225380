import { Text } from "@/styles/reactnative";
import { FONTS } from "@/styles/styles";
import { Typography } from "antd";
import styled from "styled-components";

export const HeroContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-height: 617px;
    margin-top: 160px;
`

export const HeroImage = styled.img`
    width: 503px;
    height: 100%;
    object-fit: cover;
    border-radius: 40px;
`

export const HeroTitle = styled(Typography.Text)<{$isDesktop: boolean}>`
    font-size: ${props => props?.$isDesktop ? "40px" : "80px"};
    font-style: normal;
    font-weight: 700;
    color: #000;
    line-height: ${props => props?.$isDesktop ? "46px" : "86px"};
    letter-spacing: ${props => props?.$isDesktop ? "0px" : "-4px"};
`

export const HeroDescription = styled(Text)`
    font-size: ${FONTS.h3};
    font-style: normal;
    font-weight: 500;
    color: #4B5262;
    line-height: 50px;
    margin-top: 32px;
    max-width: 533px;
`