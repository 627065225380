import { CImage } from "@/app/common";
import { CustomHeader, DesktopFooter, DesktopNavbar } from "@/app/components/Desktop";
import { images } from "@/assets/images";
import { useScrollToTop } from "@/hooks";
import { Flex, Typography } from "antd";
import React from "react";

const AboutUsPage = () => {
  useScrollToTop();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "space-between",
      }}>
      <DesktopNavbar />
      <Flex vertical style={{ marginTop: 130, paddingInline: 71 }}>
        <CustomHeader maintitle={"Ulsan Cham"} subtitles={["ကျွန်ုပ််တို့ အကြောင်း"]} />
        <Flex gap={24}>
          <div>
            <Typography.Text
              style={{
                fontSize: 32,
                fontWeight: "600",
                color: "#000",
                lineHeight: "32px",
              }}>
              ကျွန်ုပ််တို့ အကြောင်း
            </Typography.Text>
            <Typography.Paragraph
              style={{ fontSize: 20, lineHeight: "48px", fontWeight: "400", marginTop: 64 }}>
              English ဘာသာ Lorem ipsum စာများသည် ယေဘုယျအားဖြင့် pseudo-Latin စကားလုံးများဖြင့်
              ဖွဲ့စည်းထားခြင်းဖြစ်ပါသည်။ ဒီဇိုင်းများကို နမူနာပြသရန် နှင့် ဒီဇိုင်၏ အရည်အသွေးကို
              စမ်းစစ်ရန် အတွက် placeholder text အနေဖြင့် ကျယ်ပြန့်စွာ အသုံပြုလျှက်ရှိပါသည်။
              စကားလုံးများတွင် အဓိပ္ပာယ် မရှိသည့်အားလျှောစွာ ဖတ်ရှုသူ အနေနဲ့ စာကို ဖတ်ရှုနေစရာ
              မလိုအပ်ပဲ ဒီဇိုင်းကို ပိုမို အာရုံစိုက်နိုင်ပါသည်။ ဘာသာစကား အသီးသီးတွင် Lorem ipsum
              များရှိသကဲ့သိုပင် ယခုမှာ မြန်မာ ဘာသာ အတွက် ဖြစ်ပါသည်။ <br /> <br /> ယခု စာမျက်နှာ သည်
              Lorem ipsum များကို မြန်မာ Version အနေနဲ့ ထုတ်ပေးထားခြင်းဖြစ်ပါသည်။ စာပိုဒ် (၅)
              ပိုဒ်ပါ၀င်ပြီး စာပိုဒ် တစ်ခုချင်းစီတွင် ၀ါကျ (၅) ခုမှ (၆) အထိပါ၀င်ပါသည်။
              စာလုံးတိုင်းတွင် လူသုံးနည်းသော ပါဠိ စာတစ်၀က် နှင့် လူသုံးများသော မြန်မာစာ တစ်၀က် ပါ၀င်
              ပါသည်။ ပါ၀င်သော ပါဠိစာများသည် ပုံမှန် စာဖတ်သူများ အတွက် ဖတ်ရှုရန် ခက်ခဲသော
              စာများဖြစ်စေပါသည်။ pseudo-sentence စာများကို generate လုပ်သည့် အဆင့် တစ်ဆင့်စီကို
              အောက်ပါစာပိုဒ်တွင် ဖော်ပြထားပါသည်။ <br /> <br /> ပါဠိစာအမှန် (၁၂) ကြောင်း နှင့် မြန်မာ
              စာအမှန် (၁၂) ကြောင်း၏ ဝဏ္ဏ syllable များကို ကျပန်း ရောမွှေ ပါသည်။ အဆိုပါ ကျပန်း ဝဏ္ဏ
              (၂) လုံး မှ (၆) လုံးအထိကိုစုစည်းပြီး ကျပန်း ပုဒ်စု phrase ကိုရရှိပါသည်။ အဆိုပါ ပုဒ်စု
              (၃) ခုမှ (၉) ခုကို စုစည်းပြီး ၀ါကျ sentence များကို တည်ဆောက်ပါသည်။ ရလဒ်အနေနှင့်
              စာအမှန်နှင့် အမြင်တွင်လွန်စွာ ဆင်တူပြီး ဖတ်ရှုရန် မလွယ်သော ၀ါကျများကို ရရှိပါသည်။
            </Typography.Paragraph>
          </div>
          <Flex vertical gap={60}>
            <CImage
              src={images.dummy_blog_1}
              style={{ width: 526, height: 526, borderRadius: 12, objectFit: "cover" }}
            />
            <CImage
              src={images.dummy_blog_2}
              style={{ width: 526, height: 526, borderRadius: 12, objectFit: "cover" }}
            />
          </Flex>
        </Flex>
      </Flex>
      <DesktopFooter />
    </div>
  );
};

export default AboutUsPage;
