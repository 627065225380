import { CParagraph } from "@/app/common";
import { useMobileDimensions } from "@/hooks";
import { Text } from "@/styles/reactnative";
import { Flex } from "antd";
import React from "react";

const ServiceCategoryHeader = ({ title, description }) => {
  const { isMobile } = useMobileDimensions();
  return (
    <Flex vertical style={{ marginTop: isMobile ? 36 : 64, marginBottom: isMobile ? 40 : 80 }}>
      <Text
        style={{
          color: "#000",
          fontSize: isMobile ? 16 : 24,
          fontWeight: "500",
          lineHeight: isMobile ? "25.6px" : "32px",
        }}>
        {title}
      </Text>
      <CParagraph
        style={{
          color: "#4E4C4C",
          fontSize: isMobile ? 10 : 16,
          fontWeight: 400,
          lineHeight: isMobile ? "20px" : "32px",
          marginTop: isMobile ? 32 : 40,
        }}>
        {description}
      </CParagraph>
    </Flex>
  );
};

export default ServiceCategoryHeader;
