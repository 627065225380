import {
  ServiceAddition,
  ServiceAddress,
  ServicePhonenumber,
  ServiceTime,
  ServiceUsername,
} from "@/app/entities";
import { useMobileDimensions } from "@/hooks";
import { useApiCreate } from "@/server/api-fetch";
import { CREATE_SERVICE_FORM } from "@/server/apis";
import { Button, Flex, Form, message, Typography } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledButton = styled(Button)<{ $isMobile: boolean }>`
  width: 100%;
  height: ${props => (props?.$isMobile ? "46px" : "72px")};
  padding-block: ${props => (props?.$isMobile ? "12px" : "20px")};
  font-size: ${props => (props?.$isMobile ? "14px" : "20px")};
  font-style: normal;
  font-weight: 500;
  line-height: ${props => (props?.$isMobile ? "22.4px" : "32px")};
  border: none;
`;

const ServiceDetailForm = ({ setIsCompleted, servicePaths }) => {
  const [form] = Form.useForm();
  const { isMobile } = useMobileDimensions();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false); // Track submission cooldown

  const { mutate, loading } = useApiCreate({
    url: CREATE_SERVICE_FORM,
    action: data => {
      if (data?.success) {
        message.success(data?.message || "Successfully submitted");
        setIsCompleted(true);

        // form.resetFields();
      } else {
        message.error(data?.message || "Failed to submit");
      }
    },
  });
  const onFinish = async values => {
    if (loading || isSubmitting) return; // Prevent multiple submissions

    const serviceIds = servicePaths?.map((item: any) => item?.parentId);
    if (!values.username || !values.phonenumber || !values.city || !values.time) {
      message.error("Please fill all the required fields");
      return;
    }
    if (!serviceIds?.length) {
      message.error("Please select a service");
      return;
    }

    // Set the cooldown for 10 seconds
    setIsSubmitting(true);
    setTimeout(() => {
      setIsSubmitting(false); // Reset cooldown after 10 seconds
    }, 100);

    // Proceed with form submission
    mutate({
      servicechoose: serviceIds,
      name: values.username,
      contact: values.phonenumber,
      address: values.city,
      availableSchedule: values.time,
      description: values.addition,
    });
  };

  const onFinishFailed = errorInfo => {
    console.log("Failed: ", errorInfo);
  };

  return (
    <Flex vertical gap={isMobile ? 40 : 64}>
      <Typography.Text
        style={{
          color: "#000",
          fontSize: isMobile ? 16 : 24,
          fontWeight: "500",
          lineHeight: isMobile ? "25.6px" : "32px",
        }}>
        ဝန်ဆောင်မှု လျှောက်ထားခြင်းကို အောက်ပါဖောင်အား ဖြည့်စွက်ပြီး စတင်လိုက်ပါ။
      </Typography.Text>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{
          time: "၂၄ နာရီအတွင်း",
        }}>
        <Flex
          vertical={isMobile ? true : false}
          align="center"
          justify="space-between"
          gap={isMobile ? 0 : 32}>
          <ServiceUsername />
          <ServicePhonenumber />
        </Flex>
        <Flex
          vertical={isMobile ? true : false}
          align="center"
          justify="space-between"
          gap={isMobile ? 0 : 32}>
          <ServiceAddress />
          <ServiceTime />
        </Flex>
        <ServiceAddition />
        <Flex
          align="center"
          justify="space-between"
          gap={isMobile ? 16 : 32}
          style={{ marginTop: isMobile ? 64 : 100 }}>
          <Form.Item style={{ flex: 1 }}>
            <StyledButton
              $isMobile={isMobile}
              htmlType="reset"
              onClick={() => navigate(-1)}
              style={{ backgroundColor: "#F0F1FC", color: "#212121" }}>
              မလုပ်တော့ပါ
            </StyledButton>
          </Form.Item>
          <Form.Item style={{ flex: 1 }}>
            <StyledButton
              $isMobile={isMobile}
              htmlType="submit"
              style={{ backgroundColor: "#485AFF", color: "#FFFFFF" }}
              disabled={loading || isSubmitting}>
              ဝန်ဆောင်မှုအား လျှောက်ထားမည်
            </StyledButton>
          </Form.Item>
        </Flex>
      </Form>
      <Typography.Paragraph
        style={{
          fontSize: isMobile ? 18 : 24,
          color: "#000",
          fontWeight: "400",
          lineHeight: isMobile ? "24px" : "32px",
        }}>
        အရေးပေါ် အကူအညီ လိုအပ်ပါက ဖော်ပြထားသည့် ဖုန်းနံပါတ်များအား ချက်ချင်းဆက်သွယ်နိုင်ပါသည်။
        <br />
        <br />
        010-8745-1092
      </Typography.Paragraph>
    </Flex>
  );
};

export default ServiceDetailForm;
