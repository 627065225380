import React from "react";
import DesktopView from "./DesktopView";
import { useMobileDimensions, useScrollToTop } from "@/hooks";
import MobileView from "./MobileView";
import { useApiQuery } from "@/server/api-fetch";
import { GET_SERVICE_BY_PARENT_ID } from "@/server/apis";

const BlogDetailPage = ({ id, title }) => {
  const { isMobile } = useMobileDimensions();

  useScrollToTop();
  const { data, isLoading } = useApiQuery({
    url: `${GET_SERVICE_BY_PARENT_ID}?parentId=${""}&featured=${2}`,
  });


  return (
    <>
      {isMobile ? (
        <MobileView title={title} serviceData={data} />
      ) : (
        <DesktopView title={title} serviceData={data} />
      )}
    </>
  );
};

export default BlogDetailPage;
