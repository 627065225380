export function blogCalculateReadingTime(content) {
  const charactersPerMinute = 1000; // Adjust this value based on average reading speed in characters per minute
  let totalCharacters = 0;

  const processItem = item => {
    if (item.type === "TEXT" && item.text) {
      totalCharacters += item.text.length; // Count characters instead of words
    }
  };

  content?.forEach?.(contentItem => {
    if (contentItem?.content) {
      contentItem?.content?.forEach?.(processItem);
    } else {
      processItem(contentItem);
    }
  });

  const totalMinutes = totalCharacters / charactersPerMinute;
  const minutes = Math.floor(totalMinutes);
  const seconds = Math.floor((totalMinutes - minutes) * 60);

  if (minutes === 0 && seconds === 0) {
    return "less than a minute read";
  } else if (minutes === 0) {
    // return `${seconds} sec read`
    return `1 min read`;
  } else {
    return `${minutes} min read`;
    // return `${minutes} min ${seconds} sec read`
    // details read
  }
}
