import React, { useEffect } from "react";
import { ServiceContainer } from "./styles";
import { motion } from "framer-motion";
import { textVariant } from "@/utils/motion";
import { SectionWrapper } from "@/app/hoc";
import { List } from "antd";
import { BlogLayoutHeader } from "@/app/components/Desktop";
import { useMobileDimensions } from "@/hooks";
import { ServiceCard } from "@/app/entities";
import { GET_SERVICE_BY_PARENT_ID } from "@/server/apis";
import { useApiQuery } from "@/server/api-fetch";
import { useDispatch, useSelector } from "react-redux";
import { resetPath } from "@/stores/Slices/serviceSlice";

const ServiceSection = ({
  title = "ဝန်ဆောင်မှုများ",
  isViewAll = true,
  isPaginate = false,
  href = "",
  featured = 0,
}) => {
  const dispatch = useDispatch();
  const { isMobile } = useMobileDimensions();
  const servicePaths = useSelector((state: any) => state.service?.path);
  const lastServicePath = servicePaths?.length ? servicePaths?.[servicePaths?.length - 1] : null;
  const { data, isLoading } = useApiQuery({
    url: `${GET_SERVICE_BY_PARENT_ID}?parentId=${
      lastServicePath?.parentId || ""
    }&featured=${featured}`,
  });

  useEffect(() => {
    dispatch(resetPath());
  }, []);

  return (
    <ServiceContainer $isMobile={isMobile}>
      <motion.div variants={textVariant()}>
        <BlogLayoutHeader title={title} isViewAll={isViewAll} isPaginate={isPaginate} href={href} />
      </motion.div>
      {data?.length ? (
        <List
          itemLayout="vertical"
          size="large"
          loading={isLoading}
          dataSource={data}
          renderItem={(item: any, index) => (
            <ServiceCard
              item={item}
              key={item?._id}
              style={{
                marginRight: isMobile ? 0 : index % 2 === 0 ? 24 : 0,
                marginBottom: 24,
              }}
            />
          )}
          grid={{
            xs: 1,
            sm: 1,
            md: 2,
            lg: 2,
            xl: 2,
            xxl: 2,
          }}
        />
      ) : null}
    </ServiceContainer>
  );
};

export default SectionWrapper(ServiceSection, "serviceSection");
