import { Typography } from "antd";
import styled from "styled-components";

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: 24px;
    padding-inline: 16px;
`

export const MobileNavbarTitle = styled(Typography.Text)`
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color: #fff;
    cursor: pointer;
`