import { Flex, Typography } from "antd";
import React, { useState } from "react";
import {
  StyledButton,
  StyledDetailTitle,
  StyledParagraph,
  StyledPrice,
  StyledText,
  StyledTitle,
} from "./styles";
import { useMobileDimensions } from "@/hooks";
import { Dot } from "@/app/components/Desktop/DesktopBlogLayout/styles";

const Details = ({ data }) => {
  const { isMobile } = useMobileDimensions();

  const featureList = [
    "ပါဝါကြီးမားသော ကင်မရာများ: ၎င်းတွင် 200MP အဓိကကင်မရာ၊ တယ်လီဖိုတိုကင်မရာ၊ နှစ်ချက်အကျယ်ကင်မရာနှင့် ဆယ်ဖီကင်မရာတို ပါဝင်သည်။ ၎င်းတိုသည် ညအချိန်တွင်ပင် ကြည်လင်သောဓာတ်ပုံများကို ရိုက်ကူးနိုင်သည်။",
    "ခေတ်မီသည့် ပရိုဆက်ဆာ: Samsung Galaxy S24 Ultra 5G သည် နောက်ဆုံးထွက် Snapdragon ပရိုဆက်ဆာကို အသုံးပြုထားသည်။ ၎င်းသည် ပြီးပြည့်စုံသော စွမ်းဆောင်ရည်နှင့် ချောမွေ့သော လုပ်ဆောင်မှုကို ပေးစွမ်းသည်။",
    "ကြီးမားသော ပြင်ညီမျက်နှာပြင်: ၎င်းတွင် ကြီးမားသော AMOLED ပြင်ညီမျက်နှာပြင်ပါရှိပြီး ကြည်လင်သော ပုံရိပ်များနှင့် ကောင်းမွန်သော ကြည့်ရှုခံစားမှုကို ပေးစွမ်းသည်။",
    "အရည်အသွေးမြင့် ဒီဇိုင်း: Samsung Galaxy S24 Ultra 5G သည် ခေတ်မီသော ဒီဇိုင်းနှင့် သွေးပူတောက်လျှံသော အရောင်များဖြင့် လှပသည်။",
    "Samsung Galaxy S24 Ultra 5G သည် နည်းပညာကမ္ဘာတွင် ထိပ်တန်းဖုန်းတစ်လုံးဖြစ်ပြီး သုံးစွဲသူများအတွက် အံ့သြဖွယ်ကောင်းသော အတွေ့အကြုံကို ပေးစွမ်းသည်။",
    "ပါဝါကြီးမားသော ကင်မရာများ: ၎င်းတွင် 200MP အဓိကကင်မရာ၊ တယ်လီဖိုတိုကင်မရာ၊ နှစ်ချက်အကျယ်ကင်မရာနှင့် ဆယ်ဖီကင်မရာတို ပါဝင်သည်။ ၎င်းတိုသည် ညအချိန်တွင်ပင် ကြည်လင်သောဓာတ်ပုံများကို ရိုက်ကူးနိုင်သည်။",
    "ခေတ်မီသည့် ပရိုဆက်ဆာ: Samsung Galaxy S24 Ultra 5G သည် နောက်ဆုံးထွက် Snapdragon ပရိုဆက်ဆာကို အသုံးပြုထားသည်။ ၎င်းသည် ပြီးပြည့်စုံသော စွမ်းဆောင်ရည်နှင့် ချောမွေ့သော လုပ်ဆောင်မှုကို ပေးစွမ်းသည်။",
    "ကြီးမားသော ပြင်ညီမျက်နှာပြင်: ၎င်းတွင် ကြီးမားသော AMOLED ပြင်ညီမျက်နှာပြင်ပါရှိပြီး ကြည်လင်သော ပုံရိပ်များနှင့် ကောင်းမွန်သော ကြည့်ရှုခံစားမှုကို ပေးစွမ်းသည်။",
    "အရည်အသွေးမြင့် ဒီဇိုင်း: Samsung Galaxy S24 Ultra 5G သည် ခေတ်မီသော ဒီဇိုင်းနှင့် သွေးပူတောက်လျှံသော အရောင်များဖြင့် လှပသည်။",
    "Samsung Galaxy S24 Ultra 5G သည် နည်းပညာကမ္ဘာတွင် ထိပ်တန်းဖုန်းတစ်လုံးဖြစ်ပြီး သုံးစွဲသူများအတွက် အံ့သြဖွယ်ကောင်းသော အတွေ့အကြုံကို ပေးစွမ်းသည်။",
  ];
  const [seeMore, setSeeMore] = useState(featureList.length > 5);

  return (
    <Flex vertical>
      <Flex gap={12} style={{ marginBottom: 12 }} align="center">
        <StyledText $isMobile={isMobile}>{data?.category}</StyledText>
        <Dot />
        <StyledText $isMobile={isMobile}>{data?.type}</StyledText>
        <Dot />
        <StyledText $isMobile={isMobile}>{data?.brand}</StyledText>
      </Flex>
      <StyledTitle $isMobile={isMobile}>{data?.name}</StyledTitle>
      <StyledPrice $isMobile={isMobile}> ₩{data?.price} </StyledPrice>
      <StyledDetailTitle>အသေးစိတ်အချက်အလက်</StyledDetailTitle>
      <StyledParagraph $isMobile={isMobile}>{data?.description}</StyledParagraph>
      <div style={{ marginTop: 16 }}>
        <StyledParagraph $isMobile={isMobile}>အင်္ဂါရပ်များ</StyledParagraph>
        <ul style={{ marginTop: 16 }}>
          {(!seeMore ? featureList : featureList.slice(0, 5)).map((item, index) => (
            <li style={{ color: "#000", fontSize: 12 }} key={index}>
              <StyledParagraph $isMobile={isMobile}>{item}</StyledParagraph>
            </li>
          ))}
        </ul>
        {seeMore && <Typography.Link onClick={() => setSeeMore(false)}>See More</Typography.Link>}
      </div>
      <StyledButton
        $isMobile={isMobile}
        type="primary"
        style={{
          backgroundColor: "#485AFF",
        }}>
        မှာယူမည်
      </StyledButton>
    </Flex>
  );
};

export default Details;
