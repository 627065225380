import React from "react";
import { StyledMessage, StyledMessageText, StyledProfileName, StyledTimeStamp } from "./styles";
import { CImage } from "@/app/common";
import { Flex } from "antd";

const Message = ({ message, showProfile, showTime }) => {
  const isSender = message?.sender?.type === "user";
  return (
    <Flex vertical style={{marginBottom: 8}}>
      {showProfile && (
        <Flex gap={15} style={{ flexDirection: isSender ? "row-reverse" : "row" }}>
          <CImage
            style={{ width: 40, height: 40, borderRadius: "100%", backgroundColor: "#485AFF" }}
          />
          <StyledProfileName>{isSender ? "You" : "Ulsan Cham"}</StyledProfileName>
        </Flex>
      )}
      <StyledMessage $isSender={isSender}>
        <StyledMessageText $isSender={isSender}>{message?.content}</StyledMessageText>
      </StyledMessage>
      {showTime && <StyledTimeStamp $isSender={isSender}>12 min ago</StyledTimeStamp>}
    </Flex>
  );
};

export default Message;
