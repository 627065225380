import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  firstTime: false,
  darkMode: false,
  loggedIn: {
    email: "",
    password: "",
    checked: false,
  },
};

export const appStore = createSlice({
  name: "appstore",
  initialState: initialState,
  reducers: {
    updateFirstTime(state, { payload }) {
      state.firstTime = payload;
    },
    switchMode(state, { payload }) {
      state.darkMode = payload;
    },
    loginForm(state, { payload }) {
      if (payload.checked) {
        state.loggedIn = payload;
      } else {
        state.loggedIn = {
          email: "",
          password: "",
          checked: false,
        };
      }
    },
  },
});
export const { updateFirstTime, switchMode, loginForm } = appStore.actions;

export default appStore.reducer;
