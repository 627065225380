import React from "react";
import { CategoryImage, CategoryName, Container } from "./styles";
import { useNavigate } from "react-router-dom";
import { images } from "@/assets/images";

const CategoryCard = ({ category }) => {
  const navigation = useNavigate();
  return (
    <Container
      key={category?.id}
      onClick={() => navigation(`/blogs/categories?category=${category?.title}`)}>
      <CategoryImage src={images.blog_2} alt={category?.title} />
      <CategoryName>{category?.title}</CategoryName>
    </Container>
  );
};

export default CategoryCard;
