import dummyProductBrands from "@/data/dummyProductBrands";
import { Checkbox, Space } from "antd";
import React from "react";

const ProductBrandFilterList = ({ value, onChangeValue }) => {
  return (
    <Space direction="vertical">
      {dummyProductBrands.map((item, index) => (
        <Checkbox
          key={index}
          value={item}
          checked={value.includes(item)}
          onChange={e => onChangeValue(e.target.checked, item)}>
          {item}
        </Checkbox>
      ))}
    </Space>
  );
};

export default ProductBrandFilterList;
