import { CParagraph } from "@/app/common";
import { ServiceIcon } from "@/assets/svg";
import { useMobileDimensions } from "@/hooks";
import { setServiceState } from "@/stores/Slices/serviceSlice";
import { Text, View } from "@/styles/reactnative";
import { Flex } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container } from "./styles";
import { ImFacebook } from "react-icons/im";
const ServiceCard = ({ item, style = {} }) => {
  const { isMobile } = useMobileDimensions();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Container $isMobile={isMobile} style={style}>
      <Flex gap={8}>
        <ServiceIcon />
        <Text style={{ fontSize: isMobile ? 10 : 16, fontWeight: "600", color: "#485AFF" }}>
          ဝန်ဆောင်မှု
        </Text>
      </Flex>
      <Text style={{ fontSize: isMobile ? 16 : 20, fontWeight: "500", color: "#000" }}>
        {item?.title}
      </Text>
      <CParagraph
        lines={2}
        style={{
          fontSize: isMobile ? 10 : 16,
          fontWeight: "400",
          color: "#4D4D4D",
          paddingBlock: isMobile ? 1 : 2,
        }}>
        {item?.description}
      </CParagraph>
      <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
        <View
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            window.open(
              item?.socialLink
                ? item?.socialLink
                : "https://www.facebook.com/share/1DE1rM9xjY/?mibextid=LQQJ4d",
              "_blank"
            );
          }}>
          <ImFacebook color="blue" size={24} />
        </View>
        <a
          onClick={() => {
            navigate(
              `/services?parentId=${item?._id}&title=${item?.title}&description=${item?.description}`
            );
            dispatch(
              setServiceState({
                parentId: item?._id,
                title: item?.title,
                description: item?.description,
              })
            );
          }}
          style={{
            fontSize: isMobile ? 12 : 16,
            fontWeight: "600",
            textAlign: "right",
            color: "#485AFF",
          }}>
          အသေးစိတ်ကြည့်ရှုရန်
        </a>
      </View>
    </Container>
  );
};

export default ServiceCard;
