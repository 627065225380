import { CFloatingButton } from "@/app/common";
import { DesktopFooter, DesktopNavbar } from "@/app/components/Desktop";
import { BlogDetail, ContactUsLayout } from "@/app/layouts/Desktop";
import { SvgFloatButton } from "@/assets/svg";
import React, { useState } from "react";

const DesktopView = ({ serviceData, title }) => {
  const [showContactUs, setShowContactUs] = useState(false);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "space-between",
      }}>
      <DesktopNavbar />
      <div style={{ marginTop: 130, paddingInline: 71 }}>
        <BlogDetail title={title} serviceData={serviceData} />
      </div>
      <DesktopFooter />
      <CFloatingButton onClick={() => setShowContactUs(true)} icon={<SvgFloatButton />} />
      <ContactUsLayout open={showContactUs} setOpen={setShowContactUs} />
    </div>
  );
};

export default DesktopView;
