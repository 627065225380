import { staggerContainer } from "@/utils/motion";
import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";

const Section = styled(motion.section)`
  position: relative;
  /* z-index: 0; */
`;

const HashSpan = styled.span`
  position: absolute;
  top: -80px;
`;

const StarWrapper = (Component, idName) =>
  function HOC(props) {
    return (
      <Section
        variants={staggerContainer()}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: true, amount: 0.25 }}>
        <HashSpan id={idName}>&nbsp;</HashSpan>

        <Component {...props} />
      </Section>
    );
  };

export default StarWrapper;
