import { useMobileDimensions } from "@/hooks";
import { Form, Input } from "antd";
import React from "react";
import styled from "styled-components";

const StyledParagraph = styled(Input.TextArea)<{ $isMobile: boolean }>`
  padding: ${props => (props?.$isMobile ? "8px 16px" : "24px")};
  font-size: ${props => (props?.$isMobile ? "12px" : "20px")};
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`;

const ServiceAddition = () => {
  const { isMobile } = useMobileDimensions();
  return (
    <Form.Item
      label="ဖြည့်စွက်ပြောပြလိုသော အကြောင်းအရာများ"
      name="addition"
      style={{ width: "100%" }}
      rules={[{ required: true, message: "*အကြောင်းအရာကို ထည့်ရန်လိုအပ်ပါသည်" }]}
      required>
      <StyledParagraph
        $isMobile={isMobile}
        rows={3}
        placeholder="ဖြည့်စွက်ပြောပြလိုသော အကြောင်းအရာများအား အတိုချုံး ရေးသားဖော်ပြပေးပါ"
        name="addition"
      />
    </Form.Item>
  );
};

export default ServiceAddition;
