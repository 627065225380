import { Typography } from "antd";
import styled from "styled-components";

export const Dot = styled.div<{ $isMobile: boolean }>`
  width: ${props => (props?.$isMobile ? "3px" : "6px")};
  height: ${props => (props?.$isMobile ? "3px" : "6px")};
  border-radius: 100%;
  background-color: #000;
`;

export const StyledText = styled(Typography.Text)<{ $isMobile: boolean }>`
  font-size: ${props => (props?.$isMobile ? "10px" : "16px")};
  font-weight: 400;
  line-height: ${props => (props?.$isMobile ? "16px" : "24px")};
  color: #18181b;
`;
