import React from "react";
import { SearchItemsContainer, StyledSearchedText } from "./styles";
import { Flex } from "antd";
import { useApiQuery } from "@/server/api-fetch";
import { SEARCH_BLOG_RECOMMENDATION } from "@/server/apis";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Text, View } from "@/styles/reactnative";

const BlogRecommendationList = ({ searchQuery }) => {
  const navigate = useNavigate();
  const { data, isLoading, error } = useApiQuery({
    url: `${SEARCH_BLOG_RECOMMENDATION}?search=${searchQuery}`,
  });
  return (
    <SearchItemsContainer>
      {error && (
        <View>
          <Text>Failed to load blogs</Text>
        </View>
      )}
      {!isLoading && data?.length === 0 && (
        <View>
          <Text>No blogs found</Text>
        </View>
      )}
      {data?.map?.((item, index) => (
        <Flex
          align="center"
          gap={12}
          key={item._id || index}
          onClick={() => {
            navigate(`/blogs?id=${item?._id}&title=${item?.title}`);
          }}>
          <SearchOutlined style={{ color: "#000", fontSize: 18 }} />
          <StyledSearchedText style={{ color: "#000" }}>{item?.title}</StyledSearchedText>
        </Flex>
      ))}
      {isLoading && (
        <View>
          <Text>Loading...</Text>
        </View>
      )}
    </SearchItemsContainer>
  );
};
export default BlogRecommendationList;
