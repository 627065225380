import { Radio, Space } from "antd";
import React from "react";
import { StyledRadio } from "./styles";
import { GET_CATEGORIES } from "@/server/apis/categories";
import { useApiQuery } from "@/server/api-fetch";

const ProductTypeFilterList = ({ value, onChangeValue }) => {
  const { data } = useApiQuery({
    url: `${GET_CATEGORIES}?type=product`,
  });

  return (
    <Radio.Group value={value} onChange={e => onChangeValue(e.target.value)}>
      <Space direction="vertical">
        {data?.map?.((item, index) => (
          <StyledRadio key={index} value={item} $isSelected={value === item}>
            {item?.title}
          </StyledRadio>
        ))}
      </Space>
    </Radio.Group>
  );
};

export default ProductTypeFilterList;
