import { Message } from "@/app/entities";
import dummyChat from "@/data/dummyChat";
import { Text } from "@/styles/reactnative";
import { Flex, List, Skeleton } from "antd";
import dayjs from "dayjs";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";

const StyledDivider = styled.div`
  flex: 1;
  height: 1px;
  background-color: #b4bac9;
`;

const MessagesList = () => {
  const getTimelineLabel = (currentMessageTime, previousMessageTime) => {
    if (!previousMessageTime || !currentMessageTime.isSame(previousMessageTime, "day")) {
      if (currentMessageTime.isSame(dayjs(), "day")) {
        return "Today";
      } else if (currentMessageTime.isSame(dayjs().subtract(1, "day"), "day")) {
        return "Yesterday";
      } else {
        return currentMessageTime.format("MMMM D, YYYY");
      }
    }
    return null;
  };

  const loadMoreData = () => {};
  return (
    <div>
      <InfiniteScroll
        dataLength={dummyChat?.length}
        hasMore={false}
        next={loadMoreData}
        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
        scrollableTarget="scrollableDiv">
        <List
          dataSource={dummyChat}
          renderItem={(message, index) => {
            const previousMessage = dummyChat[index - 1];
            const timelineLabel = getTimelineLabel(
              dayjs(message.time),
              previousMessage && dayjs(previousMessage.time)
            );

            return (
              <div key={index}>
                {timelineLabel && (
                  <Flex align="center" gap={16}>
                    <StyledDivider />
                    <Text
                      style={{
                        fontSize: 16,
                        color: "#18181B",
                        fontWeight: "500",
                        lineHeight: "32px",
                      }}>
                      {timelineLabel}
                    </Text>
                    <StyledDivider />
                  </Flex>
                )}
                <Message
                  message={message}
                  showProfile={message?.sender?.type !== dummyChat[index - 1]?.sender?.type}
                  showTime={message?.sender?.type !== dummyChat[index + 1]?.sender?.type}
                />
              </div>
            );
          }}
        />
      </InfiniteScroll>
    </div>
  );
};

export default MessagesList;
