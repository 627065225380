import { Text } from "@/styles/reactnative";
import { FONTS } from "@/styles/styles";
import styled from "styled-components";

export const NavbarContainer = styled.div`
    min-height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-inline: 24px;
`

export const NavbarTitle = styled(Text)`
    font-size: ${FONTS.h6};
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 3px;
    text-transform: uppercase;
`

export const NavbarText = styled(Text)`
    font-size: ${FONTS.h6};
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
`

export const Marker = styled.div`
    width: 20px;
    height: 2px;
    border-radius: 4px;
    background-color: #18181B;
`