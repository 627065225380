import {
  CustomCarousel,
  DesktopAdSection,
  DesktopFooter,
  DesktopNavbar,
} from "@/app/components/Desktop";
import {
  CategorySection,
  PopularBlogsSection,
  RecommandBlogs,
  SampleBlogs,
} from "@/app/layouts/Desktop";
import React from "react";

const DesktopView = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "space-between",
      }}>
      <DesktopNavbar />
      <div style={{ marginTop: "7%" }}>
        <CustomCarousel />
        <div style={{ paddingInline: 71, marginTop: 62, overflowX: "hidden" }}>
          <SampleBlogs />
          <CategorySection />
          <PopularBlogsSection title={"လတ်တလော ရေပန်းစားမှုများ"} href={"/blogs"} />
          <DesktopAdSection />
          <RecommandBlogs title={"အကျိုးပြု ဆောင်းပါးများ"} href={"/blogs"} />
        </div>
      </div>
      <DesktopFooter />
    </div>
  );
};

export default DesktopView;
