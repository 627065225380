import { useMobileDimensions } from "@/hooks";
import Button from 'antd/es/button';
import React, { useState } from "react";
import styled from "styled-components";
import ViewAllButton from "../ViewAllButton";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Text } from "@/styles/reactnative";
import { Flex } from "antd";

const Container = styled.div<{ $isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => (props?.$isMobile ? "32px" : "64px")};
`;

const BlogLayoutTitle = styled(Text)<{ $isMobile: boolean }>`
  font-size: ${props => (props?.$isMobile ? "12px" : "32px")};
  font-weight: 500;
  color: #18181b;
`;

const BlogLayoutHeader = ({ title, isViewAll = true, isPaginate = false, href = "" }) => {
  const { isMobile } = useMobileDimensions();
  // const isMobile = false
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(4);

  const increase = () => {
    setOffset(offset + 4);
    setLimit(limit + 4);
  };

  const reduce = () => {
    if (offset > 0) {
      setOffset(offset - 4);
      setLimit(limit - 4);
    } else {
      setOffset(0);
      setLimit(4);
    }
  };

  return (
    <Container $isMobile={isMobile}>
      <BlogLayoutTitle $isMobile={isMobile}>{title}</BlogLayoutTitle>
      {isViewAll && <ViewAllButton label={"အားလုံးကို ကြည့်ရှူမည်"} href={href} />}
      {isPaginate && (
        <Flex gap={isMobile ? 10 : 24}>
          <Button
            type="primary"
            style={{
              backgroundColor: "#ECECEC",
              width: isMobile ? 24 : 32,
              height: isMobile ? 24 : 32,
            }}
            icon={
              <LeftOutlined
                style={{
                  color: offset === 0 ? "#96999E" : "#212122",
                  fontSize: isMobile ? 10 : 18,
                }}
              />
            }
            onClick={reduce}
          />
          <Button
            type="primary"
            style={{
              backgroundColor: "#ECECEC",
              width: isMobile ? 24 : 32,
              height: isMobile ? 24 : 32,
            }}
            icon={<RightOutlined style={{ color: "#212122", fontSize: isMobile ? 10 : 18 }} />}
            onClick={increase}
          />
        </Flex>
      )}
    </Container>
  );
};

export default BlogLayoutHeader;
