import { Text } from "@/styles/reactnative";
import { Typography } from "antd";
import styled from "styled-components";

export const StyledTitle = styled(Text)<{ $isMobile: boolean }>`
  color: #000;
  font-size: ${props => (props?.$isMobile ? "12px" : "24px")};
  font-style: normal;
  font-weight: 500;
  line-height: ${props => (props?.$isMobile ? "19.2px" : "32px")}; /* 133.333% */
  max-width: ${props => (props?.$isMobile ? "150px" : "auto")};
`;
