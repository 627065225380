import { CFloatingButton } from "@/app/common";
import { DesktopFooter } from "@/app/components/Desktop";
import { MobileNavbar } from "@/app/components/Mobile";
import { BlogsByCategory, ContactUsLayout } from "@/app/layouts/Desktop";
import { MobileSearchContainer } from "@/app/layouts/Mobile";
import { SvgFloatButton } from "@/assets/svg";
import React, { useState } from "react";

const MobileView = ({ category }) => {
  const [showContactUs, setShowContactUs] = useState(false);
  const [showSearchContainer, setShowSearchContainer] = useState(false);
  return (
    <main>
      <MobileSearchContainer
        showSearchContainer={showSearchContainer}
        setShowSearchContainer={setShowSearchContainer}
      />
      {!showSearchContainer && <MobileNavbar setShowSearchContainer={setShowSearchContainer} />}
      <div style={{ marginTop: "20%", paddingInline: 16 }}>
        <BlogsByCategory category={category} />
      </div>
      <DesktopFooter />
      <CFloatingButton onClick={() => setShowContactUs(true)} icon={<SvgFloatButton />} />
      <ContactUsLayout open={showContactUs} setOpen={setShowContactUs} />
    </main>
  );
};

export default MobileView;
