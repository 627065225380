import { Text } from "@/styles/reactnative";
import styled from "styled-components";

export const Container = styled.div`
  /* min-width: 164px; */
  max-width: 180px;
  min-width: 120px;
  min-height: 180px;
  background-color: #f1f2f7;
  padding: 8px 8px 48px 8px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  text-align: center;
  cursor: pointer;
`;

// Image styles for category card
export const CategoryImage = styled.img`
  width: 100%;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
`;

// Category name text
export const CategoryName = styled(Text)`
  font-size: 12px;
  font-weight: 500;
  margin-top: 8px;
  text-transform: capitalize;
  font-feature-settings: "liga" off, "clig" off;
  text-align: center;
  color: #000;
`;