import { useMobileDimensions } from "@/hooks";
import { Form, Input } from "antd";
import React from "react";
import styled from "styled-components";

const StyledInput = styled(Input)<{ $isMobile: boolean }>`
  height: ${props => (props?.$isMobile ? "48px" : "64px")};
  padding-block: ${props => (props?.$isMobile ? "10px" : "18px")};
  padding-inline: ${props => (props?.$isMobile ? "16px" : "24px")};
  font-size: ${props => (props?.$isMobile ? "12px" : "20px")};
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`;

const ServcieAddress = () => {
  const { isMobile } = useMobileDimensions();
  return (
    <Form.Item
      label="နေထိုင်ရာ မြို့အမည်"
      name="city"
      style={{ width: "100%" }}
      rules={[{ required: true, message: "*နေထိုင်ရာ မြို့အမည် ကိုထည့်ရန် လိုအပ်ပါသည်" }]}
      required>
      <StyledInput
        $isMobile={isMobile}
        placeholder="နေထိုင်ရာမြို့အမည်အားဖြည့်စွက်ပါ"
        name="city"
      />
    </Form.Item>
  );
};

export default ServcieAddress;
