import { Text } from "@/styles/reactnative";
import { FONTS } from "@/styles/styles";
import { Flex } from "antd";
import styled from "styled-components";

export const Container = styled(Flex)<{ $isOpen: boolean }>`
  background-color: #f1f2f7;
  border-radius: 12px;
  padding-inline: 16px;
  border-bottom-left-radius: ${props => (props?.$isOpen ? "0px" : "12px")};
  border-bottom-right-radius: ${props => (props?.$isOpen ? "0px" : "12px")};
`;

export const SearchItemsContainer = styled.div`
  width: 100%;
  min-height: 170px;
  background-color: #f1f2f7;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 26px 16px;
  z-index: 999;
  cursor: pointer;
`;

export const StyledSearchedText = styled(Text)`
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.24px;
`;

export const SearchInput = styled.input`
  font-size: ${FONTS.h7};
  font-weight: 400;
  letter-spacing: 0.24px;
  line-height: 16px;
  flex: 1;
  height: 100%;
  padding-block: 15px;
  background-color: transparent;
  border: 0px;
  outline: none;
  color: #000;
`;
