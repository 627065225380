import { useMobileDimensions } from "@/hooks";
import { setPathToIndex, setServiceState } from "@/stores/Slices/serviceSlice";
import { Text } from "@/styles/reactnative";
import { MinusOutlined, RightOutlined } from "@ant-design/icons";
import { Flex } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Title = styled(Text)<{ $isMobile: boolean }>`
  font-size: ${props => (props?.$isMobile ? "12px" : "24px")};
  font-weight: 500;
  line-height: ${props => (props?.$isMobile ? "16px" : "32px")};
  color: #18181b;
`;

interface ServiceHeaderProps {
  maintitle: string;
  subtitles: string[];
  chevIcon?: boolean | false;
}

const ServiceHeader = ({ maintitle, subtitles, chevIcon }: ServiceHeaderProps) => {
  const { isMobile } = useMobileDimensions();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <Flex wrap={"wrap"} align="center" gap={8} style={{ marginBottom: isMobile ? 20 : 64 }}>
      <Title
        $isMobile={isMobile}
        onClick={() => {
          navigate("/services");
        }}>
        {maintitle}
      </Title>
      {subtitles.map((subtitle: any, index) => (
        <Flex
          key={index}
          gap={8}
          align="center"
          onClick={() => {
            navigate(
              `/services?parentId=${subtitle?.parentId}&title=${subtitle?.title}&description=${subtitle?.description}`
            );
            dispatch(setPathToIndex(index));
          }}>
          {chevIcon ? (
            <RightOutlined
              style={{ fontSize: isMobile ? 10 : 18, color: "#18181B", marginTop: 6 }}
            />
          ) : (
            <MinusOutlined
              style={{ fontSize: isMobile ? 16 : 18, color: "#18181B", marginTop: 6 }}
            />
          )}
          <Text
            style={{
              color: "#4D4D4D",
              fontSize: isMobile ? 10 : 16,
              fontWeight: "500",
              lineHeight: isMobile ? "16px" : "32px",
              maxWidth: isMobile ? "100%" : 560,
            }}>
            {subtitle?.title}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};

export default ServiceHeader;
