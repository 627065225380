import { CText } from "@/app/common";
import { Flex } from "antd";
import React from "react";
import { ImQuotesLeft } from "react-icons/im";
import { ImQuotesRight } from "react-icons/im";

const BlogDetailSectionContentQuote = ({ item }) => {
  return (
    <>
      <Flex
        gap={5}
        style={{
          backgroundColor: "#e7e8e9",
          padding: 5,
          borderRadius: 6,
          paddingInline: 16,
        }}>
        <Flex>
          <ImQuotesLeft />
        </Flex>
        <CText style={{fontStyle: 'italic'}}>{item?.text}</CText>
        <Flex>
          <ImQuotesRight />
        </Flex>
      </Flex>
    </>
  );
};

export default BlogDetailSectionContentQuote;
