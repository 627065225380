import { Modal } from "antd";
import React from "react";

const CModal = ({
  open,
  title,
  onOk,
  onCancel,
  children,
  centered = true,
  width = 520,
  headerStyle,
  bodyStyle,
  footerStyle,
  footer,
}: {
  open: boolean;
  title?: string;
  onOk?: () => void;
  onCancel?: () => void;
  children: React.ReactNode;
  centered?: boolean;
  width?: number | string;
  height?: number | string;
  footer?: any;
  headerStyle?: any;
  bodyStyle?: any;
  footerStyle?: any;
}) => {
  return (
    <Modal
      open={open}
      title={title}
      onOk={onOk}
      onCancel={onCancel}
      centered={centered}
      width={width}
      styles={{
        body: {
          maxHeight: "80vh",
          overflowY: "auto",
          position: "relative",
          scrollbarWidth: 'none',
          ...bodyStyle,
        },
        header: {
          ...headerStyle,
        },
      }}
      footer={footer || null}>
      {children}
    </Modal>
  );
};

export default CModal;
