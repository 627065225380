import React, { useEffect, useState } from "react";
import {
  Container,
  SearchBarContainer,
  SearchItemsContainer,
  StyledInput,
  StyledSearchedText,
} from "./styles";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { Flex } from "antd";

const MobileSearchContainer = ({ showSearchContainer, setShowSearchContainer }) => {
  const [showItemsContainer, setShowItemsContainer] = useState(false);
  const [searchData, setSearchData] = useState("");

  useEffect(() => {
    searchData?.length > 0 ? setShowItemsContainer(true) : setShowItemsContainer(false);
  }, [searchData]);

  return (
    <Container $show={showSearchContainer}>
      <SearchBarContainer $show={showItemsContainer}>
        {!showItemsContainer && <SearchOutlined style={{ color: "#000", fontSize: 18 }} />}

        <StyledInput
          value={searchData}
          onChange={e => setSearchData(e.target.value)}
          placeholder="သိလိုသည်များကို ရှာဖွေမည်"
          variant="borderless"
        />
        <CloseOutlined
          style={{ color: "#000", fontSize: 18 }}
          onClick={() => {
            setSearchData("");
            setShowItemsContainer(false);
            setShowSearchContainer(false);
          }}
        />
      </SearchBarContainer>
      {showItemsContainer && (
        <SearchItemsContainer>
          {["Visa", "Visa Extension", "Visa Price", "Visa Types", "Visa Packages"].map(
            (item, index) => (
              <Flex align="center" gap={12} key={index}>
                <SearchOutlined style={{color: '#000', fontSize: 18}} />
                <StyledSearchedText>{item}</StyledSearchedText>
              </Flex>
            )
          )}
        </SearchItemsContainer>
      )}
    </Container>
  );
};

export default MobileSearchContainer;
