import React from "react";

function SvgFilterIcon({fillColor}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g>
        <path
          fill={fillColor}
          d="M8.75 7.75v5.75a.75.75 0 11-1.5 0V7.75a.75.75 0 011.5 0zM12.5 12a.75.75 0 00-.75.75v.75a.75.75 0 101.5 0v-.75a.75.75 0 00-.75-.75zM14 9.5h-.75v-7a.75.75 0 10-1.5 0v7H11a.75.75 0 100 1.5h3a.75.75 0 100-1.5zM3.5 10a.75.75 0 00-.75.75v2.75a.75.75 0 101.5 0v-2.75A.75.75 0 003.5 10zM5 7.5h-.75v-5a.75.75 0 00-1.5 0v5H2A.75.75 0 002 9h3a.75.75 0 000-1.5zm4.5-3h-.75v-2a.75.75 0 00-1.5 0v2H6.5a.75.75 0 000 1.5h3a.75.75 0 100-1.5z"
        ></path>
      </g>
    </svg>
  );
}

export default SvgFilterIcon;
