export default [
  "Computing Devices",
  "Audio Devices",
  "Gaming Devices",
  "Wearable Technology",
  "Smartphones and Accessories",
  "Computer & Laptop",
  "Cameras and PHotography",
  "Computing Device",
  "Audio Device",
  "Gaming Device",
];
