import { CustomCarousel, DesktopAdSection, DesktopFooter } from "@/app/components/Desktop";
import { MobileNavbar } from "@/app/components/Mobile";
import { ServiceSection } from "@/app/layouts/Desktop";
import { MobileSearchContainer } from "@/app/layouts/Mobile";
import React, { useState } from "react";

const MobileView = () => {
  const [showSearchContainer, setShowSearchContainer] = useState(false);
  return (
    <main style={{overflowX: 'hidden'}}>
      <MobileSearchContainer
        showSearchContainer={showSearchContainer}
        setShowSearchContainer={setShowSearchContainer}
      />
      <div style={{ marginTop: 90 }}>
        <CustomCarousel />
        <div style={{ paddingInline: 16, marginTop: 40 }}>
          {!showSearchContainer && <MobileNavbar setShowSearchContainer={setShowSearchContainer} />}
          <ServiceSection
            title={"လူကြိုက်များသော ဝန်ဆောင်မှုများ"}
            isViewAll={false}
            isPaginate={true}
            featured={2}
          />
          <DesktopAdSection />
          <ServiceSection title={"ဝန်ဆောင်မှု အားလုံး"} isViewAll={false} showAllServices={true} />
        </div>
      </div>
      <DesktopFooter />
    </main>
  );
};

export default MobileView;
