import React, { useState } from "react";
import DesktopView from "./DesktopView";
import { useMobileDimensions, useScrollToTop } from "@/hooks";
import MobileView from "./MobileView";
import { CFloatingButton } from "@/app/common";
import { ContactUsLayout } from "@/app/layouts/Desktop";
import { SvgFloatButton } from "@/assets/svg";
import { useApiQuery } from "@/server/api-fetch";
import { GET_PRODUCT } from "@/server/apis";

const ProductDetailPage = () => {
  const { isMobile } = useMobileDimensions();
  const [showContactUs, setShowContactUs] = useState(false);
  useScrollToTop();
  const queryParams = new URLSearchParams(window.location.search);
  const productId = queryParams.get("id");
  const { data } = useApiQuery({
    url: `${GET_PRODUCT}?productId=${productId || ""}`,
  });

  return (
    <div>
      {isMobile ? <MobileView data={data} /> : <DesktopView data={data} />}
      <CFloatingButton onClick={() => setShowContactUs(true)} icon={<SvgFloatButton />} />
      <ContactUsLayout open={showContactUs} setOpen={setShowContactUs} />
    </div>
  );
};

export default ProductDetailPage;
