import React from "react";
import { BlogLayoutContainer } from "./styles";
import { useMobileDimensions } from "@/hooks";
import { BlogsList } from "@/app/features";

const DesktopBlogLayout = ({ data, noLoadMore = false, offset, isLoading, setOffset }: any) => {
  const { isMobile } = useMobileDimensions();

  return (
    <BlogLayoutContainer $isMobile={isMobile}>
      <BlogsList
        noLoadMore={noLoadMore}
        offset={offset}
        setOffset={setOffset}
        isLoading={isLoading}
        data={data}
      />
    </BlogLayoutContainer>
  );
};

export default DesktopBlogLayout;
