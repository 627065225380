import { BlogDetailSectionContentItem } from '@/app/entities'
import { nanoid } from '@reduxjs/toolkit'
import { List } from 'antd'
import React from 'react'

const BlogDetailsSectionContentList = ({item}) => {
  return (
    <List
      dataSource={item?.content}
      // bordered
      itemLayout="vertical"
      split={false}
      renderItem={(item: any) => <BlogDetailSectionContentItem item={item} key={nanoid()} />}
    />
  )
}

export default BlogDetailsSectionContentList
