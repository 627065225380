import { Button, Radio, Typography } from "antd";
import styled from "styled-components";

export const Container = styled.div<{ $isDesktop: boolean }>`
  width: ${props => (props?.$isDesktop ? "296px" : "624px")};
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const FilterTypeTitle = styled(Typography.Text)`
  font-size: 16px;
  font-weight: 500;
  color: #000;
  line-height: 24px;
`;

export const StyledButton = styled(Button)`
  color: "#485AFF";
  font-size: 16px;
  font-weight: 500;
  width: fit-content;
  display: flex;
  margin-block: 16px;
  margin-top: 60px;
`;
