import { ServiceIcon } from "@/assets/svg";
import { useMobileDimensions } from "@/hooks";
import { Text } from "@/styles/reactnative";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Flex, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CollapsibleContent, Container } from "./styles";
import { useDispatch } from "react-redux";
import { setServiceState } from "@/stores/Slices/serviceSlice";

const ServiceTypeCard = ({ item, style = {} }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isMobile } = useMobileDimensions();
  const [open, setOpen] = useState(false);

  const [contentHeight, setContentHeight] = useState(0);
  const paragraphRef = useRef<any>(null);

  useEffect(() => {
    if (paragraphRef.current) {
      setContentHeight(paragraphRef.current.scrollHeight);
    }
  }, [item.description, isMobile]);

  return (
    <Container $isMobile={isMobile} style={style}>
      <Flex align="center" justify="space-between">
        <Flex gap={8}>
          <ServiceIcon />
          <Text style={{ fontSize: isMobile ? 10 : 16, fontWeight: "600", color: "#485AFF" }}>
            ဝန်ဆောင်မှု
          </Text>
        </Flex>
        <Flex
          vertical
          align="center"
          justify="center"
          style={{ cursor: "pointer" }}
          gap={3}
          onClick={() => setOpen(!open)}>
          <UpOutlined
            style={{
              fontSize: 10,
              transform: `rotate(${open ? 180 : 0}deg)`,
              transition: "transform 0.3s ease",
              color: "#414141",
            }}
          />
          <DownOutlined
            style={{
              fontSize: 10,
              transform: `rotate(${open ? -180 : 0}deg)`,
              transition: "transform 0.3s ease",
              color: "#414141",
            }}
          />
        </Flex>
      </Flex>
      <Flex vertical gap={16}>
        <Text style={{ fontSize: isMobile ? 16 : 20, fontWeight: "500", color: "#000" }}>
          {item?.title}
        </Text>
        <CollapsibleContent $open={open} $contentHeight={contentHeight}>
          <Typography.Paragraph
            ref={paragraphRef}
            ellipsis={!open ? { rows: 2 } : false}
            style={{
              fontSize: isMobile ? 10 : 16,
              fontWeight: "400",
              color: "#4D4D4D",
              paddingBlock: 2,
            }}>
            {item?.description}
          </Typography.Paragraph>
        </CollapsibleContent>
      </Flex>
      <a
        onClick={() => {
          navigate(
            `/services?parentId=${item?._id}&title=${item?.title}&description=${item?.description}`
          );
          dispatch(
            setServiceState({
              parentId: item?._id,
              title: item?.title,
              description: item?.description,
            })
          );
        }}
        style={{
          marginTop: 4,
          fontSize: isMobile ? 12 : 16,
          fontWeight: "600",
          textAlign: "right",
          color: "#485AFF",
        }}>
        ဆက်လက်လုပ်ဆောင်ရန်
      </a>
    </Container>
  );
};

export default ServiceTypeCard;
