import styled from "styled-components";

export const ChatContainer = styled.div<{ $isMobile: boolean, $isFocus: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${props => (props?.$isMobile ? "93%" : "88%")};
  padding-bottom: ${({ $isFocus, $isMobile }) => ($isFocus && $isMobile ? "70px" : "0")};
`;

export const MessageArea = styled.div`
  overflow-y: auto;
  margin-bottom: 16px;
  padding-right: 12px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  height: 100%;
  background-color: #fff;
  padding-inline: 24px;
  /* flex: 1;
  overflow-y: auto; */
`;

export const InputArea = styled.div`
  display: flex;
  height: 80px;
  align-items: center;
  background-color: #f5f5f6;
  padding-inline: 24px;
`;

export const StyledInput = styled.textarea`
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  flex: 1;
  height: 80%;
  padding: 20px 24px;
  background-color: transparent;
  border: none;
  outline: none;
  color: #000;
  resize: none;
  overflow-y: auto;
  max-height: calc(25.6px * 3 + 40px); /* Max height for 3 rows */
  min-height: calc(25.6px + 40px); /* Min height for 1 row */
`;

export const TimelineLabel = styled.div`
  text-align: center;
  margin: 16px 0;
  color: #888;
  font-weight: bold;
`;
