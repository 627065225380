import React, { useState } from "react";
import { Container, StyledButton, StyledInput } from "./styles";
import { COLORS } from "@/styles/styles";
import { useMobileDimensions } from "@/hooks";
import { CParagraph } from "@/app/common";
import { Text } from "@/styles/reactnative";
import { Flex } from "antd";

const CustomerIdForm = ({ setOpenForm, setOpen }) => {
  const { isMobile } = useMobileDimensions();
  const [isDisable, setIsDisable] = useState(true);
  const [fillBtnTxt, setFillBtnTxt] = useState("ဝင်ရောက်မည်");
  const [submitBtnTxt, setSubmitBtnTxt] = useState("Customer ID ရယူမည်");
  const [placeholer, setPlaceholer] = useState("XXXXXXXX");
  const [id, setId] = useState("");

  const handleCustomerIdBtn = () => {
    if (submitBtnTxt == "Customer ID ရယူမည်") {
      setIsDisable(false);
      setFillBtnTxt("Customer ID ရယူမည်");
      setSubmitBtnTxt("Customer ID ဖြည့်စွက်မည်");
      setPlaceholer("ဤနေရာတွင် ရရှိမည်ဖြစ်သည်");
    } else {
      setIsDisable(false);
      setFillBtnTxt("ဝင်ရောက်မည်");
      setSubmitBtnTxt("Customer ID ရယူမည်");
    }
  };

  const handleFillIdBtn = () => {
    if (!isDisable && fillBtnTxt === "ဝင်ရောက်မည်") {
      setOpenForm(false);
    } else {
      setIsDisable(true);
      setId("123456A");
      setFillBtnTxt("ပြန်လည်ထုတ်ရန်  ခေတ္တစောင့်ပါ");
    }
  };

  return (
    <Container $isMobile={isMobile}>
      <Flex gap={24} vertical>
        <Text
          style={{
            fontSize: isMobile ? 16 : 20,
            color: "#000",
            fontWeight: "500",
            lineHeight: "32px",
          }}>
          Customer ID <Text style={{ color: COLORS.red, fontSize: 20 }}>*</Text>
        </Text>
        <Flex
          align="center"
          style={{ width: "100%", height: isMobile ? "48px" : "64px", border: '1px solid #a6a5b3', borderRadius: 12 }}>
          <StyledInput $isMobile={isMobile} value={id} disabled placeholder={placeholer} />
          <StyledButton
            $isMobile={isMobile}
            style={{
              fontSize: isMobile ? 12 : 20,
              color: "#fff",
              backgroundColor: isDisable ? "#C5CBFF" : "#485aff",
            }}
            disabled={isDisable}
            type="primary"
            onClick={() => handleFillIdBtn()}>
            {fillBtnTxt}
          </StyledButton>
        </Flex>
        <CParagraph
          style={{
            fontSize: isMobile ? 12 : 16,
            lineHeight: isMobile ? "28px" : "48px",
            color: "#000",
            fontWeight: "400",
          }}>
          အမှားအယွင်း သို့မဟုတ် အခက်အခဲ ဖြစ်ပေါ်နေပါသလား။ အောက်ပါ ဖုန်းနံပါတ်များကို ဆက်သွယ်ပြီး
          အကူအညီရယူနိုင်ပါသည်။ <br /> {isMobile && <br />} 010-8745-1092
        </CParagraph>
      </Flex>
      <Flex align="center" gap={24}>
        <StyledButton
          $isMobile={isMobile}
          style={{
            fontSize: isMobile ? 12 : 20,
            color: "#000",
            flex: 1,
            height: isMobile ? "48px" : "72px",
            backgroundColor: "#F0F1FC",
          }}
          type="primary"
          onClick={() => setOpen(false)}>
          မလုပ်တော့ပါ
        </StyledButton>
        <StyledButton
          $isMobile={isMobile}
          style={{
            fontSize: isMobile ? 12 : 20,
            color: "#fff",
            flex: 1,
            height: isMobile ? "48px" : "72px",
            backgroundColor: "#485aff",
          }}
          type="primary"
          onClick={handleCustomerIdBtn}>
          {submitBtnTxt}
        </StyledButton>
      </Flex>
    </Container>
  );
};

export default CustomerIdForm;
