import { CloseOutlined } from "@ant-design/icons";
import { Button, Flex, Typography } from "antd";
import React from "react";

const FilterList = ({ data, onClear, onClearSingle }) => {
  return (
    <Flex gap={32} justify="space-between">
      <Typography.Text
        style={{
          color: "#000",
          fontSize: 20,
          fontWeight: 400,
          lineHeight: "32px",
        }}>
        စီစစ်မှုများ -
      </Typography.Text>
      <Flex gap={16} wrap style={{ flex: 1 }}>
        {data?.map((item, index) => (
          <Flex
            align="center"
            gap={8}
            key={index}
            style={{
              height: 32,
              paddingInline: 12,
              border: "1px solid #4D4D4D",
              borderRadius: 16,
            }}>
            <Typography.Text>{item}</Typography.Text>
            <CloseOutlined
              onClick={() => onClearSingle(item)}
              style={{ fontSize: 14, color: "#000" }}
            />
          </Flex>
        ))}
      </Flex>
      <Button
        onClick={() => onClear && onClear()}
        type="text"
        style={{ color: "#485AFF", fontSize: 16, fontWeight: "500", lineHeight: "32px" }}>
        ဖယ်ရှားမည်
      </Button>
    </Flex>
  );
};

export default FilterList;
