import { CModal } from "@/app/common";
import { useMobileDimensions } from "@/hooks";
import { Flex, Form, Typography } from "antd";
import React, { useState } from "react";
import { StyledButton, StyledInput } from "./styles";
import { useNavigate } from "react-router-dom";

const ServiceFormModal = ({ visible, setVisible }) => {
  const { isMobile } = useMobileDimensions();
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [form] = Form.useForm();

  const onFinish = async values => {
    console.log("Data ready to submit = ", values);
    form.resetFields();
    setVisible(false);
    navigate(`/services?categoryId=1&serviceName=${values.serviceName}`);
  };

  const onFinishFailed = errorInfo => {
    console.log("Failed: ", errorInfo);
  };

  return (
    <CModal
      open={visible}
      onCancel={() => setVisible(false)}
      width={isMobile ? "100%" : "70%"}
      bodyStyle={{
        maxHeight: isMobile ? "100vh" : "80vh",
      }}
      footer={null}>
      <div style={{ padding: isMobile ? "20px 16px" : "40px 32px" }}>
        <Typography.Text
          style={{
            color: "#000",
            fontSize: isMobile ? 16 : 32,
            fontWeight: 500,
            lineHeight: isMobile ? "25.6px" : "51.2px",
          }}>
          ကိုယ်တိုင် ဝန်ဆောင်မှုတစ်ခုအား ဖြည့်သွင်းရန်ပုံစံ
        </Typography.Text>
        <Typography.Paragraph
          style={{
            color: "#000",
            fontSize: isMobile ? 12 : 20,
            fontWeight: 400,
            lineHeight: isMobile ? "24px" : "40px",
            marginTop: isMobile ? 24 : 64,
            marginBottom: isMobile ? 64 : 100,
          }}>
          ကျွန်ုပ်တို့ ဝန်ဆောင်မှုစာရင်းတွင် မပါဝင်သည့် ဝန်ဆောင်မှုအတွက် အမည်ထည့်သွင်းနိုင်ပါသည်။
          ကျေးဇူးပြု၍ မိတ်ဆွေထည့်သွင်းသော ဝန်ဆောင်မှုအမည်သည် မှန်ကန်စင်စစ်ဖြစ်ကြောင်းနှင့်
          မသင့်လျော်သောနာမည်များ မဖြစ်ကြောင်း သေချာစွာ စစ်ဆေးပြီးမှ ထည့်သွင်းပေးပါ။
        </Typography.Paragraph>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off">
          <Form.Item
            label="ဝန်ဆောင်မှုအမည်"
            name={"serviceName"}
            style={{ width: "100%" }}
            rules={[{ required: true, message: "*ဝန်ဆောင်မှုအမည်် ကိုထည့်ရန် လိုအပ်ပါသည်" }]}
            required>
            <StyledInput
              $isMobile={isMobile}
              placeholder="ဝန်ဆောင်မှုအမည်ကို ထည့်သွင်းပါ"
              name="serviceName"
              maxLength={100}
              onChange={e => setValue(e.target.value)}
            />
          </Form.Item>
          <Flex justify="flex-end">
            <Typography.Text
              style={{
                color: "#A1A2AA",
                fontSize: isMobile ? 10 : 20,
                fontWeight: "400",
                lineHeight: "28px",
              }}>
              {value.length}/100
            </Typography.Text>
          </Flex>
          <Flex align="center" justify="space-between" gap={32} style={{ marginTop: 100 }}>
            <Form.Item style={{ flex: 1 }}>
              <StyledButton
                $isMobile={isMobile}
                htmlType="reset"
                onClick={() => setVisible(false)}
                style={{ backgroundColor: "#F0F1FC", color: "#212121" }}>
                မလုပ်တော့ပါ
              </StyledButton>
            </Form.Item>
            <Form.Item style={{ flex: 1 }}>
              <StyledButton
                $isMobile={isMobile}
                htmlType="submit"
                style={{ backgroundColor: "#485AFF", color: "#FFFFFF" }}>
                ဖြည့်သွင်းမည်
              </StyledButton>
            </Form.Item>
          </Flex>
        </Form>
      </div>
    </CModal>
  );
};

export default ServiceFormModal;
