import { CustomHeader } from "@/app/components/Desktop";
import { Flex } from "antd";
import React from "react";
import { useMobileDimensions } from "@/hooks";
import ProductImages from "./ProductImages";
import Details from "./Details";

const ProudctDetail = ({ data }) => {
  const { isMobile } = useMobileDimensions();

  return (
    <Flex vertical>
      <CustomHeader type={"product"} maintitle={data?.name} subtitles={[data?.category]} chevIcon />
      <Flex gap={isMobile ? 32 : 24} vertical={isMobile ? true : false}>
        <ProductImages images={data?.images} soldOut={data?.status === "sold"} />
        <Details data={data} />
      </Flex>
    </Flex>
  );
};

export default ProudctDetail;
