import { images } from "@/assets/images";

export default [
  {
    _id: "1",
    title: "တောင်ကိုရီးယား ယဉ်ကျေးမှု အကြောင်း သိကောင်းစရာများ",
    description:
      "တောင်ကိုရီးယားသည် ထူးခြားသော ယဉ်ကျေးမှုရှိသည့် နိုင်ငံတစ်ခုဖြစ်သည်။ ဤနေရာတွင် ထူးခြားသော ဓလေ့ထုံစံများ၊ အနုပညာ၊ စာပေများကို တွေ့ရှိနိုင်ပါသည်။ တောင်ကိုရီးယားသည် ထူးခြားသော ယဉ်ကျေးမှုရှိသည့် နိုင်ငံတစ်ခုဖြစ်သည်။ ဤနေရာတွင် ထူးခြားသော ဓလေ့ထုံစံများ၊ အနုပညာ၊ စာပေများကို တွေ့ရှိနိုင်ပါသည်။ တောင်ကိုရီးယားသည် ထူးခြားသော ယဉ်ကျေးမှုရှိသည့် နိုင်ငံတစ်ခုဖြစ်သည်။ ဤနေရာတွင် ထူးခြားသော ဓလေ့ထုံစံများ၊ အနုပညာ၊ စာပေများကို တွေ့ရှိနိုင်ပါသည်။ တောင်ကိုရီးယားသည် ထူးခြားသော ယဉ်ကျေးမှုရှိသည့် နိုင်ငံတစ်ခုဖြစ်သည်။ ဤနေရာတွင် ထူးခြားသော ဓလေ့ထုံစံများ၊ အနုပညာ၊ စာပေများကို တွေ့ရှိနိုင်ပါသည်။",
    category: "ယဉ်ကျေးမှု",
    createdAt: "September 1, 2024",
    timeTaken: "ဖတ်ရန်ကြာချိန် ၅ မိနစ်",
    img: images.dummy_blog_1,
  },
  {
    _id: "2",
    title: "တောင်ကိုရီးယားနိုင်ငံရှိ ရာသီပွဲတော်များ အကြောင်း",
    description:
      "တောင်ကိုရီးယားတွင် လူသိများသည့် ကာနီဗယ်ပွဲတော်များမှာ ပြည်တွင်းလူမျိုးများနှင့် နိုင်ငံခြားသားဧည့်သည်များအတွက် အံ့သြစရာအတွေ့အကြုံတစ်ခုဖြစ်ပါသည်။ ကာနီဗယ်များသည် ဒေသဖျော်ဖြေပွဲများ၊ အစားအစာပွဲများနှင့် ယဉ်ကျေးမှုယဉ်ကျေးမှုအကအနွေးများနှင့်အတူ ကျင်းပသည်။",
    category: "ကိုရီးယားအသိုက်အဝန်း",
    createdAt: "September 1, 2024",
    timeTaken: "ဖတ်ရန်ကြာချိန် ၅ မိနစ်",
    img: images.dummy_blog_5,
  },
  {
    _id: "3",
    title: "တောင်ကိုရီးယားနိုင်ငံသုံး ဘဏ်ငွေစက္ကူများ",
    description:
      "တောင်ကိုရီးယားတွင် အသုံးပြုသည့် ငွေကြေးသည် 'ဝမ်' ဟုခေါ်သည်။ တစ်ဝမ်သည် ငွေကြေးစနစ်တွင် အရေးကြီးသော အခန်းကဏ္ဍတစ်ခုဖြစ်သည်။ နိုင်ငံတော်၏ စီးပွားရေးနှင့် ရောင်းဝယ်မှုလုပ်ငန်းများအတွက် ငွေကြေးနှုန်းသည် အဓိကအကြောင်းအရာတစ်ခုဖြစ်သည်။",
    category: "သတင်းအချက်အလက်",
    createdAt: "September 1, 2024",
    timeTaken: "ဖတ်ရန်ကြာချိန် ၅ မိနစ်",
    img: images.dummy_blog_2,
  },
  {
    _id: "4",
    title: "ကိုရီးယားရိုးရာဝတ်စုံများ၏ အဓိပ္ပာယ်နှင့် အလှ",
    description:
      "ကိုရီးယားရိုးရာဝတ်စုံများကို 'ဟန်ဘော့' ဟုခေါ်ကြပြီး ယဉ်ကျေးမှုပုံစံတစ်ခုအဖြစ် ကျန်ရှိနေသည်။ ဟန်ဘော့ဝတ်ဆင်ခြင်းသည် ပွဲတော်များနှင့် ယဉ်ကျေးမှုအခမ်းအနားများတွင် အထူးအလေးထားခံရပြီး ကိုရီးယားလူမျိုးတို့၏ လှပသော ယဉ်ကျေးမှုအရောင်စုံများကို ပြသသည်။",
    category: "ယဉ်ကျေးမှု",
    createdAt: "September 1, 2024",
    timeTaken: "ဖတ်ရန်ကြာချိန် ၅ မိနစ်",
    img: images.dummy_blog_8,
  },
  {
    _id: "5",
    title: "တောင်ကိုရီးယား၏ ထူးခြားသော ယဉ်ကျေးမှုဓလေ့များ",
    description:
      "တောင်ကိုရီးယားယဉ်ကျေးမှုသည် အိမ်ထောင်ရေးသိက္ခာ၊ ပုဂ္ဂိုလ်ရေးပုဂ္ဂိုလ်တစ်ဦးချင်းစီအကြောင်း၊ အဖွဲ့အစည်းနှင့် လူမှုရေးဂုဏ်သိက္ခာများကို အထူးအာရုံစိုက်ခြင်းဖြင့် ထူးခြားသည်။ ယဉ်ကျေးမှုဓလေ့များသည် စားသောက်ပွဲများ၊ ပွဲရုံများနှင့် အပြန်အလှန်လေးစားမှုစနစ်များကို အခြေခံသည်။",
    category: "ယဉ်ကျေးမှု",
    createdAt: "September 1, 2024",
    timeTaken: "ဖတ်ရန်ကြာချိန် ၅ မိနစ်",
    img: images.dummy_blog_7,
  },
  {
    _id: "6",
    title: "တောင်ကိုရီးယားဆောင်းဦးရာသီ၏ အံ့မခန်းသဘာဝအလှများ",
    description:
      "ဆောင်းဦးရာသီတွင် တောင်ကိုရီးယား၏ သစ်တောများနှင့် တောရိုးတန်းများသည် ရွှေဝါရောင်သစ်ရွက်များနှင့် အလှဆင်နေသည်။ ဆောင်းဦးကာလသည် တောင်ကိုရီးယားတွင် သဘာဝအလှကို ခံစားရမည့် ကာလအချိန်ဖြစ်ပြီး၊ ခရီးသည်များအတွက် ရှုမဝအောင် ကောင်းမွန်သော အခါကာလတစ်ခုလည်းဖြစ်သည်။",
    category: "ဗဟုသုတ",
    createdAt: "September 1, 2024",
    timeTaken: "ဖတ်ရန်ကြာချိန် ၅ မိနစ်",
    img: images.dummy_blog_6,
  },
  {
    _id: "7",
    title: "ကိုရီးယား၏ အာဟာရဓာတ်ပြည့်ဝသော အစားအစာများ",
    description:
      "ကိုရီးယားအစားအစာများသည် အနောက်တိုင်းလူမှုအသိုင်းအဝိုင်းတွင် နာမည်ကြီးလာပြီး Kimchi, Bibimbap တို့ကဲ့သို့ ထင်ရှားသော အစားအစာများဖြင့် ရောင်းချသည်။ ကိုရီးယားအစားအစာများတွင် ကျန်းမာရေးအတွက် အဓိကထားသည့် အစိတ်အပိုင်းများဖြစ်သော ဟင်းသီးဟင်းရွက်များ၊ ထောပတ်ဆီမပါသောအရသာများကို တွေ့ရနိုင်သည်။",
    category: "အစားအသောက်",
    createdAt: "September 1, 2024",
    timeTaken: "ဖတ်ရန်ကြာချိန် ၅ မိနစ်",
    img: images.dummy_blog_3,
  },
  {
    _id: "8",
    title: "တောင်ကိုရီးယားတွင် အလုပ်လျှောက်ခြင်း",
    description:
      "တောင်ကိုရီးယားတွင် အလုပ်ရှာဖွေရန်အတွက် နိုင်ငံခြားသားများအနေဖြင့် လိုက်နာရမည့် စည်းမျဉ်းများရှိသည်။ အလုပ်အကိုင်ရှာဖွေရေးသည် K-pop နှင့် နည်းပညာလုပ်ငန်းခွင်များတွင် အထူးခွင့်ပြုချက်ရှိသော်လည်း အခြားလုပ်ငန်းများတွင်လည်း ရှာဖွေနိုင်သော အခွင့်အလမ်းများစွာရှိသည်။",
    category: "ကိုရီးယားအလုပ်အကိုင်",
    createdAt: "September 1, 2024",
    timeTaken: "ဖတ်ရန်ကြာချိန် ၅ မိနစ်",
    img: images.dummy_blog_4,
  },
];
