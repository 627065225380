import React, { useState } from "react";
import { ChatContainer, InputArea, MessageArea, StyledInput } from "./styles";
import { PaperClipOutlined, SendOutlined, SmileOutlined } from "@ant-design/icons";
import { MessagesList } from "@/app/features";
import Button from "antd/es/button";
import { useMobileDimensions } from "@/hooks";
import { Flex } from "antd";

const Chat = () => {
  const { isMobile } = useMobileDimensions();
  const [isFocus, setIsFocus] = useState(false);
  return (
    <ChatContainer $isMobile={isMobile} $isFocus={isFocus}>
      <MessageArea id="scrollableDiv">
        <MessagesList />
      </MessageArea>
      <InputArea>
        <PaperClipOutlined style={{ color: "#7C8493", fontSize: 24, cursor: "pointer" }} />
        <StyledInput placeholder="Reply Message" onFocus={() => setIsFocus(true)} />
        <Flex gap={16} align="center">
          <SmileOutlined style={{ color: "#7C8493", fontSize: 24, cursor: "pointer" }} />
          <Button
            type="primary"
            style={{ backgroundColor: "#4640DE", width: 80, height: 40, borderRadius: 32 }}
            icon={<SendOutlined style={{ fontSize: 18 }} />}
          />
        </Flex>
      </InputArea>
    </ChatContainer>
  );
};

export default Chat;
