import { Text, View } from "@/styles/reactnative";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";

const ProductListLoading = () => {
  return (
    <View
      style={{
        width: "60vw",
        height: "50vh",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <Spin indicator={<LoadingOutlined spin />} size="large" />
    </View>
  );
};

export default ProductListLoading;
