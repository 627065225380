import {
  CustomHeader,
  DesktopFooter,
  DesktopNavbar,
  BlogSearchBar,
} from "@/app/components/Desktop";
import { CategoriesList } from "@/app/features";
import { Flex } from "antd";
import React from "react";

const DesktopView = () => {
  return (
    <div
    style={{
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      justifyContent: 'space-between'
    }}>
      <DesktopNavbar />
      <Flex vertical style={{ marginTop: 130, paddingInline: 71, position: "relative" }} gap={40}>
        <CustomHeader maintitle={"Ulsan Cham"} subtitles={["ကဏ္ဍအမျိုးအစားများ"]} />
        <BlogSearchBar
          placeholder={"ကဏ္ဍအမျိုးအစားများကို ရှာဖွေမည်"}
          vStyle={{ position: "absolute", left: "71px", width: "90%", top: "80px" }}
          style={{ fontSize: 16 }}
        />
        <div style={{ marginTop: 40 }}>
          <CategoriesList />
        </div>
      </Flex>
      <DesktopFooter />
    </div>
  );
};

export default DesktopView;
