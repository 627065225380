import { useMobileDimensions } from "@/hooks";
import { Text } from "@/styles/reactnative";
import { RightOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const ViewAllButton = ({ label, href = "" }) => {
  const { isMobile } = useMobileDimensions();
  const navigate = useNavigate()
  return (
    <a
      onClick={() => navigate(href)}
      style={{ display: "flex", gap: "8px", alignItems: "center", textDecoration: "none", cursor: 'pointer' }}>
      <Text style={{ fontSize: isMobile ? "10px" : "16px", fontWeight: "500" }}>
        {label}
      </Text>
      <RightOutlined style={{ fontSize: isMobile ? "10px" : "16px", color: "#000" }} />
    </a>
  );
};

export default ViewAllButton;
