import { Pagination } from "antd";
import styled from "styled-components";

export const ProductsGrid = styled.div<{ $isMoile: boolean }>`
  min-height: 1076px;
  display: grid;
  flex: 1;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${props => (props?.$isMoile ? "135px" : "205px")}, 1fr)
  );
  /* background-color: brown; */
  gap: 16px;
  margin-top: ${props => (props?.$isMoile ? "32px" : "0px")};
  margin-left: ${props => (props?.$isMoile ? "10px" : "0px")};
`;

export const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
  margin-top: 78px;
  border-top: 1px solid #EAECF0;
  padding-top: 12px;

  .ant-pagination-item {
    border-radius: 4px;
    border: 1px solid #d9d9d9;
  }

  .ant-pagination-item-active {
    background-color: #2f54eb;
    border-color: #2f54eb;
  }

  .ant-pagination-item a {
    color: #2f54eb;
  }

  .ant-pagination-item-active a {
    color: white;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    border: none;
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border: none;
    background: transparent;
    color: #2f54eb;
  }
`;
