import { CText } from "@/app/common";
import React from "react";

const BlogDetailSectionContentText = ({ item }) => {
  return (
    <>
      {item?.text && <CText>{item?.text}</CText>}
    </>
  );
};

export default BlogDetailSectionContentText;
