import React from "react";

import { List } from "antd";

import BlogDetailSectionContentText from "./BlogDetailSectionContentText";
import BlogDetailSectionContentImage from "./BlogDetailSectionContentImage";
import BlogDetailSectionContentLink from "./BlogDetailSectionContentLink";
import BlogDetailSectionContentQuote from "./BlogDetailSectionContentQuote";
// import BlogDetailSectionContentVideo from "./BlogDetailSectionContentVideo";

const BlogDetailSectionContentItem = ({ item }) => {
  return (
    <List.Item>
      {item?.type === "TEXT" && <BlogDetailSectionContentText item={item} />}
      {item?.type === "IMAGE" && <BlogDetailSectionContentImage item={item} />}
      {item?.type === "LINK" && <BlogDetailSectionContentLink item={item} />}
      {item?.type === "QUOTE" && <BlogDetailSectionContentQuote item={item} />}
      {/* {item?.type === "VIDEO" && <BlogDetailSectionContentVideo item={item} />} */}
    </List.Item>
  );
};

export default BlogDetailSectionContentItem;
