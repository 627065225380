import dayjs from "dayjs";

export default [
  {
    sender: {
      type: "user",
      profileImage: "",
    },
    content: "I'm so excited! I can't wait to experience everything South Korea has to offer.",
    time: dayjs().subtract(1, 'day'),
  },
  {
    sender: {
      type: "user",
      profileImage: "",
    },
    content: "What are some must-see places? I've heard a lot about Seoul and Busan.",
    time: dayjs().subtract(1, 'day'),
  },
  {
    sender: {
      type: "admin",
      profileImage: "",
    },
    content:
      "Seoul is definitely a must-visit. There's so much to see and do, from historical sites like Gyeongbokgung Palace.",
    time: dayjs().subtract(1, 'day'),
  },
  {
    sender: {
      type: "user",
      profileImage: "",
    },
    content: "I'm so excited! I can't wait to experience everything South Korea has to offer.",
    time: dayjs(),
  },
  {
    sender: {
      type: "user",
      profileImage: "",
    },
    content: "What are some must-see places? I've heard a lot about Seoul and Busan.",
    time: dayjs(),
  },
  {
    sender: {
      type: "admin",
      profileImage: "",
    },
    content:
      "Seoul is definitely a must-visit. There's so much to see and do, from historical sites like Gyeongbokgung Palace.",
    time: dayjs(),
  },
  {
    sender: {
      type: "user",
      profileImage: "",
    },
    content: "I'm so excited! I can't wait to experience everything South Korea has to offer.",
    time: dayjs(),
  },
  {
    sender: {
      type: "user",
      profileImage: "",
    },
    content: "What are some must-see places? I've heard a lot about Seoul and Busan.",
    time: dayjs(),
  },
  {
    sender: {
      type: "admin",
      profileImage: "",
    },
    content:
      "Seoul is definitely a must-visit. There's so much to see and do, from historical sites like Gyeongbokgung Palace.",
    time: dayjs(),
  },
  {
    sender: {
      type: "user",
      profileImage: "",
    },
    content: "I'm so excited! I can't wait to experience everything South Korea has to offer.",
    time: dayjs(),
  },
  {
    sender: {
      type: "user",
      profileImage: "",
    },
    content: "What are some must-see places? I've heard a lot about Seoul and Busan.",
    time: dayjs(),
  },
  {
    sender: {
      type: "admin",
      profileImage: "",
    },
    content:
      "asfasfsdfSeoul is definitely a must-visit. There's so much to see and do, from historical sites like Gyeongbokgung Palace.",
    time: dayjs(),
  },
];
