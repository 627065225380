import { useMobileDimensions } from "@/hooks";
import { Text } from "@/styles/reactnative";
import { MinusOutlined, RightOutlined } from "@ant-design/icons";
import { Flex } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Title = styled(Text)<{ $isMobile: boolean }>`
  font-size: ${props => (props?.$isMobile ? "12px" : "24px")};
  font-weight: 500;
  line-height: ${props => (props?.$isMobile ? "16px" : "32px")};
  color: #18181b;
`;

interface CustomHeaderProps {
  maintitle: string;
  subtitles: string[];
  chevIcon?: boolean | false;
  type?: string;
}

const CustomHeader = ({ maintitle, subtitles, chevIcon, type }: CustomHeaderProps) => {
  const { isMobile } = useMobileDimensions();
  const navigate = useNavigate();
  return (
    <Flex wrap={"wrap"} align="center" gap={8} style={{ marginBottom: isMobile ? 20 : 64 }}>
      <Title
        $isMobile={isMobile}
        onClick={() => {
          console.log(type);
          if (type === "blog") {
            navigate("/blogs");
          } else if (type === "product") {
            navigate("/products");
          }
        }}>
        {maintitle}
      </Title>
      {subtitles.map((subtitle, index) => (
        <Flex key={index} gap={8} align="center">
          {chevIcon ? (
            <RightOutlined
              style={{ fontSize: isMobile ? 10 : 18, color: "#18181B", marginTop: 6 }}
            />
          ) : (
            <MinusOutlined
              style={{ fontSize: isMobile ? 16 : 18, color: "#18181B", marginTop: 6 }}
            />
          )}
          <Text
            style={{
              color: "#4D4D4D",
              fontSize: isMobile ? 10 : 16,
              fontWeight: "500",
              lineHeight: isMobile ? "16px" : "32px",
              maxWidth: isMobile ? "100%" : 560,
            }}>
            {subtitle}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};

export default CustomHeader;
