import { CImage } from "@/app/common";
import { useMobileDimensions } from "@/hooks";
import { Flex, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { CloseCarousel, StyledCarousel, StyledTag } from "./styles";
import { StyledBackground } from "../../Mobile/MobileProductFIlter/styles";
import { CarouselRef } from "antd/es/carousel";

const ProductImages = ({ images, soldOut }) => {
  const { isMobile } = useMobileDimensions();
  const [open, setOpen] = useState(false);

  const carouselRef = useRef<CarouselRef>(null);

  const handleImageClick = index => {
    setOpen(true);
    setTimeout(() => {
      if (carouselRef.current) {
        carouselRef.current.goTo(index);
      }
    }, 0);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      const allowedTags = ["IMG", "SPAN", "BUTTON", "UL"];
      if (!allowedTags.includes(event.target.tagName)) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Flex vertical gap={12} style={{ position: "relative" }}>
        <CImage
          src={images?.[0]}
          width={isMobile ? "100%" : 528}
          height={isMobile ? 290 : 528}
          style={{ objectFit: "cover", cursor: "pointer" }}
          preview={false}
          onClick={() => handleImageClick(0)}
        />
        <Flex gap={12}>
          {images?.length > 4 ? (
            <Flex gap={12}>
              {images?.slice?.(1, 3)?.map?.((item, index) => (
                <CImage
                  src={item}
                  width={isMobile ? 111 : 168}
                  height={isMobile ? 106 : 168}
                  key={index}
                  preview={false}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleImageClick(index + 1)}
                />
              ))}
              <Flex
                align="center"
                justify="center"
                onClick={() => handleImageClick(3)}
                style={{
                  width: isMobile ? 111 : 168,
                  height: isMobile ? 106 : 168,
                  borderRadius: 12,
                  backgroundColor: "rgba(0, 0, 0, 0.50)",
                  cursor: "pointer",
                }}>
                <Typography.Text
                  style={{ color: "#fff", fontSize: isMobile ? 16 : 20, fontWeight: "500" }}>
                  +{images?.length - 3}
                </Typography.Text>
              </Flex>
            </Flex>
          ) : (
            images
              ?.slice(1)
              ?.map?.((item, index) => (
                <CImage
                  src={item}
                  width={isMobile ? 111 : 168}
                  height={isMobile ? 106 : 168}
                  key={index}
                  preview={false}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleImageClick(index + 1)}
                />
              ))
          )}
        </Flex>
        <StyledTag style={{ backgroundColor: soldOut ? "#FF6C73" : "#00B67A" }}>
          {soldOut ? "ရောင်းချပြီး" : "ရောင်းချဆဲ"}
        </StyledTag>
      </Flex>
      <StyledBackground
        className="background"
        animate={open ? { opacity: 0.6, zIndex: 3999 } : { opacity: 0, display: "none" }}
        initial={{ opacity: 0 }}
      />
      {open && (
        <>
          <CloseCarousel onClick={() => setOpen(false)} />
          <StyledCarousel ref={carouselRef} arrows infinite={false} style={{ position: "fixed" }}>
            {images?.map((item, index) => (
              <CImage
                key={index}
                src={item}
                width={isMobile ? 300 : 500}
                height={500}
                preview={false}
                style={{ objectFit: "cover" }}
              />
            ))}
          </StyledCarousel>
        </>
      )}
    </>
  );
};

export default ProductImages;
