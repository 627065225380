import React, { useEffect } from "react";
import { BlogCreatedAt, BlogSectionDescription, BlogSectionTitle, BlogTitle } from "./styles";
import { SectionWrapper } from "@/app/hoc";
import { motion } from "framer-motion";
import { textVariant } from "@/utils/motion";
import { useDesktopDimensions, useMobileDimensions } from "@/hooks";
import { useNavigate } from "react-router-dom";
import { Flex } from "antd";
import { useApiQuery } from "@/server/api-fetch";
import { GET_HIGHTLIGHT_BLOGS } from "@/server/apis";
import moment from "moment";
import { View } from "@/styles/reactnative";
import { CImage } from "@/app/common";
const BlogSection = () => {
  const navigate = useNavigate();
  const { isMobile } = useMobileDimensions();
  const { isDesktop } = useDesktopDimensions();

  const { data, refetch, isLoading } = useApiQuery({
    url: `${GET_HIGHTLIGHT_BLOGS}?limit=3&visibility=GLOBAL`,
  });

  useEffect(() => {
    refetch?.();
  }, [data]);

  return (
    <Flex vertical align="center" justify="center" style={{ marginTop: isMobile ? 64 : 121 }}>
      <motion.div
        variants={textVariant()}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <BlogSectionTitle $isMobile={isMobile}>
          မိတ်ဆွေအတွက် အကျိုးပြုဆောင်းပါးများစွာ
        </BlogSectionTitle>
        <BlogSectionDescription $isMobile={isMobile}>
          တောင်ကိုရီးယားသို့ လည်ပတ်ခြင်း၊ အလုပ်လုပ်ခြင်းနှင့် ပညာသင်ခြင်း စသည်တို့နှင့် ပတ်သက်သော
          သတင်းအချက်အလက် ဆောင်းပါးများကို လွယ်ကူစွာ ဖတ်ရှုလိုက်ပါ။
        </BlogSectionDescription>
      </motion.div>
      <Flex
        vertical={isMobile ? true : false}
        gap={isMobile ? 40 : 80}
        style={{ marginTop: isMobile ? 40 : 80 }}>
        {!isLoading
          ? data?.map?.((item, index) => (
              <View key={index}>
                <Flex
                  vertical
                  key={item._id}
                  style={{ cursor: "pointer",height: '100%' }}
                  onClick={() => navigate(`/blogs?id=${item._id}&title=${item.title}`)}>
                  <CImage
                    style={{
                      width: isMobile ? "100%" : isDesktop ? 228 : 328,
                      height: isDesktop ? '100%' : 207,
                      borderRadius: isMobile ? 12 : 16,
                      objectFit: 'cover',
                      backgroundColor: 'brown'
                    }}
                    src={item.coverImage}
                    preview={false}
                  />
                  <BlogCreatedAt>{moment(item?.createdAt).format("MMM DD, YYYY")}</BlogCreatedAt>
                  <BlogTitle $isMobile={isMobile}>{item.title}</BlogTitle>
                </Flex>
              </View>
            ))
          : null}
      </Flex>
    </Flex>
  );
};

export default SectionWrapper(BlogSection, "blogSection");
