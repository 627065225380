import { CImage, CParagraph } from "@/app/common";
import { Dot } from "@/app/components/Desktop/DesktopBlogLayout/styles";
import { BlogTitle } from "@/app/layouts/Desktop/Home/BlogSection/styles";
import { useDesktopDimensions, useMobileDimensions } from "@/hooks";
import { Text } from "@/styles/reactnative";
import { blogCalculateReadingTime } from "@/utils/caculatedReadingTime";
import { findNonEmptyCaption } from "@/utils/findNonEmptyCaption";
import { Flex } from "antd";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div<{ $isMobile: boolean }>`
  width: ${props => (props?.$isMobile ? "100%" : "632px")};
  height: ${props => (props?.$isMobile ? "162px" : "212px")};
  display: flex;
  gap: 16px;
  padding: 8px;
  margin-bottom: ${props => (props?.$isMobile ? "16px" : "32px")};
`;

const BlogCard = ({ item }) => {
  const { isMobile } = useMobileDimensions();
  const { isDesktop } = useDesktopDimensions();
  const navigate = useNavigate();
  const foundCaption = findNonEmptyCaption(item?.content);
  const readingTime = blogCalculateReadingTime(item?.content);
  return (
    <Container $isMobile={isDesktop || isMobile}>
      <CImage
        src={item?.coverImage}
        style={{
          width: isDesktop || isMobile ? 141 : 300,
          height: isDesktop || isMobile ? 142 : 195,
          borderRadius: 12,
        }}
      />
      <Flex
        vertical
        gap={8}
        justify="space-between"
        style={{ cursor: "pointer" }}
        onClick={() => navigate(`/blogs?id=${item?._id}&title=${item?.title}`)}>
        <Text style={{ fontSize: 10, color: "#2C2762", fontWeight: "500" }}>{item?.category}</Text>
        <BlogTitle $isMobile={isMobile} style={{fontWeight: '500'}}>
          {item?.title}
        </BlogTitle>
        <CParagraph
          lines={isMobile || isDesktop ? 2 : 3}
          style={{
            fontSize: isMobile ? 10 : 12,
            color: "#4D4D4D",
            fontWeight: "400",
            lineHeight: "24px",
          }}>
          {foundCaption}
        </CParagraph>
        <Flex align="center" gap={8}>
          <Text style={{ fontSize: isMobile || isDesktop ? 8 : 12, fontWeight: "400" }}>
            {moment(item?.createdAt).format("MMM DD, YYYY")}
          </Text>
          <Dot />
          <Text style={{ fontSize: isMobile || isDesktop ? 8 : 12, fontWeight: "400" }}>
            {readingTime}
          </Text>
        </Flex>
      </Flex>
    </Container>
  );
};

export default BlogCard;
