import React, { useEffect, useState } from "react";
import { MobileNavbarTitle, NavbarContainer } from "./styles";
import SvgNavToggle from "@/assets/svg/SvgNavToggle";
import { Flex, Typography } from "antd";
import { NavbarTitle } from "../../Desktop/DesktopNavbar/styles";
import { SearchOutlined } from "@ant-design/icons";
import { motion } from "framer-motion";
import { textVariant } from "@/utils/motion";
import { useNavigate } from "react-router-dom";
import { SectionWrapper } from "@/app/hoc";

const MobileNavbar = ({ setShowSearchContainer }) => {
  const navigation = useNavigate();
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > lastScrollY) {
      // Scrolling down
      setShowNavbar(false);
    } else if (currentScrollY < lastScrollY) {
      // Scrolling up
      setShowNavbar(true);
    }

    setLastScrollY(currentScrollY);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return (
    <>
      <motion.div
        variants={textVariant()}
        style={{
          position: "fixed",
          top: showNavbar ? 0 : "-80px",
          left: 0,
          transition: "top 0.3s ease-in-out",
          width: "100%",
          zIndex: 1000,
          backgroundColor: "#fff",
        }}>
        <NavbarContainer>
          <Flex style={{cursor: 'pointer'}} onClick={() => toggleSidebar()}>
            <SvgNavToggle />
          </Flex>
          <Flex style={{cursor: 'pointer'}} onClick={() => navigation("/")}>
            <NavbarTitle style={{ color: "#485AFF" }}>ULSAN</NavbarTitle>
            <NavbarTitle style={{ color: "#18181B", marginLeft: 3 }}>CHAM</NavbarTitle>
          </Flex>
          <SearchOutlined
            style={{ color: "#000", fontSize: 20 }}
            onClick={() => setShowSearchContainer(true)}
          />
        </NavbarContainer>
      </motion.div>

      <motion.div
        initial={{ x: "-100%" }}
        animate={{ x: isSidebarOpen ? "0%" : "-100%" }}
        transition={{ duration: 0.3 }}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          backgroundColor: "black",
          zIndex: 1001,
          padding: "20px",
        }}>
        <Flex vertical justify="center" align="center" gap={40} style={{ height: "100%" }}>
          <MobileNavbarTitle onClick={() => navigation("/")}>ပင်မစာမျက်နှာ</MobileNavbarTitle>
          <MobileNavbarTitle onClick={() => navigation("/blogs")}>ဆောင်းပါးများ</MobileNavbarTitle>
          <MobileNavbarTitle onClick={() => navigation("/services")}>ဝန်ဆောင်မှုများ</MobileNavbarTitle>
          <MobileNavbarTitle onClick={() => navigation("/products")}>စျေးဆိုင်</MobileNavbarTitle>
        </Flex>

        <div
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            cursor: "pointer",
            color: "white",
            fontSize: "24px",
          }}
          onClick={toggleSidebar}>
          &#10005; {/* This is the close (X) button */}
        </div>
      </motion.div>
    </>
  );
};

export default SectionWrapper(MobileNavbar, 'mobileNavbar');
