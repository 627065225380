import {
  DesktopFooter,
  DesktopNavbar,
  ServiceCategoryHeader,
  ServiceHeader,
} from "@/app/components/Desktop";
import { ServiceTypesList } from "@/app/features";
import { useScrollToTop } from "@/hooks";
import React from "react";

const DesktopView = ({ parentId, title, description, servicePaths }) => {
  useScrollToTop();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "space-between",
      }}>
      <DesktopNavbar />
      <div style={{ paddingInline: 71, marginTop: 130 }}>
        <ServiceHeader maintitle={"ဝန်ဆောင်မှု"} subtitles={servicePaths} chevIcon />
        <ServiceCategoryHeader title={title} description={description} />
        <ServiceTypesList parentId={parentId} title={title} servicePaths={servicePaths} />
      </div>
      <DesktopFooter />
    </div>
  );
};

export default DesktopView;
