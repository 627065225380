import React, { useState } from "react";
import { Container } from "./styles";
import HeadSection from "./HeadSection";
import { CustomerIdForm } from "@/app/entities";
import Chat from "../Chat";
import { useDesktopDimensions, useMobileDimensions } from "@/hooks";
import { CParagraph } from "@/app/common";

const ContactUsLayout = ({ open, setOpen }) => {
  const [showForm, setShowForm] = useState(true);
  const { isDesktop } = useDesktopDimensions();
  const { isMobile } = useMobileDimensions();

  return (
    <Container
      style={{ width: isMobile ? "100%" : isDesktop ? 652 : 952 }}
      initial={{ x: "100%" }}
      animate={{ x: open ? "0%" : "100%" }}
      transition={{ duration: 0.3 }}>
      <HeadSection setOpen={setOpen} />
      {showForm ? (
        <div
          style={{
            paddingInline: isMobile ? 16 : 24,
            paddingBlock: isMobile ? 40 : 80,
            height: isMobile ? "65%" : "70%",
          }}>
          <CParagraph
            style={{
              fontSize: isMobile ? 14 : 20,
              color: "#000",
              fontWeight: "500",
              lineHeight: "40px",
            }}>
            ကျေးဇူးပြု၍ Chat နှင့် အခြားသော ဝန်ဆောင်မှုများ စတင်နိုင်ရန်အတွက် Customer ID
            အားဖြည့်စွက်ပြီး ဝင်ရောက်မည် ဟုသော ခလုတ်ကိုနှိပ်ပေးပါ။ အကယ်၍ သင့်တွင် Customer ID
            မရှိပါက ကျေးဇူးပြု၍  Customer ID ရယူမည် ဟုသော  ခလုတ်ကိုနှိပ်ပေးပါ။
          </CParagraph>
          <CustomerIdForm setOpenForm={setShowForm} setOpen={setOpen} />
        </div>
      ) : (
        <Chat />
      )}
    </Container>
  );
};

export default ContactUsLayout;
