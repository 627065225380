import { CustomCarousel, DesktopAdSection, DesktopFooter } from "@/app/components/Desktop";
import { MobileNavbar } from "@/app/components/Mobile";
import { CategorySection, PopularBlogsSection, RecommandBlogs } from "@/app/layouts/Desktop";
import { MobileSampleBlogs, MobileSearchContainer } from "@/app/layouts/Mobile";
import React, { useState } from "react";

const MobileView = () => {
  const [showSearchContainer, setShowSearchContainer] = useState(false);
  return (
    <main>
      <MobileSearchContainer
        showSearchContainer={showSearchContainer}
        setShowSearchContainer={setShowSearchContainer}
      />
      {!showSearchContainer && <MobileNavbar setShowSearchContainer={setShowSearchContainer} />}
      <div style={{ marginTop: "21%" }}>
        <CustomCarousel />
        <div style={{ paddingInline: 16 }}>
          <MobileSampleBlogs />
          <CategorySection />
          <PopularBlogsSection title={"လတ်တလော ရေပန်းစားမှုများ"} />
          <DesktopAdSection />
          <RecommandBlogs title={"အကျိုးပြု ဆောင်းပါးများ"} />
        </div>
      </div>
      <DesktopFooter />
    </main>
  );
};

export default MobileView;
