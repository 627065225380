import React from "react";

function SvgNavToggle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          fill="#414141"
          fillRule="evenodd"
          d="M3.6 6a1.2 1.2 0 011.2-1.2h14.4a1.2 1.2 0 110 2.4H4.8A1.2 1.2 0 013.6 6zm0 6a1.2 1.2 0 011.2-1.2h14.4a1.2 1.2 0 110 2.4H4.8A1.2 1.2 0 013.6 12zm0 6a1.2 1.2 0 011.2-1.2H12a1.2 1.2 0 110 2.4H4.8A1.2 1.2 0 013.6 18z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}

export default SvgNavToggle;
