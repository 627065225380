import { useMobileDimensions } from "@/hooks";
import { Form, Input } from "antd";
import React from "react";
import styled from "styled-components";

const StyledInput = styled(Input)<{$isMobile: boolean}>`
  height: ${props => props?.$isMobile ? "48px" : "64px"};
  padding-block: ${props => props?.$isMobile ? "10px" : "18px"};
  padding-inline: ${props => props?.$isMobile ? "16px" : "24px"};
  font-size: ${props => props?.$isMobile ? "12px" : "20px"};
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`;

const ServicePhonenumber = () => {
    const {isMobile} = useMobileDimensions()
  return (
    <Form.Item
      label="ဖုန်းနံပါတ်"
      name="phonenumber"
      //   labelCol={{ span: 24 }}
      style={{ width: "100%" }}
      rules={[{ required: true, message: "*ဖုန်းနံပါတ် ကိုထည့်ရန် လိုအပ်ပါသည်" }]}
      required>
      <StyledInput $isMobile={isMobile} placeholder="ဖုန်းနံပါတ်အားဖြည့်စွက်ပါ" name="phonenumber" />
    </Form.Item>
  );
};

export default ServicePhonenumber;
