import { DesktopFooter, DesktopNavbar } from "@/app/components/Desktop";
import { ProductDetail, ProductSection } from "@/app/layouts/Desktop";
import { useDesktopDimensions, useScrollToTop } from "@/hooks";
import { Flex } from "antd";
import React from "react";

const DesktopView = ({ data }) => {
  const { isDesktop } = useDesktopDimensions();
  useScrollToTop();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "space-between",
      }}>
      <DesktopNavbar />
      <Flex vertical style={{ paddingInline: isDesktop ? 20 : 72, marginTop: 130 }}>
        <ProductDetail data={data} />
        <ProductSection title={"သင့်အတွက် အကြုံပြုထားသော"} category={data?.category} />
      </Flex>
      <DesktopFooter />
    </div>
  );
};

export default DesktopView;
