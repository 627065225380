import { motion } from "framer-motion";
import styled from "styled-components";

export const Container = styled(motion.div)`
  height: 100vh;
  position: fixed;
  background-color: #fff;
  border-left: 1px solid #EDEDED;
  backdrop-filter: blur(2px);
  top: 0;
  right: 0;
  z-index: 9999;
`;
