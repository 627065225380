import React from "react";

function SvgFacebook() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <g>
        <path
          fill="#fff"
          d="M11.666 11.25h2.084l.833-3.333h-2.917V6.25c0-.858 0-1.666 1.667-1.666h1.25v-2.8a23.473 23.473 0 00-2.38-.117c-2.263 0-3.87 1.38-3.87 3.917v2.333h-2.5v3.333h2.5v7.084h3.333V11.25z"
        ></path>
      </g>
    </svg>
  );
}

export default SvgFacebook;
