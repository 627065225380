import React, { useState } from "react";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { Container, SearchInput } from "./styles";
import { Flex } from "antd";
import { useDebounce } from "@/hooks";
import SearchRecommndationList from "./SearchRecommndationList";

const ProductSearchBar = ({ vStyle, placeholder, style, action }: any) => {
  const [search, setSearch] = useState("");
  const searchQuery = useDebounce(search, 300);

  return (
    <Flex vertical style={{ width: 554, minHeight: 44, ...vStyle }}>
      <Container $isOpen={!!search} align="center">
        <SearchInput
          placeholder={placeholder}
          style={{ ...style }}
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
        {search && (
          <CloseOutlined
            onClick={() => setSearch("")}
            style={{ fontSize: 20, color: "#565656", cursor: "pointer" }}
          />
        )}
        {!search && (
          <SearchOutlined
            onClick={() => setSearch("")}
            style={{ fontSize: 20, color: "#565656", cursor: "pointer" }}
          />
        )}
      </Container>
      {search && <SearchRecommndationList searchQuery={searchQuery} action={action} />}
    </Flex>
  );
};

export default ProductSearchBar;
