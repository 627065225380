import styled from "styled-components";

export const Container = styled.div<{ $isMobile: boolean }>`
  width: 98%;
  min-height: ${props => (props?.$isMobile ? "191px" : "274px")};
  padding: ${props => (props?.$isMobile ? "16px" : "24px")};
  display: Flex;
  flex-direction: column;
  gap: 12px;
  border: 1px solid #d7d7ea;
  justify-content: space-between;
  border-radius: 12px;
`;

export const CollapsibleContent = styled.div<{ $open: boolean; $contentHeight: number }>`
  /* max-height: ${({ $open, $contentHeight }) => ($open ? `${$contentHeight}px` : "60px")}; */
  overflow: hidden;
  transition: max-height 0.3s ease;
`;
