import { SectionWrapper } from "@/app/hoc";
import { images } from "@/assets/images";
import { useMobileDimensions } from "@/hooks";
import { fadeIn } from "@/utils/motion";
import { Flex } from "antd";
import { motion } from "framer-motion";
import React from "react";

const DesktopAdSection = () => {
  const {isMobile} = useMobileDimensions()
  return (
    <Flex align="center" justify="center" style={{ marginBlock: isMobile ? 42 : 100, width: '100%' }}>
      <motion.div variants={fadeIn("up", "spring")}>
        <img
          src={images.ad_img}
          style={{ width: isMobile ? '100%' : 1297, height: isMobile ? 120 : 160, objectFit: "cover" }}
        />
      </motion.div>
    </Flex>
  );
};

export default SectionWrapper(DesktopAdSection, "adSection");
