import { BlogSearchBar, DesktopFooter, ProductSearchBar } from "@/app/components/Desktop";
import { MobileNavbar } from "@/app/components/Mobile";
import { ProductsList } from "@/app/features";
import { MobileProductFilter, MobileSearchContainer } from "@/app/layouts/Mobile";
import { SvgFilterIcon } from "@/assets/svg";
import { Badge, Button, Flex } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";

const MobileView = () => {
  const [showSearchContainer, setShowSearchContainer] = useState(false);
  const [showProductFilter, setShowProductFilter] = useState(false);
  const [searchResult, setSearchResult] = useState<any>(null);

  const [productType, setProductType] = useState("ပစ္စည်းများအားလုံး");
  const [productGenre, setProductGenre] = useState<string[]>([]);
  const [productBrand, setProductBrand] = useState<string[]>([]);
  const [isFilter, setIsFilter] = useState(false);
  const [sliderRange, setSliderRange] = useState<[number, number]>([1, 10000000]);

  const bottomSheetRef = useRef(null);
  const useoutsideAlerter = (ref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref?.current && !ref?.current?.contains(event?.target)) {
          setShowProductFilter(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const clearFilter = useCallback(() => {
    setProductType("ပစ္စည်းများအားလုံး");
    setProductBrand([]);
    setProductGenre([]);
    setIsFilter(false);
  }, [setProductBrand, setProductGenre, setProductType]);

  useoutsideAlerter(bottomSheetRef);

  const totalFilter = productBrand?.length + productGenre?.length;

  return (
    <main>
      <MobileSearchContainer
        showSearchContainer={showSearchContainer}
        setShowSearchContainer={setShowSearchContainer}
      />
      <div style={{ paddingInline: 16, marginTop: 90, position: "relative" }}>
        {!showSearchContainer && <MobileNavbar setShowSearchContainer={setShowSearchContainer} />}
        <Flex
          style={{ flexDirection: "row-reverse" }}
          justify="space-between"
          align="center"
          gap={16}>
          <Badge count={totalFilter > 0 ? totalFilter + 3 : 0}>
            <Button
              onClick={() => setShowProductFilter(true)}
              type="primary"
              style={{
                width: 44,
                height: 44,
                backgroundColor: totalFilter > 0 ? "#485AFF" : "#F0F0F0",
              }}
              icon={<SvgFilterIcon fillColor={totalFilter > 0 ? "#fff" : "#000"} />}
            />
          </Badge>
        </Flex>

        <ProductSearchBar
          placeholder={"ပစ္စည်းများကို ရှာဖွေမည်"}
          vStyle={{ width: "78%", position: "absolute", left: "16px", top: "0px" }}
          action={setSearchResult}
        />
        <ProductsList
          productType={productType}
          productGenre={productGenre}
          setProductGenre={setProductGenre}
          productBrand={productBrand}
          setProductBrand={setProductBrand}
          isFilter={isFilter}
          clearFilter={clearFilter}
          searchResult={searchResult}
          sliderRange={sliderRange}
          setSliderRange={setSliderRange}
        />
      </div>
      <MobileProductFilter
        open={showProductFilter}
        setOpen={setShowProductFilter}
        ref={bottomSheetRef}
        productType={productType}
        setProductType={setProductType}
        productBrand={productBrand}
        setProductBrand={setProductBrand}
        productGenre={productGenre}
        setProductGenre={setProductGenre}
        clearFilter={clearFilter}
        sliderRange={sliderRange}
        setSliderRange={setSliderRange}
      />
      <DesktopFooter />
    </main>
  );
};

export default MobileView;
