import React from "react";
import DesktopView from "./DesktopView";
import { useSearchParams } from "react-router-dom";
import { useMobileDimensions, useScrollToTop } from "@/hooks";
import MobileView from "./MobileView";

const BlogsByCategoryPage = () => {
  const [searchParams] = useSearchParams();
  const category = searchParams.get("category");
  const { isMobile } = useMobileDimensions();

  useScrollToTop();

  return <>{isMobile ? <MobileView category={category} /> : <DesktopView category={category} />}</>;
};

export default BlogsByCategoryPage;
