import { CImage, CParagraph } from "@/app/common";
import React from "react";
import { SectionWrapper } from "@/app/hoc";
import { motion } from "framer-motion";
import { fadeIn } from "@/utils/motion";
import { useNavigate } from "react-router-dom";
import { BlogCard } from "@/app/entities";
import { useDesktopDimensions } from "@/hooks";
import { Text } from "@/styles/reactnative";
import { Flex } from "antd";
import { useApiQuery } from "@/server/api-fetch";
import { GET_HIGHTLIGHT_BLOGS } from "@/server/apis";
import { findNonEmptyCaption } from "@/utils/findNonEmptyCaption";

const SampleBlogs = () => {
  const navigate = useNavigate();
  const { isDesktop } = useDesktopDimensions();
  const { data } = useApiQuery({
    url: `${GET_HIGHTLIGHT_BLOGS}?limit=3&visibility=HIGHLIGHTED`,
  });
  const foundCaption = findNonEmptyCaption(data?.[0]?.content);
  return (
    <Flex gap={32} style={{ height: 456, justifyContent: "space-between" }}>
      <motion.div variants={fadeIn("right", "spring")} style={{ flex: 1 }}>
        <Flex vertical style={{ height: "100%" }} justify="space-between">
          <CImage
            src={data?.[0]?.coverImage}
            width={isDesktop ? 400 : "auto"}
            height={241}
            style={{ objectFit: "cover", borderRadius: 12 }}
          />
          <Flex
            vertical
            gap={32}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/blogs?id=${data?.[0]?._id}&title=${data?.[0]?.title}`)}>
            <Text style={{ fontSize: isDesktop ? 14 : 20, fontWeight: "500" }}>
              {data?.[0]?.title}
            </Text>
            <CParagraph
              lines={4}
              style={{
                fontSize: isDesktop ? 12 : 16,
                fontWeight: "400",
                color: "#4D4D4D",
                lineHeight: "26px",
                maxWidth: isDesktop ? 400 : "auto",
              }}>
              {foundCaption}
            </CParagraph>
          </Flex>
        </Flex>
      </motion.div>

      <motion.div variants={fadeIn("left", "spring")}>
        <Flex vertical gap={32}>
          {data?.length
            ? [data?.[1], data?.[2]].map((item, index) => <BlogCard key={index} item={item} />)
            : null}
        </Flex>
      </motion.div>
    </Flex>
  );
};

export default SectionWrapper(SampleBlogs, "sampleBlogs");
