import { useMobileDimensions } from "@/hooks";
import React from "react";
import styled from "styled-components";

const FloatingButton = styled.button<{ $isMobile: boolean }>`
  position: fixed;
  right: ${props => (props?.$isMobile ? "16px" : "73px")};
  bottom: ${props => (props?.$isMobile ? "80px" : "64px")};
  background-color: #485aff;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #3b4bcc;
  }
`;

const CFloatingButton = ({ icon, ...props }) => {
  // const { isMobile } = useMobileDimensions();
  const isMobile = false;
  return (
    <></>
    // <FloatingButton $isMobile={isMobile} {...props}>
    //   {icon}
    // </FloatingButton>
  );
};

export default CFloatingButton;
