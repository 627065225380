import React from "react";

function SvgFloatButton() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 40 40"
    >
      <g>
        <path
          fill="#fff"
          d="M36.742 12.95l-.084 10.5a7.832 7.832 0 01-.6 3.017 7.833 7.833 0 01-4.25 4.25 7.833 7.833 0 01-3.016.6h-3.884l-3.333 3.333a2.167 2.167 0 01-.733.5c-.288.133-.6.202-.917.2a2.333 2.333 0 01-.917-.183 2.333 2.333 0 01-.766-.55l-3.25-3.233h-3.884a7.851 7.851 0 01-7.85-7.867v-11a7.833 7.833 0 012.3-5.567 7.967 7.967 0 015.55-2.3h17.767a7.85 7.85 0 017.867 7.867v.433z"
        ></path>
      </g>
    </svg>
  );
}

export default SvgFloatButton;
