import { Text } from "@/styles/reactnative";
import styled from "styled-components";

export const FooterContainer = styled.div<{ $isMobile: boolean }>`
  width: 100%;
  padding: 40px ${props => (props?.$isMobile ? "16px" : "40px")} 48px
    ${props => (props?.$isMobile ? "16px" : "40px")};
  display: flex;
  flex-direction: column;
  gap: ${props => props?.$isMobile ? "40px" : "64px"};
  background-color: #000;
  margin-top: 120px;
  /* position: absolute;
  bottom: 0px; */
`;

export const SocialButton = styled.a`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.32);
`;

export const FooterBody = styled.div<{ $isMobile: boolean }>`
  height: ${props => props?.$isMobile ? "auto" : "188px"};
  display: flex;
  flex-direction: ${props => props?.$isMobile ? "column" : 'row'};
  gap: 64px;
  > * {
    width: 340px;
    display: flex;
    flex-direction: column;
    gap: ${props => props?.$isMobile ? "16px" : "20px"};
  }
`;

export const StyledTitle = styled(Text)<{ $isMobile: boolean }>`
  color: #fff;
  font-size: ${props => props?.$isMobile ? "18px" : "20px"};
  font-weight: "500";
`;

export const StyledText = styled(Text)<{ $isMobile: boolean }>`
  color: #fff;
  cursor: pointer;
  font-size: ${props => props?.$isMobile ? "14px" : "12px"};
  font-weight: "400";
`;

export const PoweredBy = styled(Text)`
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.48px;
  color: rgba(255, 255, 255, 0.8);
`;
