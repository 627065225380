import { useMobileDimensions } from "@/hooks";
import { Text } from "@/styles/reactnative";
import { CloseOutlined } from "@ant-design/icons";
import React from "react";
import styled from "styled-components";

const Container = styled.div<{ $isMobile: boolean }>`
  width: 100%;
  height: ${props => (props?.$isMobile ? "64px" : "100px")};
  padding-inline: 38px;
  padding-block: 31px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #485aff;
`;

const HeadSection = ({ setOpen }) => {
  const { isMobile } = useMobileDimensions();
  return (
    <Container $isMobile={isMobile}>
      <Text
        style={{
          fontSize: isMobile ? 16 : 24,
          fontWeight: "600",
          color: "#fff",
          lineHeight: isMobile ? "25.6px" : "38.4px",
        }}>
        ကျွန်ုပ်တို့နှင့် ဆက်သွယ်ရန်
      </Text>
      <CloseOutlined style={{ fontSize: 24, fontWeight: "600" }} onClick={() => setOpen(false)} />
    </Container>
  );
};

export default HeadSection;
