import React, { useState } from "react";
import { ProductsGrid, StyledPagination } from "./styles";
import { ProductCard } from "@/app/entities";
import { Flex } from "antd";
import FilterList from "../FilterList";
import { useMobileDimensions } from "@/hooks";
import { useApiQuery } from "@/server/api-fetch";
import { GET_ALL_PRODUCTS } from "@/server/apis";
import ProductListLoading from "./ProductListLoading";

const ProductsList = ({
  productGenre = [],
  productBrand = [],
  setProductGenre,
  setProductBrand,
  isFilter,
  clearFilter,
  searchResult,
  productType,
  sliderRange,
}: any) => {
  const { isMobile } = useMobileDimensions();
  const [limit, setLimit] = useState(20);
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const offset = (currentPage - 1) * limit;
  const genres = productGenre?.length ? productGenre.join(",") : "";
  const brands = productBrand?.length ? productBrand.join(",") : "";
  const { data, isLoading, refetch } = useApiQuery({
    url: `${GET_ALL_PRODUCTS}?limit=${limit}&offset=${offset}&search=${
      searchResult?.name || ""
    }&category=${
      productType === "ပစ္စည်းများအားလုံး" ? "" : productType
    }&type=${genres}&brand=${brands}&startPrice=${sliderRange?.[0] || 0}&endPrice=${
      sliderRange?.[1] || 10000000
    }`,
  });

  const products = data?.products || [];
  const totalCount = data?.totalCount || 0;

  const handlePaginationChange = (page: number, pageSize: number) => {
    setCurrentPage(page); // Update the current page
    setLimit(pageSize); // Update the limit if pageSize changes
    refetch(); // Optionally refetch data
  };
  const clearSingleFilter = item => {
    if (productGenre.includes(item)) {
      setProductGenre(productGenre.filter(genre => genre !== item));
    }
    // Check if the item is in productBrand and remove it
    else if (productBrand.includes(item)) {
      setProductBrand(productBrand.filter(brand => brand !== item));
    }
  };

  return (
    <Flex style={{ width: "100%" }} vertical>
      {isFilter && (
        <div style={{ marginBottom: 40 }}>
          <FilterList
            data={[...productBrand, ...productGenre]}
            onClear={clearFilter}
            onClearSingle={clearSingleFilter}
          />
        </div>
      )}
      <ProductsGrid $isMoile={isMobile}>
        {!isLoading && products?.map?.((item, index) => <ProductCard key={index} item={item} />)}
        {isLoading && <ProductListLoading />}
      </ProductsGrid>
      <StyledPagination
        current={currentPage}
        pageSize={limit}
        total={totalCount}
        onChange={handlePaginationChange}
        showSizeChanger={true}
        pageSizeOptions={["10", "15", "20"]}
      />
    </Flex>
  );
};

export default ProductsList;
