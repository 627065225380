import { CustomHeader, DesktopFooter, BlogSearchBar } from "@/app/components/Desktop";
import { MobileNavbar } from "@/app/components/Mobile";
import { CategoriesList } from "@/app/features";
import { MobileSearchContainer } from "@/app/layouts/Mobile";
import React, { useState } from "react";

const MobileView = () => {
  const [showSearchContainer, setShowSearchContainer] = useState(false);
  return (
    <main>
      <MobileSearchContainer
        showSearchContainer={showSearchContainer}
        setShowSearchContainer={setShowSearchContainer}
      />
      <div style={{ paddingInline: 16, marginTop: 90 }}>
        {!showSearchContainer && <MobileNavbar setShowSearchContainer={setShowSearchContainer} />}
        <CustomHeader maintitle={"Ulsan Cham"} subtitles={["ကဏ္ဍအမျိုးအစားများ"]} />
        <BlogSearchBar
          placeholder={"ကဏ္ဍအမျိုးအစားများကို ရှာဖွေမည်"}
          vStyle={{ width: "90%", position: "absolute" }}
        />
        <div style={{ marginTop: 90 }}>
          <CategoriesList />
        </div>
      </div>
      <DesktopFooter />
    </main>
  );
};

export default MobileView;
