import { CImage, CText } from "@/app/common";
import { useMobileDimensions } from "@/hooks";
import { Flex, List, Typography } from "antd";
import React from "react";
import { Dot, StyledText } from "./styles";
import { formatDate } from "@/utils/helper";
import { blogCalculateReadingTime } from "@/utils/caculatedReadingTime";

import { BlogDetailsSectionContentList } from "@/app/features";

const BlogDetails = ({ data, loading }) => {
  const { isMobile } = useMobileDimensions();

  // const readingTime = blogCalculateReadingTime(data?.content);

  return (
    <Flex vertical style={{ flex: 1 }}>
      <Typography.Text
        style={{
          fontSize: isMobile ? 16 : 24,
          fontWeight: "500",
          lineHeight: isMobile ? "25.6px" : "32px",
          color: "#000",
          marginBottom: 40,
        }}>
        {data?.title}
      </Typography.Text>
      <CImage
        src={data?.coverImage}
        // src={images.dummy_blog_1}
        width={"100%"}
        height={isMobile ? 320 : 400}
        style={{ borderRadius: 12, objectFit: "cover" }}
      />
      <Flex align="center" justify="center" gap={isMobile ? 10 : 32} style={{ marginTop: 24 }}>
        <StyledText $isMobile={isMobile}>{formatDate(data?.createdAt)}</StyledText>
        <Dot $isMobile={isMobile} />
        <StyledText $isMobile={isMobile}>{data?.category}</StyledText>
        {/* <Dot $isMobile={isMobile} />
        <StyledText $isMobile={isMobile}>ဖတ်ရန်ကြာချိန် ၆ မိနစ်</StyledText> */}
      </Flex>
      <List
        dataSource={data?.content}
        style={{ marginTop: 16 }}
        itemLayout="vertical"
        split={false}
        renderItem={(item: any, index) => (
          <List.Item key={index}>
            <CText style={{ fontSize: 20, fontWeight: "500" }}>{item?.caption}</CText>
            <BlogDetailsSectionContentList item={item} />
          </List.Item>
        )}
      />
    </Flex>
  );
};

export default BlogDetails;
