import React from 'react'
import { useSelector } from "react-redux";
import { themeColor } from './styles/themeColor';
import isPropValid from "@emotion/is-prop-valid";
import { ThemeProvider as StyledThemeProvider, StyleSheetManager } from "styled-components";
import { ConfigProvider, message } from "antd";
import AppRouter from './routes';

function App() {
  const darkMode = useSelector((state: any) => state?.appstore?.darkMode);
  const isDarkMode = darkMode ? themeColor?.dark : themeColor?.light;

  message.config({
    top: 30,
    duration: 2,
    maxCount: 1,
    rtl: true,
    prefixCls: "c-toast",
  });

  return (
    <ConfigProvider>
      <StyledThemeProvider theme={isDarkMode}>
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
          <AppRouter />
        </StyleSheetManager>
      </StyledThemeProvider>
    </ConfigProvider>
  )
}

function shouldForwardProp(propName: any, target: any) {
  if (typeof target === "string") {
    return isPropValid(propName);
  }
  return true;
}

export default App
