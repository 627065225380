import React from "react";

function SvgGreenMark() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="140"
      height="140"
      fill="none"
      viewBox="0 0 140 140"
    >
      <g>
        <g>
          <mask
            id="mask0_416_2273"
            style={{ maskType: "luminance" }}
            width="124"
            height="130"
            x="7"
            y="5"
            maskUnits="userSpaceOnUse"
          >
            <g>
              <g
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="12"
              >
                <path
                  fill="#555"
                  d="M69.001 11.667l15.322 11.176 18.967-.035 5.824 18.049 15.365 11.118L118.585 70l5.894 18.025-15.365 11.118-5.824 18.049-18.967-.035L69 128.333l-15.32-11.176-18.968.035-5.825-18.049-15.365-11.118L19.418 70l-5.895-18.025 15.365-11.118 5.825-18.049 18.967.035 15.321-11.176z"
                ></path>
                <path d="M48.585 70l14.583 14.583 29.167-29.166"></path>
              </g>
            </g>
          </mask>
          <g mask="url(#mask0_416_2273)">
            <path fill="#00B67A" d="M-.999 0h140v140h-140V0z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgGreenMark;
