import {
  CustomCarousel,
  DesktopAdSection,
  DesktopFooter,
  DesktopNavbar,
} from "@/app/components/Desktop";
import { ServiceSection } from "@/app/layouts/Desktop";
import React from "react";

const DesktopView = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "space-between",
      }}>
      <DesktopNavbar />
      <div style={{ marginTop: "7%" }}>
        <CustomCarousel />
        <div style={{ paddingInline: 71, marginTop: 62 }}>
          <ServiceSection
            title={"လူကြိုက်များသော ဝန်ဆောင်မှုများ"}
            isViewAll={false}
            isPaginate={true}
            featured={2}
          />
          <DesktopAdSection />
          <ServiceSection title={"ဝန်ဆောင်မှု အားလုံး"} isViewAll={false} showAllServices={true} />
        </div>
      </div>
      <DesktopFooter />
    </div>
  );
};

export default DesktopView;
