import { CParagraph } from "@/app/common";
import { Dot } from "@/app/components/Desktop/DesktopBlogLayout/styles";
import { useMobileDimensions } from "@/hooks";
import { Text } from "@/styles/reactnative";
import { Flex } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, StyledTag } from "./styles";

const ProductCard = ({ item }) => {
  const { isMobile } = useMobileDimensions();

  const navigate = useNavigate();
  const soldOut = item?.status === "sold";
  return (
    <Container $isMobile={isMobile} onClick={() => navigate(`/products?id=${item?._id}`)}>
      <img src={item?.images[0]} style={{ width: "100%", height: "120px", borderRadius: 8 }} />
      <StyledTag style={{ backgroundColor: soldOut ? "#FF6C73" : "#00B67A" }}>
        {soldOut ? "ရောင်းချပြီး" : "ရောင်းချဆဲ"}
      </StyledTag>
      <Flex vertical style={{ marginTop: 16 }}>
        <Flex align="center" gap={8}>
          <Text
            style={{
              fontSize: isMobile ? 10 : 12,
              fontWeight: 400,
              color: "#000",
            }}>
            {item?.category || "အမျိုးအစားမရှိပါ"}
          </Text>
          <Dot />
          <Text
            style={{
              fontSize: isMobile ? 10 : 12,
              fontWeight: 400,
              color: "#000",
            }}>
            {item?.brand || "အမျိုးအစားမရှိပါ"}
          </Text>
        </Flex>
        <CParagraph
          style={{
            fontSize: isMobile ? 12 : 14,
            fontWeight: "500",
            color: "#000",
            height: 40,
          }}
          lines={2}>
          {item?.name}
        </CParagraph>
        <Text
          style={{
            fontSize: isMobile ? 12 : 16,
            fontWeight: "500",
            color: "#485AFF",
            justifySelf: "flex-end",
          }}>
          ₩{item?.price}
        </Text>
      </Flex>
    </Container>
  );
};

export default ProductCard;
