import { images } from "@/assets/images";
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useMobileDimensions } from "@/hooks";
import { SectionWrapper } from "@/app/hoc";
import { motion } from "framer-motion";
import { fadeIn } from "@/utils/motion";

const CustomCarousel = () => {
  const dummyData = [images.dummy_carousel, images.dummy_carousel, images.dummy_carousel];

  const { isMobile } = useMobileDimensions();

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <motion.div variants={fadeIn("right")}>
      <Slider {...settings}>
        {dummyData?.map((item, index) => (
          <img
            src={item}
            key={index}
            style={{ objectFit: "cover" }}
            width={"100%"}
            height={isMobile ? 87 : 230}
          />
        ))}
      </Slider>
    </motion.div>
  );
};

export default SectionWrapper(CustomCarousel, "customCarousel");
