import { CFloatingButton } from "@/app/common";
import { DesktopNavbar } from "@/app/components/Desktop";
import { MobileNavbar } from "@/app/components/Mobile";
import { ContactUsLayout } from "@/app/layouts/Desktop";
import { MobileSearchContainer } from "@/app/layouts/Mobile";
import { SvgFloatButton } from "@/assets/svg";
import { useMobileDimensions, useScrollToTop } from "@/hooks";
import { Button, Flex, Typography } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();
  const { isMobile } = useMobileDimensions();
  const [showContactUs, setShowContactUs] = useState(false);
  const [showSearchContainer, setShowSearchContainer] = useState(false);

  useScrollToTop();

  return (
    <>
      {isMobile ? (
        <>
          <MobileSearchContainer
            showSearchContainer={showSearchContainer}
            setShowSearchContainer={setShowSearchContainer}
          />
          <div style={{ paddingInline: 16 }}>
            <MobileNavbar setShowSearchContainer={setShowSearchContainer} />
          </div>
        </>
      ) : (
        <DesktopNavbar />
      )}
      <Flex vertical style={{ height: "100vh", width: "100%" }} align="center" justify="center">
        <Typography.Text
          style={{
            fontSize: isMobile ? 100 : 200,
            color: "#181818",
            fontWeight: "400",
            lineHeight: "220px",
          }}>
          404
        </Typography.Text>
        <Typography.Text
          style={{
            paddingInline: isMobile ? 16 : 91,
            fontSize: isMobile ? 16 : 24,
            fontWeight: "400",
            lineHeight: isMobile ? "32px" : "54px",
            color: "#181818",
            textAlign: "center",
          }}>
          အဆင်မပြေမှုအတွက် ဦးစွာ တောင်းပန်ပါတယ်။ မိတ်ဆွေ ရှာဖွေနေတဲ့ စာမျက်နှာဟာ
          ရွှေ့ပြောင်းခဲ့ခြင်း သို့မဟုတ် ဖယ်ရှားခဲ့ခြင်း ဖြစ်နိုင်ပါတယ်။
        </Typography.Text>
        <Button
          onClick={() => navigate("/")}
          type="primary"
          style={{
            backgroundColor: "#18181B",
            borderRadius: 100,
            padding: "24px 32px",
            fontSize: isMobile ? 12 : 24,
            fontWeight: "400",
            lineHeight: "16px",
            color: "#fff",
            marginTop: 40,
          }}>
          ပင်မစာမျက်နှာသို့ ပြန်သွားမည်
        </Button>
      </Flex>
      <CFloatingButton onClick={() => setShowContactUs(true)} icon={<SvgFloatButton />} />
      <ContactUsLayout open={showContactUs} setOpen={setShowContactUs} />
    </>
  );
};

export default NotFoundPage;
