import { breakpoints } from "@/constant/ui-constant";
import styled, { css } from "styled-components";

interface StyledProps {
  mstyle?: {
    [key: string]: string;
  };
}
export const BackgroundBlur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, #e0eafc 0%, #cfdef3 50%, #e0eafc 75%, #fbc2eb 100%);
  filter: blur(8px);
  width: 100%;
  height: 100%;
`;

export const View = styled.div<StyledProps>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  ${({ mstyle }) =>
    mstyle &&
    css`
      @media only screen and (max-width: ${breakpoints.mobile}px) {
        ${Object.entries(mstyle)
          .map(([key, value]) => {
            const cssProperty = key.replace(/[A-Z]/g, "-$&").toLowerCase();
            return `${cssProperty}: ${value} !important;`;
          })
          .join("\n")}
      }
    `}
`;

export const ScrollView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`;

export const MainComponentWrapper = styled.div`
  width: 100vw;
  height: 100vh;

  @media only screen and (max-width: ${breakpoints.mobile}px) {
    overflow-y: hidden;
  }
`;

export const BodyWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
`;

export const Text = styled.div<{
  min?: number;
  max?: number;
  size?: number;
  hoverColor?: string;
  color?: string;
  fontWeight?: number | string;
  fontFamily?: string;
  lineHeight?: number;
}>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  line-height: ${({ lineHeight }) => `${lineHeight}px` || "22px"};
  color: #000;
  font-family: ${({ fontFamily }) =>
    fontFamily || "'Noto Sans KR', 'Noto Sans Korean', 'Nanum Gothic', sans-serif"};
  font-weight: ${({ fontWeight }) => fontWeight || "normal"};
  /* color: ${({ color, theme }) => (color ? color : theme.title)}; */
  &:hover {
    ${({ hoverColor }) => hoverColor && `color: ${hoverColor} !important;`}
  }
  ${({ min, max }) =>
    min &&
    max &&
    `
        @media only screen and (max-width: 720px) {
           font-size: ${min}px;
        }
        @media only screen and (min-width: 720px) {
           font-size: ${max}px;
        }
     
   `}

  ${({ size }) =>
    size &&
    `
        @media only screen and (max-width: 600px) {
           font-size: ${size - 4}px;
        }
        @media only screen and (min-width: 600px) {
           font-size: ${size - 3}px;
        }
        @media only screen and (min-width: 768px) {
           font-size: ${size - 2}px;
        }
        @media only screen and (min-width: 992px) {
           font-size: ${size - 1}px;
        }
        @media only screen and (min-width: 1200px) {
           font-size: ${size}px;
        }
   `}
`;

export const TextOneLine = styled.div<{
  size?: number;
  hoverColor?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-family: "Roboto";
  color: ${props => props.theme.COLORS.textw};
  white-space: nowrap; /* 텍스트 줄 바꿈 방지 */
  overflow: hidden; /* 넘치는 텍스트 숨김 */
  text-overflow: ellipsis; /* 넘치는 텍스트를 ...으로 대체 */

  &:hover {
    ${({ hoverColor }) => hoverColor && `color: ${hoverColor} !important;`}
  }

  ${({ size }) =>
    size &&
    `
        @media only screen and (max-width: 370px) {
           font-size: ${size - 5}px;
        }
        @media only screen and (min-width: 600px) {
           font-size: ${size - 3}px;
        }
        @media only screen and (min-width: 768px) {
           font-size: ${size - 2}px;
        }
        @media only screen and (min-width: 992px) {
           font-size: ${size - 1}px;
        }
        @media only screen and (min-width: 1200px) {
           font-size: ${size}px;
        }
   `}
`;

export const PageNavbar = styled.div`
  width: 100%;
  margin-inline: auto;
  padding-bottom: 20px;
  background-color: #fff;
  @media only screen and (max-width: ${breakpoints.mobile}px) {
    padding-inline: 16px;
  }
`;

export const PageFilterWrapper = styled.div`
  max-width: 400px;
  margin-inline: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  @media only screen and (max-width: ${breakpoints.mobile}px) {
  }
`;

export const CardItem = styled.div`
  width: 100%;
  border: 1px solid #eee;
  padding-top: 10px;
  padding-inline: 14px;
  background-color: #fff;
  border-radius: 6px;
  padding-bottom: 10px;
  margin-bottom: 16px;
`;

export const Status = styled.div`
  border-radius: 4px;
  padding-inline: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
`;

export const DetailsNavbar = styled.div`
  width: 100%;
  margin-inline: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fff;
  @media only screen and (max-width: ${breakpoints.mobile}px) {
    padding-inline: 16px;
  }
`;

export const PageCardContainer = styled.div`
  width: 100%;
  background-color: #fff;
  margin-bottom: 6px;
  @media only screen and (max-width: ${breakpoints.mobile}px) {
    padding-inline: 16px;
  }
`;

export const PageForm = styled.form`
  max-width: 400px;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  @media only screen and (max-width: ${breakpoints.mobile}px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export const PageFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
`;

export const PageModalCard = styled.div`
  width: 400px;
  height: 259px;
  @media only screen and (max-width: ${breakpoints.mobile}px) {
    width: 85vw;
    height: 35vh;
  }
`;
export const SectionWrapper = styled.div`
  padding-inline: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;

  @media only screen and (max-width: ${breakpoints.mobile}px) {
    padding-inline: 16px;
  }
`;
