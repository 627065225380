import { ServiceByCategoryPage, ServicesPage } from "@/app/pages";
import React from "react";
import { useLocation } from "react-router-dom";

const ServicesRoute = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const parentId = searchParams.get("parentId");
  const title = searchParams.get("title");
  const description = searchParams.get("description");
  if (parentId && title) {
    return <ServiceByCategoryPage parentId={parentId} title={title} description={description} />;
  } else {
    return <ServicesPage />;
  }
};

export default ServicesRoute;
