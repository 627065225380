import { CustomHeader, DesktopBlogLayout } from "@/app/components/Desktop";
import { BlogDetails, ServiceCard } from "@/app/entities";
import { useDesktopDimensions, useMobileDimensions } from "@/hooks";
import { useApiQuery } from "@/server/api-fetch";
import { GET_ALL_BLOGS, GET_BLOG } from "@/server/apis";
import { Flex, Typography } from "antd";
import React from "react";

const BlogDetail = ({ title, serviceData }) => {
  const { isMobile } = useMobileDimensions();
  const { isDesktop } = useDesktopDimensions();
  const queryParams = new URLSearchParams(window.location.search);
  const blogId = queryParams.get("id");

  const { data: getBlog, isLoading: getBlogLoading } = useApiQuery({
    url: `${GET_BLOG}?blogId=${blogId}`,
  });
  const { data } = useApiQuery({
    url: `${GET_ALL_BLOGS}?limit=${4}&offset=${0}&category=${getBlog?.category}`,
  });

  return (
    <div>
      <CustomHeader maintitle={"ဆောင်းပါးများ"} type={"blog"} subtitles={[title]} chevIcon />
      <Flex gap={32}>
        <BlogDetails data={getBlog} loading={getBlogLoading} />
        {!isMobile && (
          <Flex vertical style={{ width: isDesktop ? 216 : 416, gap: 40 }}>
            <Flex gap={12}>
              <div style={{ width: "6px", height: "32px", backgroundColor: "#9D9DAA" }}></div>
              <Typography.Text
                style={{ fontSize: 24, fontWeight: "500", color: "#18181B", lineHeight: "32px" }}>
                ဝန်ဆောင်မှုများ
              </Typography.Text>
            </Flex>
            <Flex vertical gap={32}>
              {serviceData?.slice(0, 2)?.map((item, index) => (
                <ServiceCard key={index} item={item} />
              ))}
            </Flex>
          </Flex>
        )}
      </Flex>
      <div style={{ marginBottom: isMobile ? 32 : 72, marginTop: isMobile ? 64 : 100 }}>
        <Typography.Text
          style={{
            color: "#18181B",
            fontSize: isMobile ? 12 : 32,
            fontWeight: "500",
            lineHeight: isMobile ? "19.2px" : "40px",
          }}>
          ဆက်စပ်နေသော ဆောင်းပါးများ
        </Typography.Text>
      </div>
      <DesktopBlogLayout noLoadMore={true} data={data} />
    </div>
  );
};

export default BlogDetail;
