import { Flex, List } from "antd";
import React, { useState } from "react";
import { StyledTitle } from "./styles";
import { PlusOutlined } from "@ant-design/icons";
import { ServiceTypeCard } from "@/app/entities";
import { useMobileDimensions } from "@/hooks";
import { ServiceFormModal } from "@/app/modal";
import Button from "antd/es/button";
import { useApiQuery } from "@/server/api-fetch";
import { GET_SERVICE_BY_PARENT_ID } from "@/server/apis";
import { ServiceDetailForm, ServiceFormComplete } from "@/app/layouts/Desktop";
import { Text, View } from "@/styles/reactnative";

const ServiceTypesList = ({ parentId, title, servicePaths }) => {
  const { data, isLoading } = useApiQuery({
    url: `${GET_SERVICE_BY_PARENT_ID}?parentId=${parentId || ""}`,
  });

  const { isMobile } = useMobileDimensions();
  const [openModal, setOpenModal] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  return (
    <>
      {data?.length ? (
        <Flex vertical gap={64}>
          <Flex align="center" justify="space-between">
            <StyledTitle $isMobile={isMobile}>
              {`${title}နှင့်ပတ်သက်သော ဝန်ဆောင်မှုများ` ||
                "ရရှိနိုင်သော ဝန်ဆောင်မှု အမျိုးအစားများ"}
            </StyledTitle>
            <Button
              type="text"
              style={{ color: "#485AFF", fontSize: isMobile ? 10 : 16, fontWeight: 500 }}
              icon={<PlusOutlined style={{ fontSize: isMobile ? 10 : 20 }} />}
              onClick={() => setOpenModal(true)}>
              ကိုယ်တိုင် ဖြည့်စွက်မည်
            </Button>
          </Flex>
          <List
            itemLayout="vertical"
            loading={isLoading}
            dataSource={data}
            renderItem={(item: any, index) => (
              <ServiceTypeCard
                item={item}
                key={item?._id}
                style={{
                  marginRight: isMobile ? "0px" : index % 2 === 0 ? "24px" : "0px",
                  marginBottom: 24,
                }}
              />
            )}
            grid={{
              xs: 1,
              sm: 1,
              md: 2,
              lg: 2,
              xl: 2,
              xxl: 2,
            }}
          />
          <ServiceFormModal visible={openModal} setVisible={setOpenModal} />
        </Flex>
      ) : isLoading ? (
        <View>
          <Text>Loading...</Text>
        </View>
      ) : (
        <>
          {isCompleted ? (
            <ServiceFormComplete />
          ) : (
            <ServiceDetailForm setIsCompleted={setIsCompleted} servicePaths={servicePaths} />
          )}
        </>
      )}
    </>
  );
};

export default ServiceTypesList;
