import { useMobileDimensions, useScrollToTop } from "@/hooks";
import React, { useState } from "react";
import MobileView from "./MobileView";
import DesktopView from "./DesktopView";
import { CFloatingButton } from "@/app/common";
import { ContactUsLayout } from "@/app/layouts/Desktop";
import { SvgFloatButton } from "@/assets/svg";

const CategoriesPage = () => {
  const { isMobile } = useMobileDimensions();
  const [showContactUs, setShowContactUs] = useState(false);
  useScrollToTop();
  return (
    <>
      {isMobile ? <MobileView /> : <DesktopView />}
      <CFloatingButton onClick={() => setShowContactUs(true)} icon={<SvgFloatButton />} />
      <ContactUsLayout open={showContactUs} setOpen={setShowContactUs} />
    </>
  );
};

export default CategoriesPage;
