import { Text } from "@/styles/reactnative";
import styled from "styled-components";

export const StyledMessage = styled.div<{ $isSender: boolean }>`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: ${props => (props.$isSender ? "row-reverse" : "row")};
  padding-left: ${props => (props?.$isSender ? "0px" : "55px")};
  padding-right: ${props => (props?.$isSender ? "55px" : "0px")};
`;

export const StyledMessageText = styled.p<{ $isSender: boolean }>`
  max-width: 382px;
  padding: 12px 16px;
  border-radius: ${props => (props?.$isSender ? "8px" : "0px")}
    ${props => (props?.$isSender ? "0px" : "8px")} 8px 8px;
  background-color: #f5f4f9;
  color: #212121;
  font-weight: 400;
  font-size: 16px;
  line-height: 25.6px;
`;

export const StyledProfileName = styled(Text)`
  color: #25324b;
  font-size: 16px;
  font-weight: 600;
  line-height: 25.6px;
`;

export const StyledTimeStamp = styled(Text)<{ $isSender: boolean }>`
  color: #7c8493;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: ${props => (props?.$isSender ? "right" : "left")};
  padding-left: ${props => (props?.$isSender ? "0px" : "55px")};
  padding-right: ${props => (props?.$isSender ? "55px" : "0px")};
`;
