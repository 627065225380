import React, { useEffect, useState } from "react";
import { Marker, NavbarContainer, NavbarText, NavbarTitle } from "./styles";
import { SectionWrapper } from "@/app/hoc";
import { motion } from "framer-motion";
import { textVariant } from "@/utils/motion";
import { useLocation, useNavigate } from "react-router-dom";
import BlogSearchBar from "../BlogSearchBar";
import { useDesktopDimensions } from "@/hooks";
import { Flex, message } from "antd";
import { useApiCreate } from "@/server/api-fetch";
import { CREATE_DUMMY_BLOG } from "@/server/apis";

const DesktopNavbar = () => {
  const navigate = useNavigate();
  const { isDesktop } = useDesktopDimensions();

  const location = useLocation();
  const path = location.pathname;
  const [route, setRoute] = useState("");

  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  // const handleScroll = () => {
  //   const currentScrollY = window.scrollY;

  //   if (currentScrollY > lastScrollY) {
  //     // Scrolling down
  //     setShowNavbar(false);
  //   } else if (currentScrollY < lastScrollY) {
  //     // Scrolling up
  //     setShowNavbar(true);
  //   }

  //   setLastScrollY(currentScrollY);
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [lastScrollY]);

  useEffect(() => {
    if (path.includes("blogs")) {
      setRoute("blogs");
    } else if (path.includes("services")) {
      setRoute("services");
    } else if (path.includes("products")) {
      setRoute("products");
    }
  }, [path]);
  // const { mutate } = useApiCreate({
  //   url: CREATE_DUMMY_BLOG,
  //   action: data => {
  //     if (data?.success) {
  //       message.success(data?.message || "Blog created successfully");
  //     } else {
  //       message.error(data?.message || "Failed to create blog");
  //     }
  //   },
  // });

  return (
    <motion.div
      variants={textVariant()}
      style={{
        position: "fixed",
        top: showNavbar ? 0 : "-80px",
        transition: "top 0.3s ease-in-out",
        width: "100%",
        zIndex: 1000,
        backgroundColor: "#fff",
      }}>
      <NavbarContainer>
        <Flex align="center" gap={32}>
          <Flex onClick={() => navigate("/")} style={{ cursor: "pointer", width: 150 }}>
            <NavbarTitle style={{ color: "#485AFF" }}>ULSAN</NavbarTitle>
            <NavbarTitle style={{ color: "#181818", marginLeft: 3 }}>CHAM</NavbarTitle>
          </Flex>
          <Flex gap={32} style={{ paddingInline: 24 }}>
            <Flex vertical align="center" justify="center" gap={4}>
              <NavbarText onClick={() => navigate("/products")}>စျေးဆိုင်</NavbarText>
              {route === "products" && <Marker />}
            </Flex>
            <Flex vertical align="center" justify="center" gap={4}>
              <NavbarText onClick={() => navigate("/blogs")}>ဆောင်းပါးများ</NavbarText>
              {route === "blogs" && <Marker />}
            </Flex>
            <Flex vertical align="center" justify="center" gap={4}>
              <NavbarText onClick={() => navigate("/services")}>ဝန်ဆောင်မှုများ</NavbarText>
              {route === "services" && <Marker />}
            </Flex>
          </Flex>
        </Flex>
      </NavbarContainer>
      <BlogSearchBar
        placeholder={"သိလိုသည်များကို ရှာဖွေမည်"}
        vStyle={{ position: "absolute", right: "24px", top: "18px", width: isDesktop ? 254 : 554 }}
      />
    </motion.div>
  );
};

export default SectionWrapper(DesktopNavbar, "navbar");
