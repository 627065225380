import React, { StrictMode } from 'react'
import ReactDOM from "react-dom/client";
import "./styles/global.css"
import AppProvider from './AppProvider'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <AppProvider />
  </StrictMode>,
)
