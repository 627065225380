import { CloseOutlined } from "@ant-design/icons";
import { Button, Carousel, Tag, Typography } from "antd";
import styled from "styled-components";

export const StyledText = styled(Typography.Text)<{ $isMobile: boolean }>`
  color: #000;
  font-size: ${props => (props?.$isMobile ? "12px" : "16px")};
  font-weight: 500;
  line-height: 24px;
`;

export const StyledTitle = styled(Typography.Text)<{ $isMobile: boolean }>`
  color: #000;
  font-size: ${props => (props?.$isMobile ? "16px" : "24px")};
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  margin-bottom: 32px;
`;

export const StyledPrice = styled(Typography.Text)<{ $isMobile: boolean }>`
  color: #485aff;
  font-size: ${props => (props?.$isMobile ? "20px" : "32px")};
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 24px;
`;

export const StyledDetailTitle = styled(Typography.Text)`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.32px;
  margin-bottom: 16px;
`;

export const StyledParagraph = styled(Typography.Paragraph)<{ $isMobile: boolean }>`
  color: #4d4d4d;
  font-size: ${props => (props?.$isMobile ? "12px" : "14px")};
  font-weight: 500;
  line-height: 24px;
`;

export const StyledButton = styled(Button)<{ $isMobile: boolean }>`
  width: 100%;
  height: ${props => (props?.$isMobile ? "48px" : "64px")};
  margin-top: ${props => (props?.$isMobile ? "48px" : "64px")};
  border-radius: 0px;
  color: #fff;
  font-size: ${props => (props?.$isMobile ? "14px" : "20px")};
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
`;

export const StyledTag = styled(Tag)`
  position: absolute;
  top: 16px;
  left: 16px;
  padding: 12px 24px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

export const StyledCarousel = styled(Carousel)`
  position: fixed;
  bottom: 0px;
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: 9999;
  display: flex;

  .slick-slide {
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .slick-prev, .slick-next {
    opacity: 1;
    color: transparent;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    z-index: 1000;
  }

  .slick-prev {
    left: 15px;
  }

  .slick-next {
    right: 15px;
  }
`

export const CloseCarousel = styled(CloseOutlined)`
  font-size: 20px;
  position: fixed;
  top: 30px;
  right: 30px;
  cursor: pointer;
  z-index: 10000;
`