import { BlogLayoutHeader, DesktopBlogLayout } from "@/app/components/Desktop";
import { SectionWrapper } from "@/app/hoc";
import { useMobileDimensions } from "@/hooks";
import { useApiQuery } from "@/server/api-fetch";
import { GET_HIGHTLIGHT_BLOGS } from "@/server/apis";
import { textVariant } from "@/utils/motion";
import { motion } from "framer-motion";
import React from "react";

const PopularBlogsSection = ({ title }) => {
  const { isMobile } = useMobileDimensions();
  const { data, isLoading } = useApiQuery({
    url: `${GET_HIGHTLIGHT_BLOGS}?limit=10&visibility=GLOBAL`,
  });

  return (
    <motion.div variants={textVariant()} style={{ marginTop: isMobile ? 66 : 120 }}>
      <BlogLayoutHeader title={title} href={"/blogs"} isViewAll={false} />
      <DesktopBlogLayout
        data={{
          blogs: data,
        }}
        noLoadMore={true}
        isLoading={isLoading}
      />  
    </motion.div>
  );
};

export default SectionWrapper(PopularBlogsSection, "popularBlogSection");
