import { CImage } from "@/app/common";
import dummyBlogs from "@/data/dummyBlogs";
import { Flex } from "antd";
import React from "react";
import { BlogCard, BlogDescription, BlogTitle } from "./styles";
import { SectionWrapper } from "@/app/hoc";
import { motion } from "framer-motion";
import { textVariant } from "@/utils/motion";
import { useNavigate } from "react-router-dom";

const MobileSampleBlogs = () => {
  const navigate = useNavigate();
  return (
    <motion.div variants={textVariant()}>
      <Flex vertical gap={40} style={{ marginTop: 32 }}>
        {dummyBlogs.slice(0, 2).map((item, index) => (
          <BlogCard key={index} style={{cursor: 'pointer'}}>
            <CImage src={item?.img} width={"100%"} height={207} style={{ borderRadius: 12 }} />
            <BlogTitle onClick={() => navigate(`/blogs?id=${item?._id}&title=${item?.title}`)}>
              {item?.title}
            </BlogTitle>
            <BlogDescription
              onClick={() => navigate(`/blogs?id=${item?._id}&title=${item?.title}`)}
              ellipsis={{ rows: 4, expandable: false }}>
              {item?.description}
            </BlogDescription>
          </BlogCard>
        ))}
      </Flex>
    </motion.div>
  );
};

export default SectionWrapper(MobileSampleBlogs, "mobileSampleBlogs");
