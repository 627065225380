import { Button, Input } from "antd";
import styled from "styled-components";

export const StyledInput = styled(Input)<{ $isMobile: boolean }>`
  height: ${props => (props?.$isMobile ? "48px" : "64px")};
  padding-block: ${props => (props?.$isMobile ? "10px" : "18px")};
  padding-inline: ${props => (props?.$isMobile ? "16px" : "24px")};
  font-size: ${props => (props?.$isMobile ? "12px" : "20px")};
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`;

export const StyledButton = styled(Button)<{ $isMobile: boolean }>`
  width: 100%;
  height: ${props => (props?.$isMobile ? "48px" : "72px")};
  padding-block: ${props => (props?.$isMobile ? "10px" : "20px")};
  font-size: ${props => (props?.$isMobile ? "12px" : "20px")};
  font-style: normal;
  font-weight: 500;
  line-height: ${props => (props?.$isMobile ? "28px" : "32px")};
  border: none;
`;