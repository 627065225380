import { CategoryCard } from "@/app/entities";
import { useMobileDimensions } from "@/hooks";
import React from "react";
import { CategoryGrid } from "./styles";
import { useApiQuery } from "@/server/api-fetch";
import { GET_CATEGORIES } from "@/server/apis/categories";

const CategoriesList = () => {
  const { isMobile } = useMobileDimensions();
  const { data } = useApiQuery({
    url: `${GET_CATEGORIES}?type=blog`,
  });
  return (
    <CategoryGrid $isMobile={isMobile}>
      {data?.map?.((category, index) => (
        <CategoryCard category={category} key={index} />
      ))}
    </CategoryGrid>
  );
};

export default CategoriesList;
