import { BlogDetailPage, BlogsPage } from "@/app/pages";
import React from "react";
import { useLocation } from "react-router-dom";

const BlogsRoute = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const title = searchParams.get("title");

  if (id) {
    return <BlogDetailPage id={id} title={title} />;
  }
  return <BlogsPage />;
};

export default BlogsRoute;
