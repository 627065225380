import { Typography } from "antd";
import styled from "styled-components";

export const StyledTitle = styled(Typography.Text)<{ $isMobile: boolean }>`
  color: #000;
  font-size: ${props => (props?.$isMobile ? "16px" : "32px")};
  font-style: normal;
  font-weight: 500;
  line-height: ${props => (props?.$isMobile ? "25.6px" : "44px")}; /* 137.5% */
`;

export const StyledSubtitle = styled(Typography.Text)<{ $isMobile: boolean }>`
  color: #000;
  font-size: ${props => (props?.$isMobile ? "16px" : "24px")};
  font-style: normal;
  font-weight: 500;
  line-height: ${props => (props?.$isMobile ? "25.6px" : "32px")};
`;

export const StyledParagraph = styled(Typography.Paragraph)<{ $isMobile: boolean }>`
  color: #4e4c4c;
  font-size: ${props => (props?.$isMobile ? "12px" : "16px")};
  font-style: normal;
  font-weight: 400;
  line-height: ${props => (props?.$isMobile ? "24px" : "32px")}; /* 200% */
`;
