import React, { useState } from "react";
import DesktopView from "./DesktopView";
import { useMobileDimensions, useScrollToTop } from "@/hooks";
import MobileView from "./MobileView";
import { CFloatingButton } from "@/app/common";
import { ContactUsLayout } from "@/app/layouts/Desktop";
import { SvgFloatButton } from "@/assets/svg";
import { useSelector } from "react-redux";

const ServiceByCategoryPage = ({ parentId, title, description }) => {
  const { isMobile } = useMobileDimensions();
  const [showContactUs, setShowContactUs] = useState(false);
  useScrollToTop();
  const servicePaths = useSelector((state: any) => state.service?.path);
  return (
    <div>
      {isMobile ? (
        <MobileView
          parentId={parentId}
          title={title}
          description={description}
          servicePaths={servicePaths || []}
        />
      ) : (
        <DesktopView
          parentId={parentId}
          title={title}
          description={description}
          servicePaths={servicePaths || []}
        />
      )}
      <CFloatingButton onClick={() => setShowContactUs(true)} icon={<SvgFloatButton />} />
      <ContactUsLayout open={showContactUs} setOpen={setShowContactUs} />
    </div>
  );
};

export default ServiceByCategoryPage;
