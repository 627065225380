import React from "react";
import { Empty as AntdEmtpy, EmptyProps } from "antd";
import styled from "styled-components";

const StyledEmpty = styled.div<{ $height?: string }>`
  width: 100%;
  height: ${props => (props.$height ? props.$height : "60vh")};
  display: grid;
  place-content: center;
`;

const CEmpty = ({ height, children, ...props }: { height?: string } & EmptyProps) => {
  return (
    <StyledEmpty $height={height}>
      <AntdEmtpy {...props}>{children}</AntdEmtpy>
    </StyledEmpty>
  );
};

export default CEmpty;
