import React from "react";
import { HeroContainer, HeroDescription, HeroImage, HeroTitle } from "./styles";
import { images } from "@/assets/images";
import { motion } from "framer-motion";
import CountUp from "react-countup";
import { fadeIn } from "@/utils/motion";
import { SectionWrapper } from "@/app/hoc";
import { FONTS } from "@/styles/styles";
import { useDesktopDimensions } from "@/hooks";
import { Text } from "@/styles/reactnative";
import WrapperStatistic, { StatisticProps } from "antd/lib/statistic/Statistic";
import { Flex } from "antd";

const formatter: StatisticProps["formatter"] = value => (
  <>
    <CountUp end={value as number} separator="," />+
  </>
);

const HeroSection = () => {
  const { isDesktop } = useDesktopDimensions();
  // const isDesktop = false
  return (
    <HeroContainer style={{ gap: 30 }}>
      <motion.div variants={fadeIn("right", "spring", 0.5)}>
        <HeroImage src={images.home_page_img} />
      </motion.div>

      <motion.div variants={fadeIn("left", "spring", 0.5)}>
        <Flex style={{ maxWidth: isDesktop ? 436 : 636 }} vertical>
          <HeroTitle $isDesktop={isDesktop}>
            Start Adventure with{" "}
            <HeroTitle $isDesktop={isDesktop} style={{ color: "#485AFF" }}>
              Ulsan Cham
            </HeroTitle>
          </HeroTitle>
          <HeroDescription>
            ကျွမ်းကျင်သူများ၏ အကြုံပြုချက်များ၊ ဝန်ဆောင်မှုများအပြင် ဗဟုသုတရစရာ
            အကျိုးပြုဆောင်းပါးများစွာကို အလွယ်တကူ ရှာဖွေ ဖတ်ရှုလို့ ရနိုင်ပါပြီ။
          </HeroDescription>
          <Flex gap={32} style={{ marginTop: 48 }}>
            <img src={images.play_store} style={{ cursor: "pointer" }} />
            <img src={images.app_store} style={{ cursor: "pointer" }} />
          </Flex>
          <Flex gap={53} style={{ marginTop: 48 }}>
            <Flex vertical gap={10}>
              <WrapperStatistic
                value={7193}
                formatter={formatter}
                style={{
                  ...FONTS.h2,
                  fontWeight: 700,
                  letterSpacing: "-0.5px",
                  lineHeight: "38px",
                  color: "#0F172A",
                }}
              />
              <Text
                style={{
                  ...FONTS.h5,
                  fontWeight: 500,
                  color: "#4B5262",
                  maxWidth: 240,
                  borderRight: "1px solid #E5EAF1",
                  paddingRight: 27.12,
                }}>
                အသုံးပြုသူပေါင်းများစွာ နေ့စဉ်နှင့်အမျှ တိုးတက်နေပါသည်။
              </Text>
            </Flex>
            <Flex vertical gap={10}>
              <WrapperStatistic
                style={{
                  ...FONTS.h2,
                  fontWeight: 700,
                  letterSpacing: "-0.5px",
                  lineHeight: "38px",
                  color: "#0F172A",
                }}
                value={540}
                formatter={formatter}
              />
              <Text
                style={{
                  ...FONTS.h5,
                  fontWeight: 500,
                  color: "#4B5262",
                  maxWidth: 343,
                }}>
                ဝန်ဆောင်မှုများကို ရယူပြီး စိတ်ကျေနပ်မှုများ ရရှိသွားသော သုံးစွဲသူများလဲ ရှိနေပြီး
                ဖြစ်ပါသည်။
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </motion.div>
    </HeroContainer>
  );
};

export default SectionWrapper(HeroSection, "heroSection");
