import { Input, Typography } from "antd";
import styled from "styled-components";

export const Container = styled.div<{ $show: boolean }>`
  width: 97%;
  top: ${props => (props?.$show ? 0 : "-80px")};
  left: 5px;
  position: fixed;
  background-color: #fff;
  z-index: 1500;
  transition: top 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  align-items: center;
  padding-inline: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const SearchBarContainer = styled.div<{ $show: boolean }>`
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding-inline: 16px;
  background-color: #f1f2f7;
  border-radius: 8px;
  padding: 10px 16px;
  border-bottom-left-radius: ${props => (props?.$show ? "0px" : "8px")};
  border-bottom-right-radius: ${props => (props?.$show ? "0px" : "8px")};
`;

export const SearchItemsContainer = styled.div`
  width: 100%;
  min-height: 170px;
  background-color: #f1f2f7;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 26px 16px;
`;

export const StyledInput = styled(Input)`
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0.24px;
`;

export const StyledSearchedText = styled(Typography.Text)`
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.24px;
`;
