import styled from "styled-components";

export const Container = styled.div<{ $isMobile: boolean }>`
  width: 98%;
  height: ${props => (props?.$isMobile ? "191px" : "274px")};
  padding: ${props => (props?.$isMobile ? "16px" : "24px")};
  display: flex;
  flex-direction: column;
  gap: ${props => (props?.$isMobile ? "12px" : "16px")};
  border: 1px solid #d7d7ea;
  justify-content: space-between;
  border-radius: 12px;
`;
