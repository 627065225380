import React from "react";
import { HeroContainer, HeroDescription, HeroImage, HeroTitle } from "./styles";
import { Flex, Statistic, StatisticProps, Typography } from "antd";
import { images } from "@/assets/images";
import { CImage } from "@/app/common";
import CountUp from "react-countup";
import { motion } from "framer-motion";
import { fadeIn, textVariant } from "@/utils/motion";
import { SectionWrapper } from "@/app/hoc";

const formatter: StatisticProps["formatter"] = value => (
  <>
    <CountUp end={value as number} separator="," />+
  </>
);

const MobileHeroSection = () => {
  return (
    <HeroContainer>
      <motion.div variants={fadeIn("right", "spring", 0.5)}>
        <Flex vertical>
          <HeroTitle>Start Adventure with</HeroTitle>
          <HeroTitle style={{ color: "#485AFF" }}>Ulsan Cham</HeroTitle>
        </Flex>
      </motion.div>
      <motion.div variants={fadeIn("left", "spring", 0.5)}>
        <HeroImage src={images.home_page_img} preview={false} />
      </motion.div>
      <motion.div variants={textVariant()}>
        <HeroDescription>
          ကျွမ်းကျင်သူများ၏ အကြုံပြုချက်များ၊ ဝန်ဆောင်မှုများအပြင် ဗဟုသုတရစရာ
          အကျိုးပြုဆောင်းပါးများစွာကို အလွယ်တကူ ရှာဖွေ ဖတ်ရှုလို့ ရနိုင်ပါပြီ။
        </HeroDescription>
      </motion.div>
      <motion.div variants={textVariant()}>
        <Flex gap={32}>
          <CImage src={images.play_store} preview={false} style={{ cursor: "pointer" }} />
          <CImage src={images.app_store} preview={false} style={{ cursor: "pointer" }} />
        </Flex>
      </motion.div>
      <motion.div variants={textVariant()}>
        <Flex style={{ width: "100%", paddingLeft: 16, justifyContent: "space-between" }}>
          <Flex style={{ width: 173 }} vertical gap={8}>
            <Statistic
              value={7193}
              formatter={formatter}
              style={{
                fontSize: 20,
                fontWeight: 700,
                letterSpacing: -0.5,
                color: "var(--Blue-900, #0F172A)",
              }}
            />
            <Typography.Text
              style={{
                fontSize: 12,
                fontWeight: 400,
                maxWidth: 150,
                color: "var(--Blue-600, #4B5262)",
              }}>
              အသုံးပြုသူပေါင်းများစွာ နေ့စဉ်နှင့်အမျှ တိုးတက်နေပါသည်။
            </Typography.Text>
          </Flex>
          <Flex style={{ width: 173 }} vertical gap={8}>
            <Statistic
              style={{
                fontSize: 20,
                fontWeight: 700,
                letterSpacing: -0.5,
                color: "var(--Blue-900, #0F172A)",
              }}
              value={540}
              formatter={formatter}
            />
            <Typography.Text
              style={{
                fontSize: 12,
                fontWeight: 400,
                color: "var(--Blue-600, #4B5262)",
              }}>
              ဝန်ဆောင်မှုများကို ရယူပြီး စိတ်ကျေနပ်မှုများ ရရှိသွားသော သုံးစွဲသူများလဲ ရှိနေပြီး
              ဖြစ်ပါသည်။
            </Typography.Text>
          </Flex>
        </Flex>
      </motion.div>
    </HeroContainer>
  );
};

export default SectionWrapper(MobileHeroSection, "mobileHeroSection");
