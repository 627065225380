import { Flex, Typography } from "antd";
import styled from "styled-components";

export const ProductContainer = styled(Flex)<{ $isMobile: boolean }>`
  flex-direction: column;
  gap: ${props => (props?.$isMobile ? "32px" : "40px")};
  margin-top: ${props => (props?.$isMobile ? "68px" : "96px")};
`;

export const ProductTitle = styled(Typography.Text)<{ $isMobile: boolean }>`
  font-size: ${props => (props?.$isMobile ? "12px" : "24px")};
  font-weight: 500;
`;

export const GridContainer = styled.div<{ $isMobile: boolean }>`
  display: grid;
  grid-template-columns: ${({ $isMobile }) => ($isMobile ? "repeat(2, 1fr)" : "repeat(5, 1fr)")};
  gap: 15px;
  align-items: center;

  ${({ $isMobile }) =>
    $isMobile &&
    `
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
  `}
`;
