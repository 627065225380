import styled from "styled-components";
import Button from 'antd/es/button';

export const Container = styled.div<{ $isMobile: boolean }>`
  width: 100%;
  height: ${props => (props?.$isMobile ? "90%" : "100%")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: ${props => (props?.$isMobile ? "56px" : "80px")};
`;

export const StyledInput = styled.input<{ $isMobile: boolean }>`
  font-size: ${props => (props?.$isMobile ? "14px" : "20px")};
  font-weight: 400;
  line-height: 28px;
  flex: 1;
  height: 100%;
  padding-block: ${props => (props?.$isMobile ? "8px" : "18px")};
  padding-inline: ${props => (props?.$isMobile ? "16px" : "24px")};
  background-color: transparent;
  /* background-color: brown; */
  border: 0px;
  outline: none;
  color: #000;
`;

export const StyledButton = styled(Button)<{ $isMobile: boolean }>`
  min-width: ${props => (props?.$isMobile ? "92px" : "184px")};
  height: 100%;
  padding: 20px ${props => (props?.$isMobile ? "10px" : "32px")};
  border-radius: 12px;
  font-weight: 500;
  line-height: 32px;
  border: none;
`;
