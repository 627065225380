import React from "react";
import { GridContainer, ProductContainer } from "./styles";
import { motion } from "framer-motion";
import { textVariant } from "@/utils/motion";
import { SectionWrapper } from "@/app/hoc";
import { BlogLayoutHeader } from "@/app/components/Desktop";
import { useMobileDimensions } from "@/hooks";
import { ProductCard } from "@/app/entities";
import { GET_ALL_PRODUCTS } from "@/server/apis";
import { useApiQuery } from "@/server/api-fetch";

const ProductSection = ({ title, category, featured }) => {
  const { isMobile } = useMobileDimensions();
  const { data } = useApiQuery({
    url: `${GET_ALL_PRODUCTS}?limit=${isMobile ? 4 : 5}&offset=${1}&category=${
      category === "ပစ္စည်းများအားလုံး" ? "" : category
    }&featured=${featured || ""}`,
  });
  const products = data?.products;

  return (
    <ProductContainer $isMobile={isMobile}>
      <motion.div variants={textVariant()}>
        <BlogLayoutHeader title={title} href="/products" />
      </motion.div>
      <motion.div variants={textVariant()}>
        <GridContainer $isMobile={isMobile}>
          {products?.map?.((item, index) => (
            <ProductCard key={index} item={item} />
          ))}
        </GridContainer>
      </motion.div>
    </ProductContainer>
  );
};

export default SectionWrapper(ProductSection, "productSection");
