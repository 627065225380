import { CImage, CText } from "@/app/common";
import React from "react";

const BlogDetailSectionContentImage = ({ item }) => {
  return (
    <>
      <CImage src={item?.text} width={"100%"} height={300} />
      <CText>
        {item?.description}
      </CText>
    </>
  );
};

export default BlogDetailSectionContentImage;
