import { Typography } from "antd";
import styled from "styled-components";

export const BlogCard = styled.div`
    width: 100%;
    height: 413px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export const BlogTitle = styled(Typography.Text)`
    font-size: 14px;
    font-weight: 500;
    color: '#090914';
    line-height: 22.4px;
`

export const BlogDescription = styled(Typography.Paragraph)`
    font-size: 10px;
    font-weight: 400;
    color: #4D4D4D;
    line-height: 20px;
`