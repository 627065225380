import { CFloatingButton } from "@/app/common";
import { CustomHeader, DesktopFooter, DesktopNavbar } from "@/app/components/Desktop";
import { MobileNavbar } from "@/app/components/Mobile";
import { ContactUsLayout } from "@/app/layouts/Desktop";
import { MobileSearchContainer } from "@/app/layouts/Mobile";
import { SvgFloatButton } from "@/assets/svg";
import { useMobileDimensions, useScrollToTop } from "@/hooks";
import { Flex, Typography } from "antd";
import React, { useState } from "react";

const PolicyPage = () => {
  const { isMobile } = useMobileDimensions();
  const [showContactUs, setShowContactUs] = useState(false);
  const [showSearchContainer, setShowSearchContainer] = useState(false);

  useScrollToTop();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "space-between",
      }}>
      {isMobile ? (
        <>
          <MobileSearchContainer
            showSearchContainer={showSearchContainer}
            setShowSearchContainer={setShowSearchContainer}
          />
          <div style={{ paddingInline: 16 }}>
            <MobileNavbar setShowSearchContainer={setShowSearchContainer} />
          </div>
        </>
      ) : (
        <DesktopNavbar />
      )}
      <Flex vertical style={{ marginTop: 130, paddingInline: isMobile ? 16 : 71 }}>
        <CustomHeader maintitle={"Ulsan Cham"} subtitles={["သတင်းအချက်အလတ် လုံခြုံမှု မူဝါဒ"]} />
        <Typography.Text
          style={{
            fontSize: isMobile ? 20 : 32,
            fontWeight: "600",
            color: "#000",
            lineHeight: "32px",
            marginBottom: isMobile ? 32 : 64,
          }}>
          ၁။ သတင်းအချက်အလတ် လုံခြုံမှု မှုဝါဒဆိုတာ ဘာလဲ။
        </Typography.Text>
        <Typography.Paragraph
          style={{ fontSize: isMobile ? 16 : 20, lineHeight: "48px", fontWeight: "400" }}>
          English ဘာသာ Lorem ipsum စာများသည် ယေဘုယျအားဖြင့် pseudo-Latin စကားလုံးများဖြင့်
          ဖွဲ့စည်းထားခြင်းဖြစ်ပါသည်။ ဒီဇိုင်းများကို နမူနာပြသရန် နှင့် ဒီဇိုင်၏ အရည်အသွေးကို
          စမ်းစစ်ရန် အတွက် placeholder text အနေဖြင့် ကျယ်ပြန့်စွာ အသုံပြုလျှက်ရှိပါသည်။
          စကားလုံးများတွင် အဓိပ္ပာယ် မရှိသည့်အားလျှောစွာ ဖတ်ရှုသူ အနေနဲ့ စာကို ဖတ်ရှုနေစရာ မလိုအပ်ပဲ
          ဒီဇိုင်းကို ပိုမို အာရုံစိုက်နိုင်ပါသည်။ ဘာသာစကား အသီးသီးတွင် Lorem ipsum
          များရှိသကဲ့သိုပင် ယခုမှာ မြန်မာ ဘာသာ အတွက် ဖြစ်ပါသည်။ <br /> <br /> ယခု စာမျက်နှာ သည်
          Lorem ipsum များကို မြန်မာ Version အနေနဲ့ ထုတ်ပေးထားခြင်းဖြစ်ပါသည်။ စာပိုဒ် (၅)
          ပိုဒ်ပါ၀င်ပြီး စာပိုဒ် တစ်ခုချင်းစီတွင် ၀ါကျ (၅) ခုမှ (၆) အထိပါ၀င်ပါသည်။ စာလုံးတိုင်းတွင်
          လူသုံးနည်းသော ပါဠိ စာတစ်၀က် နှင့် လူသုံးများသော မြန်မာစာ တစ်၀က် ပါ၀င် ပါသည်။ ပါ၀င်သော
          ပါဠိစာများသည် ပုံမှန် စာဖတ်သူများ အတွက် ဖတ်ရှုရန် ခက်ခဲသော စာများဖြစ်စေပါသည်။
          pseudo-sentence စာများကို generate လုပ်သည့် အဆင့် တစ်ဆင့်စီကို အောက်ပါစာပိုဒ်တွင်
          ဖော်ပြထားပါသည်။ <br /> <br /> ပါဠိစာအမှန် (၁၂) ကြောင်း နှင့် မြန်မာ စာအမှန် (၁၂) ကြောင်း၏
          ဝဏ္ဏ syllable များကို ကျပန်း ရောမွှေ ပါသည်။ အဆိုပါ ကျပန်း ဝဏ္ဏ (၂) လုံး မှ (၆)
          လုံးအထိကိုစုစည်းပြီး ကျပန်း ပုဒ်စု phrase ကိုရရှိပါသည်။ အဆိုပါ ပုဒ်စု (၃) ခုမှ (၉) ခုကို
          စုစည်းပြီး ၀ါကျ sentence များကို တည်ဆောက်ပါသည်။ ရလဒ်အနေနှင့် စာအမှန်နှင့် အမြင်တွင်လွန်စွာ
          ဆင်တူပြီး ဖတ်ရှုရန် မလွယ်သော ၀ါကျများကို ရရှိပါသည်။
        </Typography.Paragraph>
      </Flex>
      <DesktopFooter />
      <CFloatingButton onClick={() => setShowContactUs(true)} icon={<SvgFloatButton />} />
      <ContactUsLayout open={showContactUs} setOpen={setShowContactUs} />
    </div>
  );
};

export default PolicyPage;
