import dummyProductGenre from "@/data/dummyProductGenre";
import { Checkbox, Space } from "antd";
import React from "react";

const ProductGenreFilterList = ({value, onChangeValue}) => {
  return (
    <Space direction="vertical">
      {dummyProductGenre.map((item, index) => (
        <Checkbox
          key={index}
          value={item}
          checked={value.includes(item)}
          onChange={e => onChangeValue(e.target.checked, item)}>
          {item}
        </Checkbox>
      ))}
    </Space>
  );
};

export default ProductGenreFilterList;
