import { Button, Flex, Space, Typography } from "antd";
import React from "react";
import { StyledParagraph, StyledSubtitle, StyledTitle } from "./styles";
import { SvgGreenMark } from "@/assets/svg";
import { useNavigate } from "react-router-dom";
import { useMobileDimensions } from "@/hooks";

const ServiceFormComplete = () => {
  const navigate = useNavigate();
  const { isMobile } = useMobileDimensions();

  return (
    <Flex vertical gap={64}>
      <StyledTitle $isMobile={isMobile}>ဝန်ဆောင်မှုအတည်ပြုချက်</StyledTitle>
      <Flex align="center" justify="center">
        <SvgGreenMark />
      </Flex>
      <Flex vertical gap={40}>
        <StyledSubtitle $isMobile={isMobile}>
          မိတ်ဆွေ၏ ဝန်ဆောင်မှု လျှောက်ထားချက်ကို အောင်မြင်စွာ လက်ခံရရှိခဲ့သည်။
        </StyledSubtitle>
        <StyledParagraph $isMobile={isMobile}>
          ဝန်ဆောင်မှုကို လျှောင်ထားခဲ့သည့်အတွက် ဦးစွာကျေးဇူးတင်ပါသည်။ မိတ်ဆွေ၏ ဝန်ဆောင်မှု
          လျှောက်ထားလွှာ အောင်မြင်စွာ လက်ခံခဲ့ပြီး ဖြစ်ပါသည်။ ကျွန်ုပ်တို့၏ အဖွဲ့သည် မိတ်ဆွေ၏
          လျှောက်ထားလွှာကို စစ်ဆေးပြီး တစ်ပတ်အတွင်း မိတ်ဆွေထံ ဆက်သွယ်ပါမည်။ ထို့နောက် အခြား
          အတည်ပြုချက်များနှင့် လိုအပ်သည့် အသေးစိတ် အချက်အလက်များအတွက် မိတ်ဆွေအား
          ပြန်လည်ဆက်သွယ်ပေးပါမည်။
        </StyledParagraph>
      </Flex>
      <Button
        onClick={() => navigate("/")}
        style={{
          fontSize: isMobile ? 14 : 20,
          fontWeight: 500,
          height: isMobile ? 46 : 72,
          lineHeight: isMobile ? "22.4px" : "32px",
          color: "#fff",
          backgroundColor: "#485AFF",
          paddingBlock: isMobile ? 12 : 20,
        }}>
        ပင်မစာမျက်နှာသို့ ပြန်သွားမည်
      </Button>
    </Flex>
  );
};

export default ServiceFormComplete;
