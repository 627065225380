import { Text } from "@/styles/reactnative";
import styled from "styled-components";

export const CategoryContainer = styled.div<{ $isMobile: boolean }>`
  margin-top: ${props => props?.$isMobile ? "66px" : "126px"};
  display: flex;
  flex-direction: column;
  gap: ${props => props?.$isMobile ? "32px" : "48px"};
`;

export const CategoryTitle = styled(Text)<{ $isMobile: boolean }>`
  font-size: ${props => (props?.$isMobile ? "12px" : "32px")};
  font-weight: 500;
  font-style: normal;
`;
