import { Collapse } from "antd";
import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledBackground = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  background-color: black;
`;

export const BottomSheet = styled(motion.div)`
  max-height: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 4999;
  width: 100%;
  border-radius: 0.75rem 0.75rem 0 0;
  border: 2px solid #f9fafb;
  border-bottom: 0;
  background-color: #ffffff;
  box-shadow: 0px -8px 20px -6px rgba(0, 0, 0, 0.3);
`;

export const StyledCollapse = styled(Collapse)`
    border: none;
    .ant-collapse-header {
        background-color: #fff;
        flex-direction: row-reverse;
    }
    .ant-collapse-content {
        border-top: none;
    }
`