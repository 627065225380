import { DesktopBlogLayout } from "@/app/components/Desktop";
import { SectionWrapper } from "@/app/hoc";
import { useMobileDimensions } from "@/hooks";
import { textVariant } from "@/utils/motion";
import { RightOutlined } from "@ant-design/icons";
import { Flex, Typography } from "antd";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { Title } from "./styles";
import { GET_ALL_BLOGS } from "@/server/apis";
import { useApiQuery } from "@/server/api-fetch";

const BlogsByCategory = ({ category }) => {
  const { isMobile } = useMobileDimensions();
  const [offset, setOffset] = useState(1);
  const limit = 10;
  const { data, isLoading } = useApiQuery({
    url: `${GET_ALL_BLOGS}?limit=${limit}&offset=${(offset - 1) * limit}&category=${category}`,
  });

  return (
    <motion.div variants={textVariant()}>
      <Flex align="center" gap={8} style={{ marginBottom: isMobile ? 32 : 64 }}>
        <Title $isMobile={isMobile}>ဆောင်းပါးများ</Title>
        <RightOutlined style={{ fontSize: isMobile ? 10 : 18, color: "#18181B", marginTop: 6 }} />
        <Title $isMobile={isMobile}>ကဏ္ဍအမျိုးအစားများ</Title>
        <RightOutlined style={{ fontSize: isMobile ? 10 : 18, color: "#18181B", marginTop: 6 }} />
        {category && (
          <Typography.Text
            style={{
              color: "#4D4D4D",
              fontSize: isMobile ? 10 : 16,
              fontWeight: "500",
              lineHeight: "32px",
            }}>
            {category}
          </Typography.Text>
        )}
      </Flex>
      <DesktopBlogLayout data={data} offset={offset} isLoading={isLoading} setOffset={setOffset} />
    </motion.div>
  );
};

export default SectionWrapper(BlogsByCategory, "blogsByCategory");
