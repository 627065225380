import React from "react";

function ServiceIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
    >
      <g>
        <g>
          <path
            fill="#485AFF"
            fillRule="evenodd"
            d="M20.583 5.136v3.53a3.25 3.25 0 010 6.5v3.25c0 1.786-2.037 2.805-3.466 1.734l-2.232-1.675a9.38 9.38 0 00-4.052-1.742v3.081a2.936 2.936 0 01-5.759.806l-1.7-5.954a5.092 5.092 0 013.603-8.374l3.27-.182c1.599-.09 3.155-.557 4.538-1.364l2.54-1.482c1.445-.842 3.258.2 3.258 1.872zM6.103 16.334l1.055 3.691a.769.769 0 001.509-.211v-3.26l-1.69-.095a5.425 5.425 0 01-.874-.125zM18.417 5.136l-2.54 1.483a12.242 12.242 0 01-5.044 1.618v6.304a11.554 11.554 0 015.352 2.2l2.232 1.675V5.136zm-9.75 3.231l-1.571.087a2.925 2.925 0 00-.184 5.825l.184.017 1.57.086V8.367zm11.916 2.466V13a1.083 1.083 0 00.127-2.16l-.127-.007z"
            clipRule="evenodd"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default ServiceIcon;
