import { useMobileDimensions } from "@/hooks";
import { Form, Select } from "antd";
import React from "react";
import styled from "styled-components";

const StyledSelect = styled(Select)<{ $isMobile: boolean }>`
  width: 100%;
  height: ${props => (props?.$isMobile ? "48px" : "64px")};
  font-size: ${props => (props?.$isMobile ? "12px" : "20px")};
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`;

const ServiceTime = () => {
  const { isMobile } = useMobileDimensions();
  return (
    <Form.Item label="ဝန်ဆောင်မှုရယူလိုချိန်" name="time" style={{ width: "100%" }} required>
      <StyledSelect
        $isMobile={isMobile}
        options={[
          {
            label: "၂၄ နာရီအတွင်း",
            value: "၂၄ နာရီအတွင်း",
          },
          {
            label: "တစ်ပတ်အတွင်း ( အကြံပြုထားသော )",
            value: "တစ်ပတ်အတွင်း",
          },
          {
            label: "တစ်လအတွင်း",
            value: "တစ်လအတွင်း",
          },
          {
            label: "အချိန်မရွေး",
            value: "အချိန်မရွေး",
          },
        ]}
      />
    </Form.Item>
  );
};

export default ServiceTime;
