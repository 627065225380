import { Typography } from "antd";
import styled from "styled-components";

export const BlogLayoutContainer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
`;

export const Dot = styled.div`
  width: 3px;
  height: 3px;
  background-color: #000;
  border-radius: 100%;
`;
