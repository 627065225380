export const themeColor: {
  light: any;
  dark: any;
} = {
  light: {
    primary: "#7663FC",
  },
  dark: {
    primary: "#7663FC",
  },
};
