import { ProductDetailPage, ProductsPage } from "@/app/pages";
import React from "react";
import { useLocation } from "react-router-dom";

const ProductsRoute = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  if (id) {
    return <ProductDetailPage />;
  }
  return <ProductsPage />;
};

export default ProductsRoute;
