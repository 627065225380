import styled from "styled-components";

export const CategoryGrid = styled.div<{ $isMobile: boolean }>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: ${props => (props?.$isMobile ? "15px" : "32px")};

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  }
`;
