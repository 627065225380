import { BlogCard } from "@/app/entities";
import { useMobileDimensions } from "@/hooks";
import { List } from "antd";
import Button from "antd/es/button";
import React, { useEffect, useState } from "react";

const BlogsList = ({ noLoadMore, data, isLoading, offset, setOffset }: any) => {
  const { isMobile } = useMobileDimensions();
  const [blogs, setBlogs] = useState<any[]>([]);
  useEffect(() => {
    if (data?.blogs?.length) {
      setBlogs(prevBlogs => [...prevBlogs, ...data?.blogs]); // Flatten and merge the new blogs
    }
  }, [data]); // Trigger whenever `data` updates
  const blogsToDisplay = isMobile ? blogs.slice(0, 4) : blogs;

  const onLoadMore = () => {
    if (isLoading) return;
    setOffset(offset + 1);
  };
  const loadMore =
    !isLoading && data?.blogs?.length < data?.totalCount ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}>
        <Button
          style={{ borderRadius: 100, padding: "14px 24px", border: "1px solid #000" }}
          onClick={() => {
            onLoadMore();
          }}>
          ပိုမို ကြည့်ရှုရန်
        </Button>
      </div>
    ) : null;
  return (
    <List
      className="demo-loadmore-list"
      itemLayout="vertical"
      loading={isLoading}
      loadMore={noLoadMore ? null : loadMore}
      size="large"
      dataSource={blogsToDisplay}
      renderItem={item => <BlogCard item={item} />}
      grid={{
        gutter: 32,
        xs: 1,
        sm: 1,
        md: 2,
        lg: 2,
        xl: 2,
        xxl: 2,
      }}
    />
  );
};

export default BlogsList;
