// import { useEffect } from "react";
// import { throttle } from "lodash";
// import { atom, useAtom } from "jotai";
// import { breakpoints } from "@/constant/ui-constant";

import { breakpoints } from "@/constant/ui-constant";
import { useMediaQuery } from "react-responsive";

// function getWindowDimensions() {
//   const { innerWidth: width, innerHeight: height } = window;
//   const isMobile = /iphone|ipad|ipod|android|mobile/i.test(navigator.userAgent?.toLowerCase());
//   return {
//     width,
//     height,
//     isDesktop: width <= breakpoints.desktop && !isMobile, 
//   };
// }
// const windowDimensionsAtom = atom(getWindowDimensions());

// export default function useDesktopDimensions() {
//   const [windowDimensions, setWindowDimensions] = useAtom(windowDimensionsAtom);

//   useEffect(() => {
//     const handleResize = () => {
//       setWindowDimensions(getWindowDimensions());
//     };

//     // Throttle the function to improve performance
//     const throttledHandleResize = throttle(handleResize, 100);

//     window.addEventListener("resize", throttledHandleResize);

//     // Cleanup the event listener on component unmount
//     return () => window.removeEventListener("resize", throttledHandleResize);
//   }, [setWindowDimensions]);

//   return windowDimensions;
// }

const useDesktopDimensions = () => {
  const isDesktop = useMediaQuery({ maxWidth: breakpoints.desktop });
  return { isDesktop };
}

export default useDesktopDimensions
