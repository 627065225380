import React from "react";
import { SearchItemsContainer, StyledSearchedText } from "./styles";
import { Flex } from "antd";
import { useApiQuery } from "@/server/api-fetch";
import { SEARCH_PRODUCT_RECOMMENDATION } from "@/server/apis";
import { SearchOutlined } from "@ant-design/icons";
import { Text, View } from "@/styles/reactnative";

const SearchRecommndationList = ({ searchQuery, action }) => {
  const [selected, setSelected] = React.useState<any>(null);
  const { data, isLoading, error } = useApiQuery({
    url: `${SEARCH_PRODUCT_RECOMMENDATION}?search=${searchQuery}`,
  });
  return (
    <SearchItemsContainer>
      {error && (
        <View>
          <Text>Failed to load products</Text>
        </View>
      )}
      {!isLoading && data?.length === 0 && (
        <View>
          <Text>No products found</Text>
        </View>
      )}
      {data?.map((item, index) => (
        <Flex
          align="center"
          gap={12}
          key={item._id || index}
          onClick={() => {
            action && action?.(item);
            setSelected(item);
          }}>
          <SearchOutlined style={{ color: "#000", fontSize: 18 }} />
          <StyledSearchedText
            style={{ color: selected?._id === item?._id ? "dodgerblue" : "#000" }}>
            {item?.name}
          </StyledSearchedText>
        </Flex>
      ))}
      {isLoading && (
        <View>
          <Text>Loading...</Text>
        </View>
      )}
    </SearchItemsContainer>
  );
};
export default SearchRecommndationList;
