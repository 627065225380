import React from "react";
import { CategoryContainer } from "./styles";
import { SectionWrapper } from "@/app/hoc";
import { motion } from "framer-motion";
import { textVariant } from "@/utils/motion";
import { BlogLayoutHeader } from "@/app/components/Desktop";
import { useMobileDimensions } from "@/hooks";
import { CategoriesList } from "@/app/features";

const CategorySection = () => {
  const { isMobile } = useMobileDimensions();
  // const isMobile = false

  return (
    <CategoryContainer $isMobile={isMobile}>
      <motion.div variants={textVariant()}>
        <BlogLayoutHeader title={'ကဏ္ဍအမျိုးအစားများ'} href={"/categories"} />
      </motion.div>

      <motion.div variants={textVariant()}>
        <CategoriesList />
      </motion.div>
    </CategoryContainer>
  );
};

export default SectionWrapper(CategorySection, "categorySection");
