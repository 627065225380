import { DesktopFooter } from "@/app/components/Desktop";
import { MobileNavbar } from "@/app/components/Mobile";
import { ProductDetail, ProductSection } from "@/app/layouts/Desktop";
import { MobileSearchContainer } from "@/app/layouts/Mobile";
import React, { useState } from "react";

const MobileView = ({ data }) => {
  const [showSearchContainer, setShowSearchContainer] = useState(false);

  return (
    <main>
      <MobileSearchContainer
        showSearchContainer={showSearchContainer}
        setShowSearchContainer={setShowSearchContainer}
      />
      <div style={{ paddingInline: 16, marginTop: 90 }}>
        {!showSearchContainer && <MobileNavbar setShowSearchContainer={setShowSearchContainer} />}
        <ProductDetail data={data} />
        <ProductSection title={"သင့်အတွက် အကြုံပြုထားသော"} category={data?.category} />
      </div>
      <DesktopFooter />
    </main>
  );
};

export default MobileView;
