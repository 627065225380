import React from "react";
import {
  FooterBody,
  FooterContainer,
  PoweredBy,
  SocialButton,
  StyledText,
  StyledTitle,
} from "./styles";
import { SvgFacebook } from "@/assets/svg";
import { CImage } from "@/app/common";
import { images } from "@/assets/images";
import { useMobileDimensions } from "@/hooks";
import { useNavigate } from "react-router-dom";
import { Flex } from "antd";

const DesktopFooter = () => {
  const navigate = useNavigate();
  const { isMobile } = useMobileDimensions();
  return (
    <FooterContainer $isMobile={isMobile}>
      <FooterBody $isMobile={isMobile}>
        <div>
          <StyledTitle $isMobile={isMobile}>အလျင်အမြန် ချိတ်ဆက်မှုများ</StyledTitle>
          <Flex vertical gap={18}>
            <StyledText onClick={() => navigate("/")} $isMobile={isMobile}>
              ပင်မ စာမျက်နှာ
            </StyledText>
            <StyledText onClick={() => navigate("/blogs")} $isMobile={isMobile}>
              ဆောင်းပါးများ
            </StyledText>
            <StyledText onClick={() => navigate("/services")} $isMobile={isMobile}>
              ဝန်ဆောင်မှုများ
            </StyledText>
            <StyledText onClick={() => navigate("/categories")} $isMobile={isMobile}>
              ကဏ္ဍအမျိုးအစားများ
            </StyledText>
          </Flex>
        </div>
        <div>
          <StyledTitle $isMobile={isMobile}>အလျင်အမြန် ချိတ်ဆက်မှုများ</StyledTitle>
          <Flex vertical gap={18}>
            <StyledText onClick={() => navigate("/blogs")} $isMobile={isMobile}>
              ဆောင်းပါး အရင်းအမြစ်များ
            </StyledText>
            <StyledText onClick={() => navigate("/services")} $isMobile={isMobile}>
              ဝန်ဆောင်မှု အရင်းအမြစ်များ
            </StyledText>
            <StyledText onClick={() => navigate("/policy")} $isMobile={isMobile}>
              သတင်းအချက်အလတ် လုံခြုံမှု မူဝါဒ
            </StyledText>
          </Flex>
        </div>
        <div>
          <StyledTitle $isMobile={isMobile}>ကျွန်ုပ်တို့ အဖွဲ့အစည်း</StyledTitle>
          <SocialButton href="https://www.facebook.com/ulsanchammyanmar?mibextid=LQQJ4d">
            <SvgFacebook />
          </SocialButton>
        </div>
        <div>
          <StyledTitle $isMobile={isMobile}>App ကို ဤတွင် ဒေါင်းလုဒ်ဆွဲပါ</StyledTitle>
          <Flex gap={16} align="center">
            <CImage preview={false} src={images.footer_apple_store} />
            <CImage preview={false} src={images.footer_google_store} />
          </Flex>
        </div>
      </FooterBody>
      <PoweredBy>© 2024 UIsan Cham. All Rights Reserved.</PoweredBy>
    </FooterContainer>
  );
};

export default DesktopFooter;
